import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, switchMap } from 'rxjs';
import { UserCoursesI } from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'request-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css'],
})
export class ApprovalComponent implements OnInit {
  constructor(
    private _approvalService: ApprovalService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}

  public showSubjects: boolean = false;
  public userCourses: UserCoursesI[] = [];
  public targetUser: any = null;
  public selectedCourseInfo: UserCoursesI = {} as UserCoursesI;
  public filter: string = 'all';
  public modalNoteList: any[] = [];
  public isWithinDateRange: boolean = false;
  public showNotesModal: boolean = false;
  public childrenList: any[] = [];
  public isMyApproval: boolean = false;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  private _selectedCard: HTMLElement;

  ngOnInit(): void {
    Swal.fire({
      text:"Estamos procesando su solicitud. Por favor, espere un momento.",
      didOpen: () => Swal.showLoading()
    })

    this._activatedRoute.params
      .pipe(
        switchMap((params: any) => {
          return this.getUserInfo(params['id']).pipe(
            map(({ observerInfo, targetUser }) => ({
              observerInfo,
              targetUser,
            }))
          );
        })
      )
      .subscribe({
        next: ({ observerInfo, targetUser }: any) => {
          Swal.close();

          const { observerUser, children } = observerInfo;

          //If the targetUser
          const id = this._activatedRoute.snapshot.paramMap.get('id');
          const userData = id === 'me' ? observerUser : targetUser[0];

          if (targetUser.length === 0) {
            Swal.fire({
              icon: 'warning',
              text: `No hemos encontrado ningún resultado que coincida con el usuario: ${id}`,
              allowEscapeKey: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed)
                this._router.navigate(['/']);
            });
            return;
          }

          this._approvalService.roles = observerUser.roles.map((r) => r.id);

          this._approvalService.adminUser = observerUser;
          this.isMyApproval = observerUser.id === userData.id;

          //If not a parent, then selectedUser will be a student or me
          if (!(this.isMyApproval && this._approvalService.roles.includes(29) && this.SCHOOL_NAME === 'CELIC'))
            this.targetUser = userData;
          
          this.childrenList = children
            .filter((e) => e.studentUser)
            .map((e) => e.studentUser);

          if(!this.targetUser)
            return;
          
          this.userCourses = this.targetUser.UserCourses;
        },
        error: (err) => {
          if (err.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No se te permite acceder a esta parte del módulo debido a tus permisos de usuario. Si crees que esto es un error, por favor, comunícate con un administrador.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: "'Lo sentimos, hubo un problema al procesar su solicitud. Por favor, contacte a un administrador si necesita ayuda.",
            });
          }
          console.log(err);
        },
      });
  }

  /**
   * obtains the current user's information
   * @returns
   */
  getUserInfo(id: string) {
    let setFilters: string = '';
    const generalFilter: any = {
      where: { id: Number(id) },
      include: [
        'UserDocuments',
        'roles',
        { UserCourses: [{ coursesOfers: [{TypeCourseOFer:"Category"}, 'school'] }] },
      ],
    };

    if (id === 'me') {
      delete generalFilter.where;
      setFilters = `/me?filter=${JSON.stringify(generalFilter)}`;
    } else {
      setFilters = `?filter=${JSON.stringify(generalFilter)}`;
    }

    return forkJoin({
      observerInfo: this._approvalService
        .getUserInfo(`/me?filter=${JSON.stringify(generalFilter)}`)
        .pipe(
          switchMap((observerUser) =>
            this._approvalService
              .getChildren(observerUser.id)
              .pipe(map((children) => ({ observerUser, children })))
          )
        ),
      targetUser: this._approvalService.getUserInfo(setFilters),
    });
  }

  /**
   * get the data of the selected course
   * @param event
   * @param selectedCourse
   */
  onSelectCourse(event, selectedCourse: UserCoursesI) {
    if (selectedCourse.isWithdrawn) return;

    this.showSubjects = true;
    this.selectedCourseInfo = selectedCourse;

    const card = event.target
      .closest('.approval-card__holder')
      .querySelector('.approval-card');

    if (this._selectedCard !== undefined)
      this._selectedCard.classList.remove('approval-card--selected');

    card.classList.add('approval-card--selected');
    this._selectedCard = card;

    const currentDate: Date = new Date();
    const sinceDate = new Date(this.selectedCourseInfo.coursesOfers.Since);
    const untilDate = new Date(this.selectedCourseInfo.coursesOfers.Until);
    this.isWithinDateRange =
      currentDate >= sinceDate && currentDate <= untilDate;
  }

  //Switch user
  public switchUser(event: Event) {
    if (
      !this.isMyApproval ||
      !this._approvalService.roles.includes(29) ||
      this.childrenList.length === 0
    )
      return;

    const userId = event.target as HTMLSelectElement;

    const generalFilter: any = {
      where: { id: Number(userId.value) },
      include: [
        'UserDocuments',
        'roles',
        { UserCourses: [{ coursesOfers: ['school'] }] },
      ],
    };

    const targetUserFilter = `?filter=${JSON.stringify(generalFilter)}`;

    Swal.fire({
      text: 'Esto puede tardar un nomento, por favor espere.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    Swal.showLoading();
    this._approvalService.getUserInfo(targetUserFilter).subscribe({
      next: (targetUser) => {
        const userData = targetUser[0];
        this.targetUser = userData;
        this.userCourses = userData.UserCourses;
        Swal.close();
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => Swal.close(),
    });
  }
}