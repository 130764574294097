<div class="approval-card__holder" [ngClass]="{'approval-card__holder--disabled': isWithdrawn}">
  <div class="approval-card">
    <div class="approval-card__header">
      <h2 class="approval-text approval-text--md approval-text--bold">{{ courseInfo.NameCourseOfer }}</h2>
      <p class="approval-text approval-text--gray">{{ courseInfo.school.NameTSchool }}</p>
    </div>
    <p class="approval-text approval-text--center">{{ (courseInfo.RealSince | date ) || (courseInfo.Since | date ) }}</p>
    <p class="approval-text approval-text--center">{{ getCourseStatus() }}</p>
    <i class="icon-i-angle-right"></i>
  </div>
</div>