<div class="row">

  <div class="col-lg-9">

    <div class="card">
      <div class="card-header">
        <h3 class=" my-2">
          <strong>Histórico de notas</strong>
        </h3>
      </div>

      <div class="card-block img-container">
        <div class="img-box" *ngFor="let school of dependSchools" (click)="setSchool(school, userCourse);">
          <img class="img" [src]="DYNAMIC_SCHOOL_LOGO( school.id )" alt="school logo" />
        </div>
      </div>

      <div class="card-block">
        <form action="">
          <div class="form-group row mx-auto col-lg-6" [ngClass]="dependSchools.length === 0 ? 'disabled':''">
            <select class="form-control" name="yearInput" [(ngModel)]="selectedYearId"
              (change)="loadCourseOffers(); userCourse.value = '0';">
              <option [value]="0" disabled selected>Seleccione un año</option>
              <option [value]="year" *ngFor="let year of yearList">{{year}}</option>
            </select>
          </div>

          <div class="form-group row mx-auto col-lg-6" [ngClass]="courseOffers.length === 0 ? 'disabled':''">
            <select class="form-control" #userCourse (change)="getCourseInfo($event)" name="courseOffer">
              <option [value]="0" disabled selected>Seleccione un curso </option>
              <option [value]="courseOffer.id" *ngFor="let courseOffer of courseOffers">
                {{courseOffer.NameCourseOfer}}
              </option>
            </select>
          </div>

          <div class="form-group row mx-auto col-lg-6" *ngIf="rowData.length > 0">
            <button type="button" class="btn btn-primary w-100" (click)="exportExcel()">
              Generar CSV
            </button>
          </div>
        </form>

        <ag-grid-angular [ngClass]="'ag-theme-alpine'" [ngStyle]=" {'width':'100%','height':'400px'}"
          [rowData]="rowData" [animateRows]="true" [rowDragManaged]="true" [gridOptions]="gridOptions"
          [pagination]="true" [paginationPageSize]="25" *ngIf="rowData.length > 0">
        </ag-grid-angular>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <ng-container *ngIf="dependSchools.length > 0">
      <div class="card card-block">
        <div class="d-flex align-items-center">
          <img [src]="DYNAMIC_SCHOOL_LOGO( selectedSchool.id )"
            class="media-object" style="width:80px; margin-left: -1rem;" alt="school logo">

          <h3 class="m-0"><strong>{{selectedSchool.NameTSchool}}</strong></h3>
        </div>
      </div>

      <div class="card card-block" [ngStyle]="{'background-color':selectedSchool.Color}">
        <div class="d-flex align-items-center text-white">
          <em class="icon-i-student-list"></em>
          <div>
            <span><strong>ALUMNOS</strong></span>
            <h3 class="m-0"><strong>Total:</strong> {{rowData.length}}</h3>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>