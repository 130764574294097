import { Component, Input } from '@angular/core';
import {
  ObservationsInfoI,
  UserDataI,
} from 'src/app/models/observationModels/observation';

@Component({
  selector: 'print-observations',
  templateUrl: './print-observations.component.html',
  styleUrls: ['./print-observations.component.css'],
})
export class PrintObservations {
  @Input('observations') observations: ObservationsInfoI[] = [];
  @Input('schoolList') schoolList: any[] = [];
  @Input('userGrade') userGrade: string | null | undefined = null;
  @Input('targetUser') targetUser: UserDataI | null = null;
  @Input('observationTypeNames') observationTypeNames: {
    [id: number]: { name: string; image: string | null };
  } = {};
  @Input('observationSubTypeNames') observationSubTypeNames: {
    [idObservationType: number]: {
      [idSubtype: number]: { name: string; image: string | null };
    };
  } = {};
}
