import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';

import { ApprovalComponent } from './pages/approval/approval.component';
import { HonourGraduateComponent } from './pages/honour-graduate/honour-graduate.component';
import { HonourRollComponent } from './pages/honour-roll/honour-roll.component';
import { RegisterChildrenComponent } from './pages/register-children/register-children.component';
import { ObservationsComponent } from './pages/observations/observations.component';
import { environment } from 'src/environments/environment';
import { CedocResumeComponent } from './pages/resume/cedoc-resume/cedoc-resume.component';
import { CelicResumeComponent } from './pages/resume/celic-resume/celic-resume.component';
import { ObservationsExplorerComponent } from './pages/observations-explorer/observations.component';

const routes: Routes = [
  {
    path: 'academicStatus/honour-graduate',
    component: HonourGraduateComponent,
  },
  {
    path: 'academicStatus/register-children',
    component: RegisterChildrenComponent,
  },
  { path: 'academicStatus/honour-roll', component: HonourRollComponent },
  { path: 'academicStatus/observations/:id', component: ObservationsComponent },
  {
    path: 'academicStatus/observations-explorer',
    component: ObservationsExplorerComponent,
  },
  { path: 'academicStatus/:id', component: ApprovalComponent },
  {
    path: 'Cv-Public/:id',
    component:
      environment.SCHOOL_NAME === 'CELIC'
        ? CelicResumeComponent
        : CedocResumeComponent,
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
