import { Component, Input, OnInit } from '@angular/core';
import { UserI } from 'src/app/models/approvalModels/approvalModels';

@Component( {
  selector: 'approval-header',
  templateUrl: './approval-header.component.html',
  styleUrls: [ './approval-header.component.css' ]
} )
export class ApprovalHeaderComponent implements OnInit {

  constructor () {
    //
  }

  //receives basic user information
  @Input( 'userInfo' ) userInfo: UserI = {} as UserI;

  ngOnInit(): void {
    //
  }

}
