<div class="observations-section" >
  <div>
    <img class="observations-header" src="https://storage.googleapis.com/celic_assets/img/basic_header.png"
      alt="celic header"
    />
    <div class="yellow-part">{{ schoolList[0].schoolName }}</div>
    <div class="version-part">Versión: 1 &nbsp; &nbsp;|&nbsp; &nbsp; Modelo: CELIC360 - 2024</div>
  </div>

  <!-- Observations -->
  <div class="observations">
    <div class="observation-card">
      <ng-container
        *ngTemplateOutlet="defaultAvatar; context: {
          photoClass: 'profile-img--lg',
          avatarClass: 'default-avatar--lg',
          imageUrl: targetUser!.Photo
        }">
      </ng-container>

      <div class="observation-card__content">
        <h2 class="card-content__title">
          {{ targetUser!.Name1 | titlecase }}
          {{ targetUser!.Name2 | titlecase }}
          {{ targetUser!.LastName1 | titlecase }}
          {{ targetUser!.LastName2 | titlecase }}
        </h2>

        <div class="text text--blue">
          <strong>{{targetUser!.UserDocuments[0].TypeDocuments.NameTypeDocument}}:</strong>
          {{ targetUser!.UserDocuments[0].Document }}
        </div>

        <ng-container *ngIf="targetUser!.ClassificationUser && targetUser!.ClassificationUser.length > 0">
          <div class="text text--blue" *ngIf="targetUser!.ClassificationUser[0].MothersName">
            <strong>Madre:</strong>
            {{targetUser!.ClassificationUser[0].MothersName}}
          </div>

          <div class="text text--blue" *ngIf="targetUser!.ClassificationUser[0].FathersName">
            <strong>Padre:</strong>
            {{targetUser!.ClassificationUser[0].FathersName}}
          </div>

          <div class="text text--blue" *ngIf="targetUser!.ClassificationUser[0].UnitMilitaryService">
            <strong>Unidad:</strong>
            {{targetUser!.ClassificationUser[0].UnitMilitaryService}}
          </div>

          <div class="text text--blue" *ngIf="targetUser!.ContactInfos?.[0]?.CellPhone">
            <strong>Celular:</strong>
            {{targetUser!.ContactInfos?.[0]?.CellPhone}}
          </div>

          <div class="text text--blue" *ngIf="targetUser!.ClassificationUser[0].ForceRelation">
            <strong>Fuerza:</strong>
            {{targetUser!.ClassificationUser[0].ForceRelation.NameForce}}
          </div>

          <div class="text text--blue" *ngIf="targetUser!.ClassificationUser[0].Grade">
            <strong>Grado:</strong>
            {{targetUser!.ClassificationUser[0].Grade}}
          </div>
        </ng-container>


        <div class="text text--blue">
          <strong>Grado:</strong>
          {{userGrade || 'Sin grado'}}
        </div>
      </div>
    </div>

    <div class="observation-card observation-card--printable">
      <div class="observation-card__content observation-card__content--printable text text--blue" *ngIf="targetUser!.parentsToStudents && 
        targetUser!.parentsToStudents.length > 0 && 
        targetUser!.parentsToStudents[0].attendantUser"
      >
        <h2 class="card-content__title">Acudiente</h2>
        <div>
          <strong>Nombre:</strong>
          {{targetUser!.parentsToStudents[0].attendantUser.Name1 | titlecase}}
          {{targetUser!.parentsToStudents[0].attendantUser.Name2 | titlecase}}
          {{targetUser!.parentsToStudents[0].attendantUser.LastName1 | titlecase}}
          {{targetUser!.parentsToStudents[0].attendantUser.LastName2 | titlecase}}
        </div>
        <div>
          <strong>
            {{targetUser!.parentsToStudents[0].attendantUser.UserDocuments[0].TypeDocuments.NameTypeDocument}}:
          </strong>
          {{targetUser!.parentsToStudents[0].attendantUser.UserDocuments[0].Document}}
        </div>
        <div>
          <strong>Celular:</strong>
          {{targetUser!.parentsToStudents[0].attendantUser.ContactInfos?.[0]?.CellPhone || ''}}
        </div>
        <div>
          <strong>Parentesco:</strong>
          {{targetUser!.parentsToStudents[0].typeRlelationshipAttended?.nameRelationship}}
        </div>
      </div>

      <div class="observation-card__content observation-card__content--printable text text--blue" *ngIf="targetUser!.parentsToStudents && 
        targetUser!.parentsToStudents.length > 0 && 
        targetUser!.parentsToStudents[0].parentUser"
      >
        <h2 class="card-content__title">Responsable</h2>
        <div>
          <strong>Nombre:</strong>
          {{targetUser!.parentsToStudents[0].parentUser.Name1 | titlecase}}
          {{targetUser!.parentsToStudents[0].parentUser.Name2 | titlecase}}
          {{targetUser!.parentsToStudents[0].parentUser.LastName1 | titlecase}}
          {{targetUser!.parentsToStudents[0].parentUser.LastName2 | titlecase}}
        </div>
        <div>
          <strong>
            {{targetUser!.parentsToStudents[0].parentUser.UserDocuments[0].TypeDocuments.NameTypeDocument}}:
          </strong>
          {{targetUser!.parentsToStudents[0].parentUser.UserDocuments[0].Document}}
        </div>
        <div>
          <strong>Celular:</strong>
          {{targetUser!.parentsToStudents[0].parentUser.ContactInfos?.[0]?.CellPhone || ''}}
        </div>
        <div>
          <strong>Parentesco:</strong>
          {{targetUser!.parentsToStudents[0].typeRlelationshipParent?.nameRelationship}}
        </div>
      </div>
    </div>

    <div class="observations-content">
      <div class="observations-header"><h1 class="main-title">Observaciones</h1></div>

      <ng-container *ngFor="let item of observations">
        <div class="comment-card" *ngIf="!item.observationID">
          <div class="comment-profile">
            <div class="profile-header">
              <ng-container
                *ngTemplateOutlet="defaultAvatar; context: {
                  photoClass: 'profile-img--md',
                  avatarClass: 'default-avatar default-avatar--md',
                  imageUrl: item.observer.Photo
                }"
              >
              </ng-container>

              <div>
                <p class="text text--black">
                  {{ item.observer.Name1 | titlecase }}
                  {{ item.observer.Name2 | titlecase }}
                  {{ item.observer.LastName1 | titlecase }}
                  {{ item.observer.LastName2 | titlecase }}
                </p>
                <p class="text text--sm text--black">{{ item.observer.CedocEmail || item.observer.email }}</p>
              </div>
            </div>

            <div>
              <span class="text text--gray"> ID: {{ item.id }} - </span>
              <span class="text text--gray">{{
                item.date | date : "dd/MM/yyyy hh:mm" : "UTC"
              }}</span>
            </div>
          </div>

          <div class="categories">
            <h2 class="text text--black">
              Categoría:
              <span class="text">{{observationTypeNames[item.typeObservationID].name}}</span>
            </h2>
            <div class="subcategory-list">
              <ng-container *ngFor="let subtype of item.subTypeObservationIDs">
                <span class="subcategory-item" *ngIf="observationSubTypeNames[item.typeObservationID][subtype]">
                  <img [src]="observationSubTypeNames[item.typeObservationID][subtype].image"
                    class="subcategory-item__icon"
                    alt="school icon"
                    *ngIf="observationSubTypeNames[item.typeObservationID][subtype].imageUrl"
                  />
                  {{observationSubTypeNames[item.typeObservationID][subtype].name}}
                </span>
              </ng-container>
            </div>
          </div>

          <div class="observation-info">
            <h2 class="text text--black">Observación: <span class="text--blue"></span></h2>
            <p class="text text--gray">{{ item.comment }}</p>
          </div>

          <div class="observation-info" *ngIf="item.finalComment && item.minuteAttachment">
            <div>
              <h2 class="text text--black">Anotación final</h2>
              <div class="final-comment__container">
                <div>
                  <p class="text text--gray text--italic">
                    {{ item.finalComment }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="subcomments">
            <h2 class="text text--black">Comentarios ({{ item.additionalComments.length }})</h2>
            <div class="subcomment-list">
              <div class="subcomment" *ngFor="let additional of item.additionalComments">
                <div class="subcomment-profile">
                  <ng-container
                    *ngTemplateOutlet="defaultAvatar; context: {
                      photoClass: 'profile-img--sm',
                      avatarClass: 'default-avatar default-avatar--sm',
                      imageUrl: additional.observer.Photo
                    }">
                  </ng-container>

                  <div>
                    <p class="text text--sm text--black">
                      <span class="text--bold">
                        {{ additional.observer.Name1 | titlecase }}
                        {{ additional.observer.Name2 | titlecase }}
                        {{ additional.observer.LastName1 | titlecase }}
                        {{ additional.observer.LastName2 | titlecase }}
                      </span>
                      <span class="subcomment-profile__date">
                        {{
                          additional.date | date : "dd/MM/yyyy hh:mm" : "UTC"
                        }}
                      </span>
                    </p>
                    <p class="text text--sm text--black">
                      {{ additional.comment }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <p class="text text--blue" *ngIf="observations.length === 0">
        Sin observaciones
      </p>
    </div>
  </div>

  <ng-template
    #defaultAvatar
    let-photoClass="photoClass"
    let-avatarClass="avatarClass"
    let-imageUrl="imageUrl"
  >
    <img
      [src]="imageUrl"
      alt="Profile picture"
      class="profile-img"
      [ngClass]="photoClass"
      *ngIf="imageUrl"
    />
    <em
      class="icon-i-user-login default-avatar"
      [ngClass]="avatarClass"
      *ngIf="!imageUrl"
    ></em>
  </ng-template>
</div>
