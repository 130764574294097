import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  catchError,
  concat,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  toArray,
} from 'rxjs';
import {
  ObservationsInfoI,
  ObserverI,
  SubTypeObservationsI,
  TypeObservationsI,
  UserDataI,
} from 'src/app/models/observationModels/observation';
import { AttachmentService } from 'src/app/services/attachment.service';
import { ObservationsService } from 'src/app/services/observations/observations.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'request-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.css'],
})
export class ObservationsComponent implements OnInit {
  constructor(
    private _observationsService: ObservationsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder,
    private _titleCasePipe: TitleCasePipe,
    private _attachmentService: AttachmentService
  ) {}

  @ViewChild('insertComment') insertComment: ElementRef;

  public targetUser: UserDataI | null = null;
  public observerUser: ObserverI | null = null;
  public observationForm: FormGroup = this._fb.group({
    typeObservationID: ['', Validators.required],
    subTypeObservationIDs: ['', Validators.required],
    comment: ['', Validators.required],
  });

  public typeObservations: TypeObservationsI[] = [];
  public observationTypeNames: {
    [id: number]: { name: string; image: string | null };
  } = {};
  public observationSubTypeNames: {
    [idObservationType: number]: {
      [idSubtype: number]: { name: string; image: string | null };
    };
  } = {};
  public subTypeObservations: any[] = [];
  public observations: ObservationsInfoI[] = [];
  public validateErrors: boolean = false;

  public isObserverChild: boolean = false;
  public isMyObservation: boolean = true;
  public showObservationModal: boolean = false;
  public schoolList: any[] = [];
  public parentList: any[] = [];

  public childrenList: any[] = [];
  public selectedChildrenParenList: any[] = [];

  public yearList: number[] = [];
  public userGrade: string = '';
  public finalCommentFile: File | null = null;

  public baseUrl: string = environment.baseUrl;
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;

  ngOnInit(): void {
    const currentId = this._route.snapshot.paramMap.get('id') ?? null;
    if (!currentId || this.SCHOOL_NAME === 'CEDOC') {
      this._router.navigate(['/']);
      return;
    }

    this.mapYears();

    const observerId: number = Number(localStorage.getItem('currentUser'));
    const observerUserFilter: string = JSON.stringify({
      where: { id: observerId },
      include: ['roleMappings'],
    });

    const targetUserFilter: string = JSON.stringify({
      where: { id: Number(currentId) },
      include: [
        { ClassificationUser: 'ForceRelation' },
        { UserDocuments: 'TypeDocuments' },
        { UserCourses: 'coursesOfer' },
        {
          parentsToStudents: [
            'typeRlelationshipAttended',
            'typeRlelationshipParent',
            { parentUser: ['ContactInfos',{ UserDocuments: 'TypeDocuments' }] },
            { attendantUser: ['ContactInfos',{ UserDocuments: 'TypeDocuments' }] },
          ],
        },
        { roleMappings: 'school' },
        {
          observationsInfos: [
            'courseOffer',
            'observer',
            { additionalComments: 'observer' },
            'userapp',
          ],
        },
        'ContactInfos',
      ],
    });

    forkJoin({
      observerUser: this._observationsService.getUserInfo(observerUserFilter),
      targetUser: this._observationsService.getUserInfo(targetUserFilter),
      children: this._observationsService.getChildren(observerId),
      typeObservations: this._observationsService.getObservationTypes(),
    }).subscribe({
      next: ({
        targetUser,
        typeObservations,
        observerUser,
        children,
      }: {
        targetUser: UserDataI[];
        typeObservations: TypeObservationsI[];
        observerUser: any[];
        children: any[];
      }) => {
        if (targetUser.length === 0 || observerUser.length === 0) {
          Swal.fire({
            icon: 'error',
            text: 'Usuario no encontrado.',
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) this._router.navigate(['/']);
          });

          return;
        }
        //May be the parent or student
        const _userData = targetUser[0];

        //Allowed to view
        this.isMyObservation = observerId === _userData.id;
        this.observerUser = observerUser[0];

        //If not a parent, then selectedUser will be a student
        if (!(this.isMyObservation && this.allowedRoles([29])))
          this.targetUser = _userData;

        const canSeeContent =
          this.isMyObservation ||
          this.isMyChild() ||
          this.allowedRoles([1, 3, 18]);

        if (canSeeContent) {
          this.typeObservations = typeObservations;
          this.childrenList = children
            .filter((e) => e.studentUser)
            .map((e) => e.studentUser);

          this.setUpTypeObservations();

          //So I am the parent
          if (!this.targetUser) return;

          this.refreshUserData();
          return;
        }

        //Student can't see student
        Swal.fire({
          icon: 'error',
          text: 'No tienes permisos para ver el contenido.',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) this._router.navigate(['/']);
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  /**
   * Filter subtypes by selected type
   * @param observationTypes
   * @param targetUser
   */
  private setUpTypeObservations() {
    this.typeObservations.forEach((type) => {
      this.observationTypeNames[type.id] = {
        name: type.name,
        image: type.school?.Icono || null,
      };

      if (type.subTypeObservations && type.subTypeObservations.length > 0) {
        this.observationSubTypeNames[type.id] = {};
        type.subTypeObservations.forEach((subtype) => {
          this.observationSubTypeNames[type.id][subtype.id] = {
            name: subtype.name,
            image: type.school?.Icono || null,
          };
        });
      }
    });
  }

  //Displays a confirmation alert before creating notification or message
  private confirmMessageAlert(
    title: string,
    comment: string,
    callback: () => void
  ) {
    const userData = this.targetUser!;

    const messageHtml = `
      <div style="text-align: left; font-family: Arial, sans-serif;">
        <p>${title}</p>
        <ul style="list-style-type: none; padding: 0; margin-bottom: 16px;">
          <li style="margin-bottom: 10px;">
            <strong>Nombre:</strong>
            ${userData.Name1} 
            ${userData.Name2} 
            ${userData.LastName1} 
            ${userData.LastName2}
          </li>

          <li style="margin-bottom: 10px;">
            <strong>Grado:</strong> 
            ${this.userGrade ? this.userGrade : 'Sin grado'}
          </li>
        
          ${
            comment
              ? '<li><strong>Mensaje:</strong><span> ' +
                comment +
                '</span> </li>'
              : ''
          }
        </ul>
      </div>
    `;

    Swal.fire({
      icon: 'warning',
      html: messageHtml,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) callback();
    });
  }

  //Stores an observation, only admins and instructors can store observations
  public storeObservation() {
    this.validateErrors = true;

    if (
      this.isMyObservation ||
      !this.allowedRoles([1, 3, 18]) ||
      !this.observationForm.valid
    )
      return;

    this.confirmMessageAlert(
      '¿Desea continuar y registrar la observación?',
      this.observationForm.get('comment')?.value,
      () => {
        const now = new Date();
        const localDate = new Date(
          now.getTime() - now.getTimezoneOffset() * 60000
        ).toISOString();


        const targetUserCourses = this.targetUser!.UserCourses;
        const courseOfferID = targetUserCourses?.[0]?.CourseOferID;
        const data = {
          userID: this.targetUser!.id, //To whom they comment
          date: localDate,
          comment: this.observationForm.get('comment')?.value,
          observerID: this.observerUser!.id, //who comments
          subTypeObservationIDs: this.observationForm.get(
            'subTypeObservationIDs'
          )?.value,
          typeObservationID: this.observationForm.get('typeObservationID')?.value,
          ...(courseOfferID && { courseOfferID }),
        };

        Swal.fire({
          text: 'Esto puede tardar un nomento, por favor espere.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        Swal.showLoading();
        this.storeCommentRequest(data).pipe(
          switchMap( observations => {
            return this.observationNotification(observations[observations.length - 1]).pipe(
              map(notification => ({ observations, notification })),
              catchError((e) => {
                console.log('Error sending notification: ', e);
                return of(null);
              })
            );
          }),
        ).subscribe({
          next: ({ observations }:any) => {
            this.observations = this.filterObservations(observations);
            this.toggleModal();
            this.observationForm.reset({
              typeObservationID: '',
            });

            Swal.fire({
              icon: 'success',
              text: 'Se ha registrado de forma correcta su observación.',
            });
          },
          error: (err) => {
            Swal.close();
            console.log(err);
          },
        });
      }
    );
  }

  //Switch user observations
  public switchUser(event: Event) {
    if (
      !this.isMyObservation ||
      !this.allowedRoles([29]) ||
      this.childrenList.length === 0
    )
      return;

    const userId = event.target as HTMLSelectElement;
    const targetUserFilter: string = JSON.stringify({
      where: { id: userId.value },
      include: [
        { UserCourses: 'coursesOfer' },
        { parentsToStudents: { parentUser: 'ContactInfos' } },
        { roleMappings: 'school' },
        {
          observationsInfos: [
            'observer',
            { additionalComments: 'observer' },
            'userapp',
          ],
        },
        'ContactInfos',
      ],
    });

    Swal.fire({
      text: 'Esto puede tardar un nomento, por favor espere.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    Swal.showLoading();
    this._observationsService.getUserInfo(targetUserFilter).subscribe({
      next: (resp) => {
        this.targetUser = resp[0];
        this.refreshUserData();
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => Swal.close(),
    });
  }

  /**
   * Hide the observation modal
   */
  public toggleModal() {
    this.showObservationModal = !this.showObservationModal;
    this.validateErrors = !this.showObservationModal;
  }

  // Store comments
  public storeAdditionalComment(comment: any, item: ObservationsInfoI) {
    if (this.shouldPreventCommenting(item) && comment.value) return;

    this.confirmMessageAlert(
      '¿Desea continuar y registrar el comentario?',
      comment.value,
      () => {
        const now = new Date();
        const localDate = new Date(
          now.getTime() - now.getTimezoneOffset() * 60000
        ).toISOString();

        const data = {
          userID: this.targetUser!.id, //To whom they comment
          date: localDate,
          comment: comment.value,
          observerID: this.observerUser!.id,
          observationID: item.id,
        };

        Swal.fire({
          text: 'Esto puede tardar un nomento, por favor espere.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        Swal.showLoading();
        forkJoin({
          obs: this.storeCommentRequest(data),
          notification: this.commentNotification(item).pipe(
            catchError((e) => {
              console.log('Error sending notification: ', e);
              return of(null);
            })
          ),
        }).subscribe({
          next: ({ obs }: { obs: ObservationsInfoI[] }) => {
            this.observations = this.filterObservations(obs);
            Swal.fire({
              icon: 'success',
              text: 'Se ha registrado su comentario correctamente.',
            });
          },
          error: (err) => {
            console.log(err);
            Swal.close();
          },
        });
      }
    );
  }

  //Store comment request
  private storeCommentRequest(data: any) {
    return this._observationsService
      .setObservations(data)
      .pipe(
        switchMap((_) =>
          this._observationsService
            .getObservations(this.commentsFilter())
            .pipe(map((observations) => observations))
        )
      );
  }

  private commentsFilter() {
    return JSON.stringify({
      where: { userID: this.targetUser!.id }, //Get selected user observations
      include: ['observer', { additionalComments: 'observer' }, 'userapp'],
    });
  }

  //Filter observation subtypes
  public getObservationSubtypes() {
    this.subTypeObservations = [];
    this.observationForm.patchValue({
      subTypeObservationIDs: null,
    });

    const value = this.observationForm.get('typeObservationID')?.value;
    const observationSubTypes = this.typeObservations.find((e) => 
      e.id === value)?.subTypeObservations;

    if (!observationSubTypes) return;

    this.subTypeObservations = observationSubTypes.map(
      (e: SubTypeObservationsI) => {
        const shcoolData = this.typeObservations.find(
          (t) => t.id === e.typeObservationID
        )?.school;
        return { id: e.id, value: e.name, image: shcoolData?.Icono };
      }
    );
  }

  //get subtype observation ids from added items
  public getSubTypeObservationIDs(event: any) {
    this.observationForm.patchValue({
      subTypeObservationIDs: event.map((e: any) => e.id),
    });
  }

  //Delete observations
  public deleteObservations(id: number) {
    if (!this.allowedRoles([1])) return;

    Swal.fire({
      icon: 'warning',
      text: '¿Estás seguro de que deseas eliminar esta observación? Esta acción no se puede deshacer.',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Esto puede tardar un nomento, por favor espere.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        Swal.showLoading();
        this._observationsService
          .patchObservation(id, { isVisible: false })
          .subscribe({
            next: (obs) => {
              Swal.fire({
                icon: 'success',
                text: 'Obsevación eliminada satisfactoriamente.',
              });

              this.observations = this.observations.filter(
                (e) => e.id !== obs.id
              );
            },
            error: (err) => {
              Swal.close();
              console.log(err);
            },
          });
      }
    });
  }

  //Store instructor final comment
  public storaFinalComment(
    comment: HTMLInputElement,
    event: { files: File[]; clear: () => void },
    item: ObservationsInfoI
  ) {
    const validation =
      item.observerID === this.observerUser!.id &&
      this.allowedRoles([1, 3, 18]);

    if (!validation || (!comment.value && event.files.length === 0)) return;

    this.confirmMessageAlert(
      '¿Desea continuar y registrar la anotación final?',
      comment.value,
      () => {
        Swal.fire({
          text: 'Esto puede tardar un nomento, por favor espere.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        Swal.showLoading();

        forkJoin({
          finalComment: !comment.value ? of(null) : this._observationsService.patchObservation(item.id, {
            finalComment: comment.value,
          }),
          finalCommentFile: event.files.length === 0 ? of(null) : this.uploadFiles(event.files, item),
        }).pipe(
            switchMap(({ finalCommentFile }) => {
              if(!finalCommentFile) return of(null);

              return this.finalCommentNotification(
                finalCommentFile.minuteAttachment,
                item
              ).pipe(
                catchError((e) => {
                  console.log('Error sending notification: ', e);
                  return of(null);
                })
              );
            }),
            switchMap((_) => {
              return this._observationsService
                .getObservations(this.commentsFilter())
                .pipe(map((observations) => observations));
            })
          )
          .subscribe({
            next: (resp) => {
              this.observations = this.filterObservations(resp);
              comment.value = '';
              event.clear();
              Swal.fire({
                icon: 'success',
                text: 'La anotación ha sido cargada correctamente.',
              });
            },
            error: (err) => {
              Swal.fire({
                icon: 'error',
                text: `Hubo un problema al cargar la respuesta. Por favor, intente nuevamente.`,
              });
              console.log(err);
            },
          });
      }
    );
  }

  // Upload file qry
  private uploadFiles(event: File[], item: ObservationsInfoI) {
    const bucketName: string = environment.obsBucketName;
    return from(this._attachmentService.storageFile(bucketName, event[0])).pipe(
      switchMap((result: any) => {
        const fileUrl = `/attachments/${bucketName}/download/${result['result']['files']['file'][0]['name']}`;
        return this._observationsService.patchObservation(item.id, {
          minuteAttachment: fileUrl,
        });
      })
    );
  }

  //#region NOTIFICATIONS 🔔

  //Notification setup
  public sendNotification(name: string, email: string, message: string) {
    const senderName = this._titleCasePipe.transform(
      `${this.observerUser!.Name1} ${this.observerUser!.LastName1}`
    );

    const senderEmail =
      this.observerUser!.email || this.observerUser!.CedocEmail;

    const notificationData: any = {
      namesTO: JSON.stringify([name]),
      emailsTo: JSON.stringify([email]),
      msg: JSON.stringify([{ message }]),
      timeToSend: new Date().toISOString(),
      templateFile: 'template.html',
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email',
      senderName,
      senderEmail,
    };

    return this._observationsService.sendNotification(notificationData);
  }

  //Notification when an observation is created, only roles 1, 3, 18 can create
  private observationNotification(item:ObservationsInfoI) {
    const studentName = this._titleCasePipe.transform(
      `${this.targetUser!.Name1} ${this.targetUser!.LastName1}`
    );
    const obsPath = `
      <br>
      <br>
      <a 
        href="${environment.pagePath}/academicStatus/observations/${
      this.targetUser!.id
    }"
          target="_blank" 
          style="display: block;
            text-decoration: none;
            color: #153643; 
            font-family: Arial, sans-serif; 
            font-size: 16px; 
            font-weight: 800; 
            padding: 8px 16px; 
            border-radius: 999px; 
            background-color: #fcdb1a; 
            width: fit-content; 
            margin-left: auto; 
            margin-right: 0;"
        >
          Ver observaciones
        </a>`;

    const parentUser = this.targetUser!.parentsToStudents?.[0]?.parentUser;
    const observerName = this._titleCasePipe.transform(
      `${this.observerUser!.Name1} ${this.observerUser!.LastName1}`
    );

    const notifications = [
      //To instructor
      this.sendNotification(
        `Nueva observación registrada`,
        this.observerUser!.CedocEmail,
        `Su observación con ID <strong>${item.id}</strong>, ha sido registrada con éxito. ${obsPath}`
      ),
      //Parent
      parentUser
        ? this.sendNotification(
            `Nueva observación registrada`,
            parentUser.email,
            `Se registró una observación con el ID <strong>${item.id}</strong> para el alumno ${studentName}. ${obsPath}`
          )
        : of(null),
      //Student
      this.sendNotification(
        'Nueva observación registrada',
        this.targetUser!.CedocEmail,
        `${observerName} registró una observación con el ID <strong>${item.id}</strong>. ${obsPath}`
      ),
    ];

    return concat(...notifications.filter((e) => e)).pipe(toArray());
  }

  //Notification when a comment is created
  private commentNotification(item: ObservationsInfoI) {
    const obsPath = `
      <br>
      <br>
      <a 
        href="${environment.pagePath}/academicStatus/observations/${
      this.targetUser!.id
    }"
          target="_blank" 
          style="display: block;
            text-decoration: none;
            color: #153643; 
            font-family: Arial, sans-serif; 
            font-size: 16px; 
            font-weight: 800; 
            padding: 8px 16px; 
            border-radius: 999px; 
            background-color: #fcdb1a; 
            width: fit-content; 
            margin-left: auto; 
            margin-right: 0;"
        >
          Ver comentarios
        </a>`;

    const parentUser = this.targetUser!.parentsToStudents?.[0]?.parentUser;

    const observeName = this._titleCasePipe.transform(
      `${this.observerUser!.Name1} ${this.observerUser!.LastName1}`
    );

    const whenObserverComments: Observable<any>[] = [
      //To student
      this.sendNotification(
        'Nuevo comentario registrado',
        this.targetUser!.CedocEmail,
        `${observeName} registró un comentario en la observación con ID <strong>${item.id}</strong>. ${obsPath}`
      ),
      //To instructor
      this.sendNotification(
        'Nuevo comentario registrado',
        item.observer.CedocEmail,
        `${observeName} registró un comentario en la observación con ID <strong>${item.id}</strong>. ${obsPath}`
      ),
      parentUser
        ? this.sendNotification(
            `Nuevo comentario registrado`,
            parentUser.email,
            `${observeName} registró un comentario en la observación con ID <strong>${item.id}</strong>. ${obsPath}`
          )
        : of(null),
    ];


    const notifications: Observable<any>[] = [
      ...whenObserverComments
    ];

    return concat(...notifications.filter((e) => e)).pipe(toArray());
  }

  //Notification when a final annotation is created, only roles 1, 3, 18 can create
  private finalCommentNotification(
    documentUrl: string,
    item: ObservationsInfoI
  ) {
    const obsPath = `
    <br>
    <br>
    <a 
      href="${environment.pagePath}/academicStatus/observations/${
      this.targetUser!.id
    }"
        target="_blank" 
        style="display: block;
          text-decoration: none;
          color: #153643; 
          font-family: Arial, sans-serif; 
          font-size: 16px; 
          font-weight: 800; 
          padding: 8px 16px; 
          border-radius: 999px; 
          background-color: #fcdb1a; 
          width: fit-content; 
          margin-left: auto; 
          margin-right: 0;"
      >
        Ver anotación
      </a>`;

    const document = `
    <br>
    <br>
    <a 
      href="${this.baseUrl}/${documentUrl}"
        target="_blank" 
        style="display: block;
          text-decoration: none;
          color: #153643; 
          font-family: Arial, sans-serif; 
          font-size: 16px; 
          font-weight: 800; 
          padding: 8px 16px; 
          border-radius: 999px; 
          background-color: #fcdb1a; 
          width: fit-content; 
          margin-left: auto; 
          margin-right: 0;"
      >
        Ver adjunto
      </a>`;

    const parentUser = this.targetUser!.parentsToStudents?.[0]?.parentUser;

    const studentName = this._titleCasePipe.transform(
      `${this.targetUser!.Name1} ${this.targetUser!.LastName1}`
    );

    const observeName = this._titleCasePipe.transform(
      `${this.observerUser!.Name1} ${this.observerUser!.LastName1}`
    );

    const notifications: Observable<any>[] = [
      //To instructor
      this.sendNotification(
        'Anotación final registrada',
        item.observer.CedocEmail,
        `Su anotación final identificada con ID <strong>${item.id}</strong>, ha sido registrado con éxito. ${document}, ${obsPath}`
      ),
      //To student
      this.sendNotification(
        'Anotación final registrada',
        this.targetUser!.CedocEmail,
        `${observeName} agregó una anotación final a la observación con el ID <strong>${item.id}</strong>.${document}, ${obsPath}`
      ),
      parentUser
        ? this.sendNotification(
            `Anotación final registrada`,
            parentUser.email,
            `Se registró una anotación final para el alumno ${studentName} en la observación con el ID <strong>${item.id}</strong>. ${document}, ${obsPath}`
          )
        : of(null),
    ];

    return concat(...notifications.filter((e) => e)).pipe(toArray());
  }
  //#endregion

  //Filter by year
  public filterByYear(event: Event) {
    const target = event.target as HTMLInputElement;

    const filter = JSON.stringify({
      where: {
        and: [
          { userID: this.targetUser!.id },
          { date: { regexp: `/${target.value}/` } },
        ],
      },
      include: ['observer', { additionalComments: 'observer' }, 'userapp'],
    });

    this._observationsService.getObservations(filter).subscribe({
      next: (resp: any) => {
        this.observations = this.filterObservations(resp);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  //Gets current user schools
  private mapSchool() {
    const roles: any[] = this.targetUser!.roleMappings;
    roles.forEach((role) => {
      const schoolExists = this.schoolList.some(
        (item) => item.id === role.school.id
      );
      if (!schoolExists) {
        this.schoolList.push({
          id: role.school.id,
          schoolName: role.school.NameTSchool,
        });
      }
    });
  }

  //They can only comment once if ...
  public shouldPreventCommenting(item: ObservationsInfoI) {
    const canComment =
      item.observerID === this.observerUser!.id ||
      this.observerUser!.id === this.targetUser!.id ||
      this.isMyChild();

    if (canComment) {
      //allowedRoles([1, 3, 18]))...
      //it is the same as saying item.observerID === this.observerUser!.id
      if (this.allowedRoles([1, 3, 18])) return false;

      if (this.allowedRoles([2, 29]) && item.additionalComments.length > 0) {
        const alreadyCommented = item.additionalComments.some(
          (e) => e.observerID === this.observerUser!.id
        );
        return !!alreadyCommented;
      }

      return false;
    }

    return true;
  }

  private filterObservations(observations: any[]) {
    return observations.sort((a, b) => b.id - a.id).filter((e) => e.isVisible);
  }

  //Check for current user child
  public isMyChild() {
    if (!this.observerUser) return false;

    const parentIds =
      this.targetUser!.parentsToStudents?.map((e) => e.ParentUserId) || [];

    return this.allowedRoles([29]) && parentIds.includes(this.observerUser.id);
  }

  //Sets allowed roles
  public allowedRoles(allowedRoles: number[]) {
    if (!this.observerUser) return false;

    const mappedRoles = this.observerUser.roleMappings.map((e) => e.roleId);
    return mappedRoles.some((e) => allowedRoles.includes(e));
  }

  //Filter years
  private mapYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 10; i <= currentYear; i++) this.yearList.push(i);

    this.yearList.sort((a, b) => b - a);
  }

  //Stop propagation
  public stopPropagation(event: any) {
    event.stopPropagation();
  }

  private refreshUserData() {
    const observations = this.targetUser!.observationsInfos || [];
    
    this.observations = this.filterObservations(observations);

    this.parentList = this.targetUser!.parentsToStudents || [];
    this.mapSchool();
    this.getStudentGrade();
  }

  private getStudentGrade() {
    const userCourse = this.targetUser!.UserCourses?.[0];

    const isGradeValid =
      userCourse &&
      userCourse.IsAcepted &&
      userCourse.IsPaied &&
      userCourse.coursesOfer?.IsActive;

    this.userGrade = isGradeValid
      ? userCourse.coursesOfer?.NameCourseOfer || ''
      : '';
  }

  public print() {
    window.print();
  }
}
