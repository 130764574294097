import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable()
export class AttachmentService {

  constructor ( private http: HttpClient, private authService: AuthService ) {
  }
  private baseUrl: string = this.authService.UrlAPI;


  private getHeaders() {
    let headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    } );
    return headers;
  }

  private putHeaders() {
    let headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    } );
    return headers;
  }

  DeleteFileByID( container: string, filename: string ): Observable<any> {
    let a = this
      .http
      .delete( `${ this.baseUrl }/attachments/${ container }/files/${ filename }`, { headers: this.putHeaders() } )
      .pipe( catchError( handleError ) )
    return a;
  }


  //Stolen from: https://developer.mozilla.org/en-US/docs/Using_files_from_web_applications
  storageFile( container: string, file: File ) {
    return new Promise( ( resolve, reject ) => {

      var xhr = new XMLHttpRequest();
      var fd = new FormData();

      xhr.open( "POST", `${ this.baseUrl }/attachments/${ container }/upload`, true );

      xhr.onreadystatechange = function () {
        if ( xhr.readyState == 4 && xhr.status == 200 ) {
          resolve( JSON.parse( xhr.responseText ) );
        }
      };
      fd.append( 'file', file );
      xhr.setRequestHeader( 'Authorization', this.authService.getToken() )
      xhr.send( fd );

    } );
  }


}


function handleError( error: any ) {
  let errorMsg = error.message || `error attachment!`
  console.error( errorMsg );

  return throwError( () => new Error( errorMsg ) );
}


