import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { SubjectsI, UserCoursesI } from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component( {
  selector: 'approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: [ './approval-modal.component.css' ]
} )
export class ApprovalModalComponent implements OnInit {

  constructor ( private _attachmentService: AttachmentService, private _approvalService: ApprovalService ) { }

  @Output( 'updateList' ) updateList: EventEmitter<void> = new EventEmitter();
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();
  @Input( 'selectedSubjectInfo' ) selectedSubjectInfo: SubjectsI = {} as SubjectsI;
  @Input( 'userCourseInfo' ) userCourseInfo: UserCoursesI = {} as UserCoursesI;
  private _dataFile: any = {
    fileUrl: '',
    fileName: ''
  };
  public fileToUpload: File;
  public fileValidations: any = {
    hasErrors: false,
    message: 'SUBIR ARCHIVO',
    color: '',
    steps: { //steps done
      note: false,
      file: false
    }
  };
  public note?: number = undefined;
  private _containerName: string = environment.reqInfoBucketName;

  ngOnInit(): void {
    //
  }

  //limits the value of the note input between 0 and 5
  noteValue( event: any ) {
    let value = event.target.value;
    this.note = this.setNoteValue( value );
    event.target.value = this.note;
    this.fileValidations.steps.note = true;
  }

  /**
   * limits the value
   * @param value
   * @returns
   */
  setNoteValue( value: any ) {
    if ( value < 0 || value === '' )
      return 0;

    return value > 5 ? 5 : value;
  }

  /**
   * when uploading a file, validates its size and assigns an error text
   * @param event
   * @returns
   */
  onLoadFile( event: any ) {
    if ( event.target.files.length === 0 ) return;
    this.fileToUpload = event.target.files[ 0 ];

    if ( this.fileToUpload.size > 420000000 ) {//420MB
      this.fileValidations.hasErrors = true;
      this.fileValidations.message = 'El archivo es muy grande';
      this.fileValidations.color = 'red';
      return;
    }

    this.fileValidations.hasErrors = false;
    this.fileValidations.message = 'CAMBIAR ARCHIVO';
    this.fileValidations.color = 'green';

    this.fileValidations.steps.file = true;
  }

  /**
   * uploads a file to the storage and send the necessary requests to create a new approval.
   * @returns
   */
  sendData() {
    if ( this.fileValidations.hasErrors || this._dataFile === undefined || this.note === undefined )
      return;
    Swal.fire({ 
      title: "Cargando",
      text: 'Estamos cargando tu homologación, por favor espera"', 
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();

    this._attachmentService.storageFile( this._containerName, this.fileToUpload ).then( ( result: any ) => {
      this._dataFile.fileUrl = `/attachments/${ this._containerName }/download/${ result[ 'result' ][ 'files' ][ 'file' ][ 0 ][ 'name' ] }`;
      this.createSubjectGroup();
    } );
  }

  /**
   * creates a new SubjectGroup
   */
  createSubjectGroup() {
    let data = {
      CourseGroupID: this.userCourseInfo.CourseGroupID,
      SubjectID: this.selectedSubjectInfo.id,
      UserID: this._approvalService.adminUser.id, //admin
      MidTerms: 1,
      DateStart: Date.now(),
      DateEnd: Date.now(),
      CodeClassRoom: '',
      CodeBlackBoard: '',
      IsDateChanged: true,
      IsActive: false,
      IsPhysicalTest: false,
      TotalPeople: 1,
      CourseOferID: this.userCourseInfo.coursesOfers.id,
      NameSubjectGroup: `${ this.selectedSubjectInfo.NameSubject } (Homologación)`,
      IsHomolog: true,
      IsSemestralize: false,
      VirtualLink: ''
    }

    this._approvalService.createSubjectGroup( data ).pipe(
      switchMap( ( resp ) => this.createUserSubject( resp.id ).pipe(
        map( userSubject => {
          return {
            userSubject: userSubject,
            subjectGroup: resp
          }
        } )
      ) ),
      switchMap( ( resp ) => this.createMidTerms( resp.subjectGroup.id ).pipe(
        map( midTerms => {
          return {
            midTerms: midTerms,
            userSubject: resp.userSubject,
            subjectGroup: resp.subjectGroup
          }
        } )
      ) ),
      switchMap( ( resp ) => this.createDateRecord( resp.subjectGroup.id, resp.midTerms.id ).pipe(
        map( ( dateRecord ) => {
          return {
            dateRecord: dateRecord,
            midTerms: resp.midTerms,
            userSubject: resp.userSubject,
            subjectGroup: resp.subjectGroup
          }
        } )
      ) ), switchMap( ( resp ) => this.createUserRecords( resp.userSubject.id, resp.dateRecord.id, resp.midTerms.id ) )
    ).subscribe( {
      complete: () => {
        Swal.fire({
          icon: 'success',
          title:"¡Homologación subida con éxito!",
          allowEscapeKey:false,
          allowOutsideClick:false
        }).then(result => {
          if(result.isConfirmed){
            this.closeModal.emit();
            this.updateList.emit();
          }
        })
      }, error: ( err ) => {
        Swal.fire({
          icon: 'error',
          title: "Error",
          text: err.status === 401 ? "Error de permisos." : "Error al procesar su solicitud."
        });
        console.log( err )
      }
    } )
  }

  /**
   * creates a new UserSubject
   * @param subjectGroupId
   * @returns
   */
  createUserSubject( subjectGroupId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      FinalRecord: this.note,
      UserID: this.userCourseInfo.UserID,
      IsHomolog: true,
      IsAproved: true,
      UserCourseID: this.userCourseInfo.id,
      SubjectID: this.selectedSubjectInfo.id,
      IsAcepted: true
    }
    return this._approvalService.createUserSubject( data );
  }

  /**
   * creates a new MidTerms
   * @param subjectGroupId
   * @returns
   */
  createMidTerms( subjectGroupId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      MidTermNum: 1,
      NameMid: 'Nota 1',
      Porcent: 100,
      IsDisabled: 0
    }
    return this._approvalService.createMidTerms( data );
  }

  /**
   * creates a new DateRecord
   * @param subjectGroupId
   * @param midTermId
   * @returns
   */
  createDateRecord( subjectGroupId: number, midTermId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      MidTermID: midTermId,
      ProfileWhAutorizeID: this._approvalService.adminUser.id,
      IsExtension: false,
      DateStartRecord: Date.now(),
      DateEndREcord: Date.now(),
      Excuse: '',
      attachmentLink: this._dataFile.fileUrl
    }
    return this._approvalService.createDateRecord( data )
  }

  /**
   * creates a new UserRecord
   * @param userSubjectId
   * @param dateRecordId
   * @param midTermId
   * @returns
   */
  createUserRecords( userSubjectId: number, dateRecordId: number, midTermId: number ) {
    let data = {
      UserSubjectID: userSubjectId,
      ParcialRecord: this.note,
      DateRecordID: dateRecordId,
      MidTermID: midTermId,
      IsAccording: true,
      IsModify: true
    }
    return this._approvalService.createUseRecord( data );
  }
}