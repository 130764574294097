<div class="approval-header">
  <h2 class="approval-text--lg">INFORMACIÓN BÁSICA DEL ESTUDIANTE</h2>
  <div class="approval-header__info-container">
    <img class="approval-header__user-avatar" [src]="userInfo.Photo" alt="">
    <div class="approval-header__info-list">
      <p class="approval-text approval-text--lg">
        Nombre: 
        <span class="approval-text--bold">
          {{ userInfo.Name1 | titlecase }}
          {{ userInfo.Name2 | titlecase }}
          {{ userInfo.LastName1 | titlecase }}
          {{ userInfo.LastName2 | titlecase }}
        </span>
      </p>
      <p class="approval-text approval-text--lg">
        Email: 
        <span class="approval-text--bold">{{ userInfo.email }}</span>
      </p>
      <p class="approval-text approval-text--lg">
        Documento: 
        <span class="approval-text--bold">
          cc{{ userInfo.UserDocuments?.[0]?.Document }}
        </span>
      </p>
      <a routerLink="/Cv-Public/{{ userInfo.email }}" class="button button--blue" target="_blank"
        *ngIf="userInfo.email != undefined">Ver más</a>
    </div>
  </div>
</div>