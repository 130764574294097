import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, fromEvent, map, of, switchMap } from 'rxjs';
import { RegisterChildrenService } from 'src/app/services/register-children/register-children.service';
@Component({
  selector: 'find-user-dropdown',
  templateUrl: './find-user.component.html',
  styleUrls: ['./find-user.component.css']
})
export class FindUserComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput:ElementRef;
  @Output('result') result:EventEmitter<any[]> = new EventEmitter();
  @Input('id') id:string = '';
  @Input('placeholder') placeholder:string = '';
  @Output('onItemClick') onItemClick:EventEmitter<any> = new EventEmitter();
  @Output('onSelectRelationship') onSelectRelationship:EventEmitter<string> = new EventEmitter();
  @Output('onConfirm') onConfirm:EventEmitter<any> = new EventEmitter();
  @Input('usersToIgnore') usersToIgnore:any[] = [];
  @Input('relationships') relationships:any[] = [];
  public userList:any[] = [];
  public selectedItem:any = null;
  public selectedRelationship: Event | null = null;
  constructor(
    private _registerChildrenService: RegisterChildrenService,
  ) {}
  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const filter = (value:string) => JSON.stringify({
      where: { or: [{ Document: { regexp: `/${value}/` } }] },
      limit: 4
    })

    fromEvent<Event>(this.searchInput.nativeElement, 'keyup').pipe(
      map((event:Event) => {
        const value = (event.target as HTMLInputElement).value.trim().toLocaleLowerCase();
        return value;
      }),
      debounceTime(400),
      switchMap((value:string)=> {
        return value.length > 3 ? this._registerChildrenService.getUser(filter(value)) : of([])
      } )
    ).subscribe({
      next:(resp:any)=>{
        this.selectedItem = null;
        this.userList = resp.filter(e => !this.usersToIgnore.some(user => user.id === e.id));
        this.result.emit( this.userList );
      },error:((err)=>{
        console.log(err);
      })
    })
  }

  public onItemClickEmit(item:any, event: Event){
    event.stopPropagation();
    event.preventDefault();

    this.userList = this.userList.map(e => ({
      ...e,
      isSelected: item.id === e.id
    }))

    this.selectedItem = item;
    this.onItemClick.emit(item);
  }

  public onConfirmClick(){
    this.onSelectRelationship.emit((this.selectedRelationship?.target as HTMLInputElement).value);
    this.onConfirm.emit();
  }
}
