<div class="row">
  <div class="col-lg-9">
    <div class="card">
      <div class="card-header">
        <h3 class=" my-2">
          <strong>Sábana de notas</strong>
        </h3>
      </div>

      <div class="card-block">
        <form action="">
          <div class="form-group row mx-auto col-lg-6">
            <select class="form-control" name="yearInput"
              (change)="loadCourseOffers($event); courseOfferInput.value = '0'; subjectInput.value = '0'">
              <option [value]=" 0" disabled selected>Seleccione un tipo de curso</option>
              <option [value]="true">Activos</option>
              <option [value]="false">Inactivos</option>
            </select>
          </div>

          <div class="form-group row mx-auto col-lg-6" [ngClass]="courseOffers.length === 0 ? 'disabled':''">
            <select class="form-control" name="yearInput" (change)="loadSubjects($event); subjectInput.value = '0'"
              #courseOfferInput>
              <option [value]="0" disabled selected>Seleccione un curso</option>
              <option [value]="courseOffer.id" *ngFor="let courseOffer of courseOffers">{{courseOffer.NameCourseOfer}}
              </option>
            </select>
          </div>

          <div class="form-group row mx-auto col-lg-6" [ngClass]="subjects.length === 0 ? 'disabled':''">
            <select class="form-control" name="yearInput" (change)="showTable($event, courseOfferInput.value)"
              #subjectInput>
              <option [value]="0" disabled selected>Seleccione una materia</option>
              <option [value]="subject.id" *ngFor="let subject of subjects">{{subject.NameSubject}}</option>
            </select>
          </div>

          <div class="form-group row mx-auto col-lg-6" *ngIf="rowData.length > 0">
            <button type="button" class="btn btn-primary w-100" (click)="exportExcel()">
              Generar CSV
            </button>
          </div>

          <div class="form-group row mx-auto col-lg-6" *ngIf="rowData.length > 0">
            <button type="button" class="btn btn-primary w-100" (click)="exportPdf()">
              Generar PDF
            </button>
          </div>
        </form>

        <ag-grid-angular [ngClass]="'ag-theme-alpine'" [ngStyle]=" {'width':'100%','height':'400px'}"
          [rowData]="rowData" [animateRows]="true" [rowDragManaged]="true" [gridOptions]="gridOptions"
          *ngIf="rowData.length > 0" (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="25">
        </ag-grid-angular>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <ng-container *ngIf="selectedSchoolInfo.id !== undefined ">
      <div class="card card-block">
        <div class="d-flex align-items-center">
          <img [src]="DYNAMIC_SCHOOL_LOGO(selectedSchoolInfo.id)"
            class="media-object" style="width:80px; margin-left: -1rem;" alt="school logo">
          <h3 class="m-0"><strong>{{selectedSchoolInfo.NameTSchool}}</strong></h3>
        </div>
      </div>

      <div class="card card-block" [ngStyle]="{'background-color':selectedSchoolInfo.Color}">
        <div class="d-flex align-items-center text-white">
          <em class="icon-i-student-list"></em>
          <div>
            <span><strong>ALUMNOS</strong></span>
            <h3 class="m-0"><strong>Total:</strong> {{rowData.length}}</h3>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>