import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  catchError,
  combineLatest,
  concat,
  debounceTime,
  filter,
  finalize,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
  toArray,
} from 'rxjs';
import { ResumeI } from 'src/app/models/resumeModels/resume';
import { RequestResumeService } from 'src/app/services/resume/resume.service';
import { environment } from 'src/environments/environment';
import zonesAndDistricts from '../resources/zonesAndDistricts';
import { AttachmentService } from 'src/app/services/attachment.service';
import Swal from 'sweetalert2';
import { ITypeForces } from 'src/app/models/users/type-forces';
import { IRanges } from 'src/app/models/users/ranges';
import { ITypeUser } from 'src/app/models/users/type-user';

@Component({
  selector: 'cedoc-resume',
  templateUrl: './cedoc-resume.component.html',
  styleUrls: ['./cedoc-resume.component.css'],
})
export class CedocResumeComponent implements OnInit {
  constructor(
    private _requestResumeService: RequestResumeService,
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    public _attachmentService: AttachmentService
  ) {
    /**/
  }

  public isEditing: boolean = false;
  public currentAge: number = 0;
  public shouldChangePassword: boolean = false;
  public userForm: FormGroup = this._fb.group({
    security: this._fb.group(
      {
        currentPassword: ['', [Validators.required, Validators.minLength(6)]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validator: this.newPasswordMatchValidator }
    ),

    personalInfo: this._fb.group({
      CedocEmail: ['', [this.validateFormEmail]],
      email: ['', [Validators.required, this.validateFormEmail]],
      typeUserID: ['', [Validators.required]],
    }),

    documentInfo: this._fb.group({
      TypeDocumentID: ['', [Validators.required]],
      Document: ['', [Validators.required]],
      //Can be edited once //check on login
      DateExpedition: [{ value: '', disabled: false }, Validators.required], 
      //Can be edited once //check on login
      PlaceExpedition: [{ value: '', disabled: false }, Validators.required],
    }),

    contactInfo: this._fb.group({
      Email: ['', [Validators.required, this.validateFormEmail]],
      Address: ['', Validators.required], //check on login
      Ciudad: ['', Validators.required],
      Nacionality: ['', Validators.required],
      Phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]], //check on login
      CellPhone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      //Can be edited once //check on login
      birthDate: [{ value: '', disabled: false }, Validators.required], 
    }),

    ClassificationUser: this._fb.group({
      specialRecognition: [''],
      ethnicalGroupID: [''],
      indigenousPeopleID: [''],
      blackCommunitiesID: [''],
      exceptionalCapacityID: [''],
      Displaced: [''],
      DisplacingMunicipality: [''],
      handicappedID: [''],
      ConflictVictim: [''],
      MunicipalityWhereHeWasAVictimOfTheConflict: [''],
      biologicalSexID: [''],
      civilStatusID: [''],
      SportsInterest: [''],
      Tattoo: [''],
      QuantityTattooLocationAndDescriptionOfTheFigure: [''],
      estratoID: [''],
      TransportationMethod: [''],
      zoneID: ['1'],
      FamilyIncome: [''],
      sisbenID: [''],
      SisbenSubgroup: [''],
      HousingType: [''],
      ChildOfPublicForceMember: [''],
      NumberOfPeopleInTheFamilyGroup: [''],
      NumberSiblings: [''],
      SiblingPosition: [''],
      SiblingsHigherEducation: [''],
      NumberChildren: [''],
      MaritalStatusOfParents: [''],
      MothersName: [''],
      MothersCard: [''],
      MotherLivingOrDeceased: [''],
      MothersTelephone: [''],
      MothersOccupation: [''],
      MotherEmailAddress: ['', this.validateFormEmail],
      MotherAddress: [''],
      FathersName: [''],
      FathersCertificate: [''],
      FatherLivingOrDeceased: [''],
      FathersTelephone: [''],
      FathersOccupation: [''],
      FatherAddress: [''],
      ParentEmailAddress: ['', this.validateFormEmail],
      ArmedForcesFamily: [''],
      ForceType: [''],
      ArmedForcesFamilyName: [''],
      schoolingLevel: [''],
      NameOfHighSchoolEducationalInstitution: [''],
      WorkingDay: [''],
      Schedule: [''],
      PublicOrPrivateNature: [''],
      PresentialOrDistanceMethodology: [''],
      TypeOfHighSchool: [''],
      SecondaryEducationSpecialty: [''],
      DateAspirantBaccalaureateDegree: [''],
      InstitutionCode: [''],
      InstitutionCharacter: [''],
      Methodology: [''],
      ObtainedDegree: [''],
      GraduationRecordNo: [''],
      DiplomaFolioNo: [''],
      ICFESCode: [''],
      ICFESScore: [''],
      ICFESPresentationDate: [''],
      YearICFESPruebasSaberReport: [''],
      LecturaCriticaResult: [''],
      MathResult: [''],
      SocialStudiesResult: [''],
      NaturalSciencesResult: [''],
      EnglishAreaResult: [''],
      GuardianName: [''],
      GuardianIDNumber: [''],
      GuardianAddress: [''],
      GuardianPhone: [''],

      CurrentStatus: [''],
      MilitaryID: [''],
      MilitaryIDNumber: [''],
      MilitaryService: [''],
      SoldierForceType: [''],
      DocumentIssuanceDate: [''],
      UnitMilitaryService: [''],
      VeteransLaw: [''],
      Contingent: [''],
      ContingentYear: [''],
      Grade: [''],
      BloodType: [''],
      eps: [''],
      ers: [''],
      HrFactor: [''],
      recruitingZone: [''],
      MilitaryDistrict: [''],
      CamouflageSize: [''],
    }),

    ProfesionalProfile: this._fb.group({
      Description: [''],
      Skills: [''],
      Language: [''],
    }),

    militarInfo: this._fb.group({
      realRangeID: ['', Validators.required],
      ForceID:  ['', Validators.required],
    }),

    workInfo: this._fb.array([]),
    scientificInfo: this._fb.array([]),
    academicInfo: this._fb.array([]),
  });

  public userData: ResumeI | undefined = undefined;
  public courseList: any[] = [];
  public targetUserEmail: string = '';
  public countries: any[] = [];
  public typeCourses: any[] = [];
  public typeUser: ITypeUser[] = [];
  public typeEthnicalGroup: any[] = [];
  public typeIndigenousPeople: any[] = [];
  public blackCommunities: any[] = [];
  public typeExceptionalCapacity: any[] = [];
  public typeBiologicalSex: any[] = [];
  public typeCivilStatus: any[] = [];
  public typeHandicapped: any[] = [];
  public typeEstratos: any[] = [];
  public typeZones: any[] = [];
  public typeSisben: any[] = [];
  public typeForces: ITypeForces[] = [];
  public ranges: IRanges[] = [];
  public typeDocuments: any[] = [];
  public recruitmentZones: any[] = zonesAndDistricts;
  public recruitmentDistrict: any[] = [];

  public observerUserRoles: any[] = [];

  //#region User document
  public fileName: string = 'Cargar documento';
  private _userDocument: File | undefined = undefined;
  //#endregion

  public validForm: boolean = true;
  public isLoading: boolean = false;
  public isMyResume: boolean = false;
  public isPublicMilitar: boolean = false;
  public isMyChild:boolean = false;
  public isCurrentPasswordValid: boolean = false;

  //#region Form getters
  get workInfoControls(): AbstractControl[] {
    return (this.userForm.get('workInfo') as FormArray)?.controls || [];
  }

  get scientificInfoControls(): AbstractControl[] {
    return (this.userForm.get('scientificInfo') as FormArray)?.controls || [];
  }

  get academicInfoControls(): AbstractControl[] {
    return (this.userForm.get('academicInfo') as FormArray)?.controls || [];
  }

  get skillsControls(): AbstractControl[] {
    return (this.userForm.get('skills') as FormArray)?.controls || [];
  }

  get languagesControls(): AbstractControl[] {
    return (this.userForm.get('languages') as FormArray)?.controls || [];
  }
  //#endregion

  //#region Environment variables
  public BACK_V1_URL: string = environment.backV1ApiV1;
  public principalAppName: string = environment.principalAppName;
  public SCHOOL_NAME = environment.SCHOOL_NAME;
  //#endregion

  ngOnInit(): void {
    this.getUserId();
  }

  //Search for a user by their email address
  getUserId() {
    this.targetUserEmail = this._route.snapshot.paramMap.get('id') ?? '';
    if (this.targetUserEmail !== 'me') {
      if (!this.validateEmail(this.targetUserEmail)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Correo no válido.',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) this._router.navigate(['/']);
        });
        return;
      }
    }

    // Set filters for observer and target users
    const observerUserFilter: string = JSON.stringify({
      include: ['roleMappings', 'ContactInfos'],
    });
    const targetUserFilter: string = this.getUserFilterByEmail();

    const requests = [
      this._requestResumeService.getUserInfo(targetUserFilter,this.targetUserEmail === 'me').pipe(
        map(resp => resp[0] ? resp[0] : resp)
      ),
      this._requestResumeService.getUserInfo(observerUserFilter, true),
      this._requestResumeService.getBasicData('Countries'),
      this._requestResumeService.getBasicData('TypeEthnicalGroups'),
      this._requestResumeService.getBasicData('TypeIndigeniusPeople'),
      this._requestResumeService.getBasicData('TypeCommunities'),
      this._requestResumeService.getBasicData('TypeExceptionalCapacities'),
      this._requestResumeService.getBasicData('TypeBiologicalSexes'),
      this._requestResumeService.getBasicData('TypeCivilStatuses'),
      this._requestResumeService.getBasicData('TypeEstratos'),
      this._requestResumeService.getBasicData('TypeHandicappeds'),
      this._requestResumeService.getBasicData('TypeZones'),
      this._requestResumeService.getBasicData('TypeSisbens'),
      this._requestResumeService.getBasicData('Forces'),
      this._requestResumeService.getBasicData('TypeDocuments'),
      this._requestResumeService.getBasicData('TypeCourses'),
      this._requestResumeService.getBasicData('TypeUsers'),
    ]

    concat(...requests)
      .pipe(
        toArray(),
        switchMap((resp:any[])=>{
          const targetUser = resp[0];
          if (targetUser.MilitarInfos.length === 0)
            return of(resp);

          //Update militar info
          const forceID = targetUser.MilitarInfos[0].ForceID;
          return this._requestResumeService.getBasicData('Ranges', JSON.stringify({
            where: { ForceID: forceID }
          })).pipe(
            catchError(() => of(resp)),
            map((ranges:any[]) => {
              this.ranges = ranges;
              this.userForm.get('militarInfo')?.patchValue({
                ...targetUser.MilitarInfos[0],
              });

              return resp;
            }),
          );
        })
      )
      .subscribe({
        next: (data: any) => {
          const targetUser = data[0];

          // Check if target user exists
          if (!targetUser) {
            Swal.fire({
              icon: 'error',
              text: 'Correo no válido.',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) this._router.navigate(['/']);
            });

            return;
          }

          const parents = targetUser.parentsToStudents.map((e) => e.ParentUserId);
          this.observerUserRoles = data[1].roleMappings.map((e) => e.roleId);

          //Allowed to view if it is my resume
          this.isMyResume = data[1].id === targetUser.id;

          //If the observer is the parent...
          this.isMyChild = this.allowedRoles([29]) 
            && parents.includes(targetUser.id);

          if (this.isMyResume || this.isMyChild || this.allowedRoles([1, 3, 6, 7, 15, 18, 25])) {
            this.userData = targetUser;
            this.currentAge = this.getCurrentAge();
            this.countries = data[2];
            this.typeEthnicalGroup = data[3];
            this.typeIndigenousPeople = data[4];
            this.blackCommunities = data[5];
            this.typeExceptionalCapacity = data[6];
            this.typeBiologicalSex = data[7];
            this.typeCivilStatus = data[8];
            this.typeEstratos = data[9];
            this.typeHandicapped = data[10];
            this.typeZones = data[11];
            this.typeSisben = data[12];
            this.typeForces = data[13];
            this.typeDocuments = data[14];
            this.typeCourses = data[15];
            this.typeUser = data[16];

            this.patchForm();

            //Check password
            this.shouldChangePassword = this.isMyResume &&
              this.userData!.pendingChangePass &&
              this.allowedRoles([10, 29]);

            if (this.shouldChangePassword) {
              this.matchPassword();
              return;
            }

            //Remove validations if shouldChangePassword is false
            this.clearMassiveValidator([
              'security.currentPassword',
              'security.newPassword',
              'security.confirmPassword',
            ]);
            return;
          }
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            text: 'No tienes permisos para ver el contenido.',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) this._router.navigate(['/']);
          });
        }
      })
  }

  private matchPassword() {
    //Match password
    this.userForm
      .get('security.currentPassword')
      ?.valueChanges.pipe(
        tap(() => (this.isCurrentPasswordValid = false)),
        map((value) => value.toString()),
        debounceTime(300),
        filter((value) => value.length > 5),
        switchMap((password) =>
          this._requestResumeService.passwordMatch(this.userData!.id, {
            password,
          })
        )
      )
      .subscribe({
        next: ({ passwordMatch }: any) => {
          this.isCurrentPasswordValid = passwordMatch;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  //Calculate user age
  public getCurrentAge() {
    const contactInfo = this.userData!.ContactInfos[0];
    const birthDate =
      contactInfo && contactInfo.birthDate
        ? new Date(contactInfo.birthDate)
        : new Date();
    const currentDate = new Date();
    let age: any = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  // Helper method to create user filter by email
  private getUserFilterByEmail(): string {
    const isMe = this.targetUserEmail === 'me';

    const filters = {
      include: [
        'TypeUser',
        { parentsToStudents: { parentUser: 'UserDocuments' } },
        'roleMappings',
        { MilitarInfos: ['Force'] },
        { UserDocuments: ['TypeDocuments'] },
        {
          ClassificationUser: [
            'typeBiologicalSex',
            'typeCivilStatus',
            'typeEthnicalGroup',
            'typeIndigeniusPeople',
            'typeHandicapped',
            'typeExceptionalCapacity',
            'typeSisben',
            'typeEstrato',
            'typeZone',
            'typeRH',
          ],
        },
        'ContactInfos',
        'WorkInfos',
        'AcademicInfos',
        'ScientificInfos',
        'ProfesionalProfile',
        { UserCourses: { coursesOfers: { course: 'School' } } },
      ],
    };

    if (!isMe) filters['where'] = { CedocEmail: this.targetUserEmail };
    return JSON.stringify(filters);
  }

  //Inserts a new work info in the form
  public addWorkInfo(data: any = undefined) {
    const dataArray = this.userForm.get('workInfo') as FormArray;

    const createFormData = (e: any = null) => {
      const formData: any = {
        Company: [e?.Company || '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
        Since: [e ? this.formatDate(e.Since) : '', Validators.required],
        Description: [e?.Description || '', [Validators.required, Validators.minLength(5)]],
        Position: [e?.Position || '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
        Unit: [e?.Unit || 'null', [Validators.minLength(2)]],
        UserID: this.userData!.id
      };
      if(e?.id) formData.id = e.id;
      return formData;
    };
  
    if (data) {
      dataArray.clear();
      data.forEach((e) => {
        dataArray.push(this._fb.group(createFormData(e)));
      });
      return;
    }

    dataArray.push(this._fb.group(createFormData()));
  }

  //Inserts a new scientific info in the form
  public addScientificInfo(data: any = undefined) {
    const dataArray = this.userForm.get('scientificInfo') as FormArray;

    const createFormData = (e: any = null) => {
      const formData: any = {
        Title: [e?.Title || '', [Validators.required, Validators.minLength(5)]],
        Institute: [e?.Institute || '', [Validators.required, Validators.minLength(5)]],
        PublicationDate: [e ? this.formatDate(e.PublicationDate) : '', [Validators.required]],
        UserID: this.userData!.id
      };
      if(e?.id) formData.id = e.id;
      return formData;
    };

    if (data) {
      dataArray.clear();
      data.forEach((e) => dataArray.push(this._fb.group(createFormData(e))));
      return;
    }

    dataArray.push(this._fb.group(createFormData()));
  }

  //Inserts a new academic info in the form
  public addAcademicInfo( data: any = undefined) {
    const dataArray = this.userForm.get('academicInfo') as FormArray;
    const createFormData = (e: any = null) => {
      const formData: any = {
        Title: [e?.Title || '', [Validators.required, Validators.minLength(5)]],
        Institute: [e?.Institute || '', [Validators.required, Validators.minLength(5)]],
        FinishDate: [e ? this.formatDate(e.FinishDate) : '', [Validators.required]],
        TypeCourseID: [e?. TypeCourseID || '', [Validators.required]],
        UserID: this.userData!.id
      };
      if(e?.id) formData.id = e.id;
      return formData;
    };

    if (data) {
      dataArray.clear();
      data.forEach((e) => {
        dataArray.push(this._fb.group(createFormData(e)));
      });
      return
    }

    dataArray.push(this._fb.group(createFormData()));
  }

  //Remove any previously added dynamic elements
  public deleteDynamicItem(
    index: number,
    data: any,
    formArrayName: string,
    path: string
  ) {
    const value = data.value;
    const dataArray = this.userForm.get(formArrayName) as FormArray;

    if (value.id) {
      this.isLoading = true;

      //Removes from database
      this._requestResumeService
        .deleteInfo(value, path, this.userData!.id)
        .subscribe({
          complete: () => {
            //Remove from form array
            const index = dataArray.controls.findIndex(
              (control) => control.value.id === value.id
            );
            if (index !== -1) dataArray.removeAt(index);
            this.isLoading = false;
          },
          error: (err) => {
            console.log(err);
            this.isLoading = false;
          },
        });
      return;
    }

    dataArray.removeAt(index);
  }

  //Patch whole form
  private patchForm() {
    //Update personalInfo form
    this.userForm.get('personalInfo')?.patchValue({...this.userData!})

    this.userForm.get('contactInfo')?.patchValue({
      ...this.userData?.ContactInfos[0],
      birthDate: this.userData!.ContactInfos[0].birthDate
        ? this.formatDate(this.userData!.ContactInfos[0].birthDate.toString()) 
        : '',
    })

    this.userForm.get('documentInfo')?.patchValue({
      ...this.userData?.UserDocuments[0],
      DateExpedition: this.userData!.UserDocuments[0].DateExpedition
      ? this.formatDate(this.userData!.UserDocuments[0].DateExpedition.toString())
      : '',
    });

    //Update ClassificationUser form
    const dateAspirantBaccalaureateDegree = this.userData!.ClassificationUser?.[0]?.DateAspirantBaccalaureateDegree || '';
    const presentationDate = this.userData!.ClassificationUser?.[0]?.ICFESPresentationDate || '';
    const documentIssuanceDate = this.userData!.ClassificationUser?.[0]?.DocumentIssuanceDate || ''

    this.userForm.get('ClassificationUser')?.patchValue({
      ...this.userData!.ClassificationUser?.[0],
      DateAspirantBaccalaureateDegree: dateAspirantBaccalaureateDegree
        ? this.formatDate(dateAspirantBaccalaureateDegree.toString())
        : '',
      ICFESPresentationDate: presentationDate
        ? this.formatDate(presentationDate.toString())
        : '',
      DocumentIssuanceDate: documentIssuanceDate
        ? this.formatDate(documentIssuanceDate.toString())
        : '',
    });

    //Update ProfesionalProfile form
    this.userForm.get('ProfesionalProfile')?.patchValue({
      ...this.userData!.ProfesionalProfile?.[0],
    });
    
    //Dynamically add items that are loaded from the database
    this.addWorkInfo(this.userData!.WorkInfos || []);
    this.addScientificInfo(this.userData!.ScientificInfos || []);
    this.addAcademicInfo(this.userData!.AcademicInfos || []);

    //Automatically load recruitmentDistrict
    const recruitingZone = this.userForm.get(
      'ClassificationUser.recruitingZone'
    )?.value;

    if (recruitingZone) {
      const zonaDistritoFinded = zonesAndDistricts.find( (e: any) => e.name === recruitingZone);
      this.recruitmentDistrict = zonaDistritoFinded ? zonaDistritoFinded.district : zonesAndDistricts[0].district;
    }

    this.disableInputs();

    //Clear validators
    const typeUser = this.userData?.TypeUser;
    this.isPublicMilitar = !!(typeUser && (typeUser.isMilitar && typeUser.isPublic));
    if(!this.isPublicMilitar){
      this.clearMassiveValidator([
        'militarInfo.realRangeID',
        'militarInfo.ForceID',
      ]);
    }
  }

  private disableInputs() {
    if(this.userForm.get('militarInfo.realRangeID')?.value){
      if(this.userData!.MilitarInfos.length > 0){
        this.userData!.MilitarInfos[0].realRangeID = this.userForm.get(
          'militarInfo.realRangeID'
        )?.value
        this.userForm.get('militarInfo.realRangeID')?.disable();
      }
    }

    if (this.userForm.get('documentInfo.DateExpedition')?.value) {
      this.userData!.UserDocuments[0].DateExpedition = this.userForm.get(
        'documentInfo.DateExpedition'
      )?.value;
      this.userForm.get('documentInfo.DateExpedition')?.disable();
    }

    if (this.userForm.get('documentInfo.PlaceExpedition')?.value) {
      this.userData!.UserDocuments[0].PlaceExpedition = this.userForm.get(
        'documentInfo.PlaceExpedition'
      )?.value;
      this.userForm.get('documentInfo.PlaceExpedition')?.disable();
    }

    if (this.userForm.get('contactInfo.birthDate')?.value) {
      this.userData!.ContactInfos[0].birthDate = this.userForm.get(
        'contactInfo.birthDate'
      )?.value;
      this.userForm.get('contactInfo.birthDate')?.disable();
      this.currentAge = this.getCurrentAge();
    }

    if (!this.allowedRoles([1])) {
      this.userForm.get('personalInfo.CedocEmail')?.disable();
      this.userForm.get('documentInfo.TypeDocumentID')?.disable();
    }

    this.userForm.get("ClassificationUser.Grade")?.disable();
  }

  //Save all data
  public saveData() {
    //I can save the data if it is my curriculum or admin
    const isAdmin = this.allowedRoles([1]);
    
    if (!(this.isMyResume || isAdmin || this.isMyChild))
      return;

    //Validate password fields
    //By default the password is valid
    let validPassword = true;

    //By default the password obs is null to send nothing
    let passwordObs: Observable<any> = of(null);

    if (this.shouldChangePassword) {
      validPassword =
        this.isCurrentPasswordValid &&
        (this.userForm.get('security')?.valid ?? false);
      if (validPassword) {
        passwordObs = this._requestResumeService.patchPassword(
          this.userData!.id,
          {password: this.userForm.get('security.confirmPassword')?.value}
        );
      }
    }

    //Ignore password because "this._requestResumeService.patchPassword" is doing the job.
    const  {pendingChangePass, password, ...userData} = this.userData!;
    const personalInfo = {
      ...userData,
      ...this.userForm.get('personalInfo')?.value,
      email: this.userForm.get('personalInfo.CedocEmail')?.value
    };

    //This should not throw an error, the tables are created when the user is created.
    const contactInfo = {
      ...this.userData!.ContactInfos[0],
      ...this.userForm.get('contactInfo')?.value,
      //UserID: this.userData!.id,
    };

    //This should not throw an error, the tables are created when the user is created.
    const documentInfo = {
      ...this.userData!.UserDocuments[0],
      ...this.userForm.get('documentInfo')?.value,
      //UserID: this.userData!.id,
    };

    const profesionalProfile = {
      ...this.userData!.ProfesionalProfile?.[0],
      ...this.userForm.get('ProfesionalProfile')?.value,

      id: this.userData!.ProfesionalProfile?.[0]?.id ?? null,
      UserID: this.userData!.id
    };

    const classificationUser = {
      ...this.userData!.ClassificationUser?.[0],
      ...this.userForm.get('ClassificationUser')?.value,

      id: this.userData!.ClassificationUser?.[0]?.id ?? null,
      UserID: this.userData!.id,
    };

    const militarInfo = {
      ...this.userData!.MilitarInfos?.[0],
      ...this.userForm.get('militarInfo')?.value,

      id: this.userData!.MilitarInfos?.[0]?.id ?? null,
      UserID: this.userData!.id,
    }

    // Exclude RangeIDS
    const { RangeIDS, Range, ...militarInfoToUpdate } = militarInfo;

    const targetUserFilter: string = this.getUserFilterByEmail();
    const observables = {
      passwordObs,
      personalInfo: this._requestResumeService.updatePersonalInfo(this.userData!.id, personalInfo),
      //Also this updates contactInfo email field
      contacatInfo: this._requestResumeService.updateInfo(contactInfo,'ContactInfos'),
      documentInfo: this._requestResumeService.updateInfo(documentInfo,'UserDocuments'),
      profesionalInfo: this._requestResumeService.updateInfo(profesionalProfile, 'ProfesionalProfiles'),
      classificationUser: this._requestResumeService.updateInfo(classificationUser, 'ClassificationUsers'),
      militarInfo: this.isPublicMilitar 
        ? this._requestResumeService.updateInfo(militarInfoToUpdate, 'MilitarInfos')
        : of(null),
      userDocument: this.saveUserDocument(),
      workInfo: this.saveDynamicItem('workInfo', 'WorkInfos'),
      scientificInfo: this.saveDynamicItem('scientificInfo', 'ScientificInfos'),
      academicInfo: this.saveDynamicItem('academicInfo', 'AcademicInfos'),
      userData: this._requestResumeService.getUserInfo(targetUserFilter,this.targetUserEmail === 'me').pipe(
        map(resp => resp[0] ? resp[0] : resp)
      ),
    };

    if (!this.userForm.valid || !validPassword) {
      Swal.fire({
        icon: 'warning',
        text: 'Por favor revisa nuevamente, hay campos por completar.',
      });

      this.userForm.markAllAsTouched();
      return;
    }

    Swal.fire({
      text: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();

    this.isLoading = true;

    forkJoin(observables).subscribe({
      next: ({ workInfo, scientificInfo, academicInfo, passwordObs, userData }) => {
        //Refresh userData
        this.userData = userData;

        //This actions is unique
        if (passwordObs) {
          this.shouldChangePassword = false;

          Swal.fire({
            icon: 'warning',
            title: '¡Cambio de contraseña exitoso!',
            text: "Su contraseña ha sido actualizada correctamente. Por favor, vuelva a iniciar sesión para aplicar los cambios'",
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) this._router.navigate(['/landing']);
          });

          return;
        }

        this.addWorkInfo(workInfo);
        this.addScientificInfo(scientificInfo);
        this.addAcademicInfo(academicInfo);

        this.isLoading = false;
        this.isEditing = false;
        this.disableInputs();

        Swal.fire({
          icon: 'success',
          title: '¡Hecho!',
          text: 'Los datos se han guardado.',
        });
      },
      error: (err) => {
        let message = err.message;
        console.log(err);
        if (err.error && err.error.error.message)
          message = err.error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Ocurrió un error: ${message}`,
        });
        this.isLoading = false;
      },
    });
  }

  //Convert comma-separated text into an array
  public textArray(text: string): string[] {
    return text.split(',').map((e) => e.trim());
  }

  //Creates an array of observables for each dynamically added element
  private saveDynamicItem(formArrayName: string, path: string) {
    const formsArray: any = this.userForm.get(formArrayName) as FormArray;
    if (formsArray.controls.length === 0) return of([]);

    const allData: any[] = formsArray.controls.map((control) => control.value);
    const updateObservables: Observable<any>[] = allData
      .map((data) =>
        this._requestResumeService.updateInfo(data, path)
      );
    const allObservables: Observable<any>[] = updateObservables;
    return combineLatest(allObservables);
  }

  public onChaneUserType(){
    const typeUser = this.typeUser.find( e => e.id == this.userForm.get("personalInfo.typeUserID")?.value);
    if(typeUser && (typeUser.isMilitar && typeUser.isPublic)){
      this.isPublicMilitar = true;
      this.setMassiveValidator(['militarInfo.realRangeID','militarInfo.ForceID'], Validators.required)
      return
    }
    
    this.isPublicMilitar = false;
    this.clearMassiveValidator(['militarInfo.realRangeID','militarInfo.ForceID']);
  }

  //Load recruitmentZone list
  public onSelectRecruitingZone() {
    const value = this.userForm.get('ClassificationUser.recruitingZone')?.value;
    this.recruitmentDistrict = [];

    this.userForm.get('ClassificationUser')?.patchValue({ MilitaryDistrict: '' });
    this.recruitmentDistrict = this.recruitmentZones.find(
      (e: any) => e.name == value
    ).district;
  }

  public onSelectForce(){
    const filters = JSON.stringify({
      where: { ForceID: this.userForm.get("militarInfo.ForceID")?.value }
    });
    
    this.userForm.patchValue({ militarInfo:{ realRangeID:'' } })
    this._requestResumeService.getBasicData('Ranges', filters).subscribe({
      next:(resp)=>{
        this.ranges = resp;
      },err:(err)=>{
        console.log(err);
      }
    })
  }

  //Set the user id file
  public onFileChange(event: any) {
    this._userDocument = event.target.files[0];
    this.fileName = this._userDocument
      ? this._userDocument.name
      : 'Cargar documento';
  }

  //Stores the user id file in database
  private saveUserDocument() {
    if (!this._userDocument) return of(null);

    if (this._userDocument.size >= 5000000) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El documento no puede ser mayor a 5MB.',
      });
      throw new Error('El documento no puede ser mayor a 5MB.');
    }

    return from(
      this._attachmentService.storageFile('documentsinfo', this._userDocument!)
    ).pipe(
      switchMap((result: any) => {
        const newDocumentFile = `/attachments/documentsinfo/download/${result.result.files.file[0].name}`;
        this.userData!.UserDocuments[0].DocumentFile = newDocumentFile;

        return this._requestResumeService.patchUserDocumentByID(
          this.userData!.UserDocuments[0].id!,
          this.userData!.id,
          { DocumentFile: newDocumentFile }
        );
      })
    );
  }

  shouldShowField(fieldName: string): { showSpan: boolean; showDiv: boolean } {
    const field = this.userForm.get(fieldName);
    if (!field) return { showSpan: false, showDiv: false };
  
    const isFieldEmpty = !field.value?.toString().trim();
    const showSpan = isFieldEmpty && !this.isEditing;
    const showDiv = !isFieldEmpty || this.isEditing;
  
    return { showSpan, showDiv };
  }

  //Formatting database date in to html date
  private formatDate(date: string) {
    const firstDate = new Date(date);
  
    if (isNaN(firstDate.getTime()))
      return new Date().toISOString().split('T')[0];
  
    return firstDate.toISOString().split('T')[0];
  }

  public openPath(path: string) {
    this._router.navigate([path, this.userData!.id]);
  }

  //Email validator for userForm
  private validateFormEmail(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value && typeof value === 'string') {
      const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}$/;
      const valid = regex.test(value);

      return valid ? null : { invalidEmail: true };
    }

    return null;
  }

  //validates if the entered url parameter is an email address
  private validateEmail(email: string) {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$/;
    return regex.test(email);
  }

  public allowedRoles(allowedRoles: number[]) {
    return this.observerUserRoles.some((e) => allowedRoles.includes(e));
  }
  //Password validator
  private newPasswordMatchValidator(g: FormGroup) {
    return g.get('newPassword')!.value === g.get('confirmPassword')!.value
      ? null
      : { passwordMismatch: true };
  }

  private setMassiveValidator(fields: string[], validator: ValidatorFn) {
    fields.forEach((field) => {
      const control = this.userForm.get(field);
      if (control) {
        control.setValidators(validator);
        control.updateValueAndValidity();
      }
    });
  }

  private clearMassiveValidator(fields:string[]){
    fields.forEach(field => {
      const control = this.userForm.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }
}