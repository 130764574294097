<div class="approval-modal">
  <em class="icon-i-close" (click)="closeModal.emit()"></em>
  <h2 class="approval-text approval-text--white">Homologar: {{selectedSubjectInfo.NameSubject}}</h2>
  <p class="approval-text approval-text--white approval-text--bold">
    Desde aquí usted podrá generar una homologación
  </p>

  <div class="note-input__container">
    <label class="note-input__label approval-text approval-text--white" for="">
      NOTA
      <input class="note-input" type="number" (change)="noteValue($event)">
    </label>
    <em class="icon-i-plus"></em>
    <button class="approval-button"
      [ngClass]="fileValidations.steps.note && fileValidations.steps.file ? '' : 'disabled'" (click)="sendData()">
      HOMOLOGAR
    </button>
  </div>

  <div class="approval-modal__warning">
    <em class="icon-i-late-course-directive"></em>
    <p class="approval-text approval-text--white">
      IMPORTANTE, SE DEBE SUBIR UN ARCHIVO PARA PODER JUSTIFICAR LA HOMOLOGACIÓN
    </p>
  </div>

  <div class="approval-modal__upload-file"
    [ngClass]="fileValidations.color !== '' ?  'approval-modal__upload-file--' + fileValidations.color : ''"
    (click)="fileInput.click()">
    <em class="icon-i-border-upload"></em>
    <div class="upload-file__text">
      <p class="approval-text approval-text--white approval-text--underline">{{fileValidations.message}}</p>
      <p class="approval-text approval-text--white approval-text--sm">PDF (max 420mb)</p>
    </div>
    <input type="file" #fileInput (change)="onLoadFile($event)" hidden>
  </div>

  <div class="approval-modal__progression">
    <span class="progression__item progression__item--active"></span>
    <span class="progression__item"
      [ngClass]="fileValidations.steps.note || fileValidations.steps.file  ? 'progression__item--active' : ''"></span>
    <span class="progression__item"
      [ngClass]="fileValidations.steps.note && fileValidations.steps.file  ? 'progression__item--active' : ''"></span>
  </div>

</div>

<div class="modal-bg"></div>