import { Component, Input, OnInit } from '@angular/core';
import { CoursesOffersI } from 'src/app/models/approvalModels/approvalModels';

@Component( {
  selector: 'approval-card',
  templateUrl: './approval-card.component.html',
  styleUrls: [ './approval-card.component.css' ]
} )
export class ApprovalCardComponent implements OnInit {

  //get basic group information
  @Input( 'courseInfo' ) courseInfo: CoursesOffersI = {} as CoursesOffersI;
  @Input('isWithdrawn') isWithdrawn:boolean = false;

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * calculate the date difference to know the status of the course
   * @returns
   */
  getCourseStatus(): string {
    const currentDate = new Date().getTime();
    const inscriptEnd = new Date( this.courseInfo.InscriptEnd ).getTime();
    const realUntil = new Date( this.courseInfo.RealUntil ).getTime();

    const isInInscription = ( this.courseInfo.InscriptStart && this.courseInfo.InscriptEnd ) && ( currentDate < inscriptEnd );
    const isCoursing = ( this.courseInfo.InscriptEnd && this.courseInfo.RealUntil ) && ( currentDate < realUntil );

    if ( isInInscription ) {
      return 'EN INSCRIPCIÓN';
    } else if ( isCoursing ) {
      return 'CURSANDO';
    } else {
      return 'FINALIZADO';
    }
  }

}
