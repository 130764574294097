<section class="resume-container">
  <form [formGroup]="userForm" (submit)="saveData()" *ngIf="!loadingResume">
    <div class="info-section">
      <div class="resume-container__header">
        <div class="header">
          <h2 class="text text--xl text--bold text--blue-light">
            {{ userData?.Name1 | titlecase }} {{ userData?.Name2 | titlecase }}
            {{ userData?.LastName1 | titlecase }}
            {{ userData?.LastName2 | titlecase }}
          </h2>
          <img [src]="userData?.Photo" alt="" class="user-avatar" />
        </div>
        <h2 class="text text--blue-light text--lg">Hoja de vida</h2>
      </div>

      <div class="basic-info" formGroupName="security" *ngIf="shouldChangePassword">
        <h2 class="text text--md text--bold text--blue">Seguridad</h2>
        <div class="info__col-4">
          <div class="input" [ngClass]="{
              'input--editable': isEditing && allowedRoles([10, 29])
            }">
            <label for="">Contraseña actual *</label>
            <span class="text" *ngIf="shouldShowField('security.currentPassword').showSpan">-</span>
            <input type="password" formControlName="currentPassword" placeholder="Su contraseña actual"
              [readonly]="!isEditing || !allowedRoles([10, 29])" *ngIf="userForm.get('security.currentPassword')?.value || isEditing"/>
            <ul class="error">
              <li *ngIf="userForm.get('security.currentPassword')?.hasError('required') && userForm.get('security.currentPassword')?.touched">
                Este campo es obligatorio.
              </li>
              <li
                *ngIf="!isCurrentPasswordValid && userForm.get('security.currentPassword')?.touched && userForm.get('security.currentPassword')?.value.length > 5">
                Contraseña errónea.
              </li>
              <li
                *ngIf="userForm.get('security.currentPassword')?.hasError('minlength') && userForm.get('security.currentPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable': isEditing && allowedRoles([10, 29])}">
            <label for="">Contraseña nueva *</label>
            <span class="text" *ngIf="shouldShowField('security.newPassword').showSpan">-</span>

            <input type="password" placeholder="Su contraseña actual" formControlName="newPassword"
              [readonly]="!isEditing || !allowedRoles([10, 29])"
              *ngIf="userForm.get('security.newPassword')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('security.newPassword')?.hasError('required') && userForm.get('security.newPassword')?.touched">
                Este campo es obligatorio.
              </li>
              <li *ngIf="userForm.get('security.newPassword')?.hasError('minlength') && userForm.get('security.newPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable': isEditing && allowedRoles([10, 29])}">
            <label for="">Confirmar contraseña *</label>
            <span class="text" *ngIf="shouldShowField('security.confirmPassword').showSpan">-</span>
            <input type="password" placeholder="Confirmar nueva contraseña" formControlName="confirmPassword"
              [readonly]="!isEditing || !allowedRoles([10, 29])" *ngIf="userForm.get('security.confirmPassword')?.value || isEditing"/>
            <ul class="error">
              <li *ngIf="userForm.get('security')?.hasError('passwordMismatch') && userForm.get('security.confirmPassword')?.touched">
                Las contraseñas no coinciden.
              </li>
              <li *ngIf="userForm.get('security.confirmPassword')?.hasError('minlength') && userForm.get('security.confirmPassword')?.touched">
                La contraseña debe tener mínimo 6 caracteres.
              </li>
              <li *ngIf="userForm.get('security.confirmPassword')?.hasError('required') && userForm.get('security.confirmPassword')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="basic-info" >
        <h2 class="text text--md text--bold text--blue">Información básica</h2>
        <div class="info__col-4">
          <div class="input" formGroupName="personalInfo" [ngClass]="{'input--editable' : isEditing && allowedRoles([1, 10, 29])}"> 
            <label for="">Tipo de usuario *</label>
            <span class="text" *ngIf="shouldShowField('personalInfo.typeUserID').showSpan">-</span>
            <div class="select-container" *ngIf="shouldShowField('personalInfo.typeUserID').showDiv">
              <select formControlName="typeUserID" [ngClass]="{'no-events' : !isEditing}" (change)="onChaneUserType()">
                <option value="" selected disabled>Seleccionar</option>
                <option [ngValue]="item.id" *ngFor="let item of typeUser">{{ item.nameTypeUser }}</option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing && allowedRoles([1, 10, 29])"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('personalInfo.typeUserID')?.hasError('required') 
                && userForm.get('personalInfo.typeUserID')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <ng-container formGroupName="documentInfo">
            <div class="input" [ngClass]="{ 'input--editable': isEditing && allowedRoles([1]) }">
              <label for="">Tipo de documento *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.TypeDocumentID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('documentInfo.TypeDocumentID').showDiv">
                <select name="" formControlName="TypeDocumentID" [ngClass]="{ 'no-events': !isEditing }">
                  <option value="" selected disabled>Seleccionar</option>
                  <option [ngValue]="item.id" *ngFor="let item of typeDocuments">{{ item.NameTypeDocument }}</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing && allowedRoles([1])"></em>
              </div>
              <ul class="error">
                <li *ngIf="userForm.get('documentInfo.TypeDocumentID')?.hasError('required') 
                  && userForm.get('documentInfo.TypeDocumentID')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
  
            <div class="input" [ngClass]="{ 'input--editable': isEditing && allowedRoles([1]) }">
              <label for="">No. Documento *</label>
              <span class="text" *ngIf="shouldShowField('documentInfo.Document').showSpan">-</span>
              <input type="text" formControlName="Document" [readonly]="!isEditing || !allowedRoles([1])"
                *ngIf="userForm.get('documentInfo.Document')?.value || isEditing" />
              <ul class="error">
                <li *ngIf=" userForm.get('documentInfo.Document')?.hasError('required') 
                  && userForm.get('documentInfo.Document')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
          </ng-container>

          <ng-container formGroupName="contactInfo">
            <div class="input" [ngClass]="{'input--editable' : isEditing && !userData?.ContactInfos?.[0]?.birthDate}">
              <label for="">Fecha de nacimiento *</label>
              <span class="text" *ngIf="shouldShowField('contactInfo.birthDate').showSpan">-</span>
              <input type="date" formControlName="birthDate" [readonly]="!isEditing || userData?.ContactInfos?.[0]?.birthDate"
                *ngIf="shouldShowField('contactInfo.birthDate').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('contactInfo.birthDate')?.hasError('required') 
                  && userForm.get('contactInfo.birthDate')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
  
            <div class="input">
              <label for="">Edad</label>
              <input type="text" [value]="currentAge" readonly />
            </div>
  
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Correo personal *</label>
              <span class="text" *ngIf="shouldShowField('contactInfo.Email').showSpan">-</span>
              <input type="text" formControlName="Email" [readonly]="!isEditing" *ngIf="shouldShowField('contactInfo.Email').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('contactInfo.Email')?.hasError('invalidEmail') 
                  && userForm.get('contactInfo.Email')?.touched">
                  Correo no válido.
                </li>
                <li *ngIf="userForm.get('contactInfo.Email')?.hasError('required') 
                  && userForm.get('contactInfo.Email')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
          </ng-container>
        </div>

        <div class="info__col-3">
          <div class="input" [ngClass]="{ 'input--editable': isEditing && allowedRoles([1]) }" formGroupName="personalInfo">
            <label for="">Correo institucional *</label>
            <span class="text" *ngIf="shouldShowField('personalInfo.CedocEmail').showSpan">-</span>
            <input type="text" formControlName="CedocEmail" [readonly]="!isEditing || !allowedRoles([1])"
              *ngIf="userForm.get('personalInfo.CedocEmail')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('personalInfo.CedocEmail')?.hasError('required') 
                && userForm.get('personalInfo.CedocEmail')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{ 'input--editable': isEditing }" formGroupName="contactInfo">
            <label for="">Dirección del domicilio *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Address').showSpan">-</span>
            <input type="text" formControlName="Address" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Address')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Address')?.hasError('required') && userForm.get('contactInfo.Address')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>
        </div>

        <div class="info__col-4" formGroupName="contactInfo">
          <div class="input" [ngClass]="{ 'input--editable': isEditing }">
            <label for="">Ciudad *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Ciudad').showSpan">-</span>
            <input type="text" formControlName="Ciudad" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Ciudad')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Ciudad')?.hasError('required') 
                && userForm.get('contactInfo.Ciudad')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{ 'input--editable': isEditing }">
            <label for="">País *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Nacionality').showSpan">-</span>
            <div class="select-container" *ngIf="userForm.get('contactInfo.Nacionality')?.value || isEditing">
              <select name="" formControlName="Nacionality" [ngClass]="{ 'no-events': !isEditing }">
                <option [ngValue]="null" selected disabled>Seleccionar</option>
                <option [ngValue]="item.id" *ngFor="let item of countries">{{ item.country_name }}</option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Nacionality')?.hasError('required') 
                && userForm.get('contactInfo.Nacionality')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{ 'input--editable': isEditing }">
            <label for="">Número de teléfono *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.Phone').showSpan">-</span>
            <input type="text" formControlName="Phone" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.Phone')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.Phone')?.invalid && userForm.get('contactInfo.Phone')?.touched">
                Este campo es obligatorio y debe contener solo números.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{ 'input--editable': isEditing }">
            <label for="">Número celular *</label>
            <span class="text" *ngIf="shouldShowField('contactInfo.CellPhone').showSpan">-</span>
            <input type="text" formControlName="CellPhone" [readonly]="!isEditing"
              *ngIf="userForm.get('contactInfo.CellPhone')?.value || isEditing" />
            <ul class="error">
              <li *ngIf="userForm.get('contactInfo.CellPhone')?.invalid && userForm.get('contactInfo.CellPhone')?.touched">
                Este campo es obligatorio y debe contener solo números.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="basic-info" *ngIf="parentToStudentsData.myChilds.length > 0 && parentResume">
        <h2 class="text text--md text--bold text--blue">Padre de</h2>
        <div class="resume-table">
          <table>
            <thead>
              <tr>
                <th [ngStyle]="{width:'100px'}">ID</th>
                <th>Nombre</th>
                <th>Documento</th>
                <th>Parentesco</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let child of parentToStudentsData.myChilds">
                <td>{{child.studentUser.id}}</td>
                <td>
                  <div class="table__user-content">
                    <img [src]="child.studentUser.Photo" alt="user picture" *ngIf="child.studentUser.Photo">
                    <div *ngIf="!child.studentUser.Photo">{{child.studentUser.Name1.charAt(0)}}</div>
                    {{child.studentUser.Name1}} {{child.studentUser.LastName1}}
                  </div>
                </td>
                <td>{{child.studentUser.UserDocuments[0].Document}}</td>
                <td>{{child.typeRlelationshipParent?.nameRelationship || '-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="basic-info" *ngIf="parentToStudentsData.attendantOf.length > 0 && parentResume">
        <h2 class="text text--md text--bold text--blue">Acudiente de</h2>
        <div class="resume-table">
          <table>
            <thead>
              <tr>
                <th [ngStyle]="{width:'100px'}">ID</th>
                <th>Nombre</th>
                <th>Documento</th>
                <th>Parentesco</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let child of parentToStudentsData.attendantOf">
                <td>{{child.studentUser.id}}</td>
                <td>
                  <div class="table__user-content">
                    <img [src]="child.studentUser.Photo" alt="user picture" *ngIf="child.studentUser.Photo">
                    <div *ngIf="!child.studentUser.Photo">{{child.studentUser.Name1.charAt(0)}}</div>
                    {{child.studentUser.Name1}} {{child.studentUser.LastName1}}
                  </div>
                </td>
                <td>{{child.studentUser.UserDocuments[0].Document}}</td>
                <td>{{child.typeRlelationshipAttended?.nameRelationship || '-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="about-left__content" formGroupName="ProfesionalProfile">
        <h2 class="text text--md text--bold text--blue">
          Acerca de {{ userData?.Name1 | titlecase }}
          {{ userData?.LastName1 | titlecase }}
        </h2>

        <div class="input" [ngClass]="{ 'input--editable': isEditing }">
          <span class="text" *ngIf="shouldShowField('ProfesionalProfile.Description').showSpan">-</span>
          <textarea name="" rows="4" class="" [readonly]="!isEditing" formControlName="Description" *ngIf="
              userForm.get('ProfesionalProfile.Description')?.value || isEditing
            "></textarea>
        </div>
      </div>

      <div class="about">
        <div class="about-left" *ngIf="parentResume">
          <!-- WORK INFO -->
          <!-- If I'm a child and it's my curriculum or I'm a parent and it's my child, hide workInfo. -->
          <div class="dynamic-info__container" formArrayName="workInfo">
            <h2 class="text text--md text--bold text--blue">
              Información laboral
            </h2>

            <span *ngIf="workInfoControls.length === 0 && !isEditing">-</span>
            <div *ngIf="workInfoControls.length > 0">
              <div class="dynamic-info__content" *ngFor="let workInfoGroup of workInfoControls; let i = index"
                [formGroupName]="i">
                <div class="dynamic-info__content-header" *ngIf="isEditing">
                  <h2 class="text text--blue text--bold">
                    {{ workInfoGroup.get("Company")?.value || "..." }}
                  </h2>
                  <button *ngIf="i !== 0" class="button button--delete" type="button"
                    [ngClass]="{ disabled: isLoading }" 
                    (click)="deleteDynamicItem(i, workInfoGroup, 'workInfo', 'WorkInfos')">
                    <em class="icon icon-i-trash"></em> Eliminar
                  </button>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                    <label for="">Empresa *</label>
                    <input type="text" formControlName="Company" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('required') && workInfoGroup.get('Company')?.touched">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('minlength') && workInfoGroup.get('Company')?.touched">
                        Debe tener al menos 5 caracteres.
                      </li>
                      <li *ngIf="workInfoGroup.get('Company')?.hasError('maxlength') && workInfoGroup.get('Company')?.touched">
                        No puede tener más de 50 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                    <label for="">Fecha *</label>
                    <input type="date" formControlName="Since" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="workInfoGroup.get('Since')?.hasError('required') && workInfoGroup.get('Since')?.touched">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Visible for attendant -->
                <ng-container *ngIf="parentResume">
                  <div class="dynamic-info__col-2 dynamic-info__col-2--reverse">
                    <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                      <label for="">Teléfono *</label>
                      <input type="text" formControlName="Phone" [readonly]="!isEditing" />
                      <ul class="error">
                        <li *ngIf="workInfoGroup.get('Phone')?.hasError('required') && workInfoGroup.get('Phone')?.touched">
                          Este campo es obligatorio.
                        </li>
                        <li *ngIf="workInfoGroup.get('Phone')?.hasError('minlength') && workInfoGroup.get('Phone')?.touched">
                          Debe tener al menos 7 caracteres.
                        </li>
                        <li *ngIf="workInfoGroup.get('Phone')?.hasError('maxlength') && workInfoGroup.get('Phone')?.touched">
                          No puede tener más de 20 caracteres.
                        </li>
                        <li *ngIf="workInfoGroup.get('Phone')?.hasError('pattern') && workInfoGroup.get('Phone')?.touched">
                          Solo puede contener dígitos.
                        </li>
                      </ul>
                    </div>

                    <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                      <label for="">Dirección *</label>
                      <input type="text" formControlName="Address" [readonly]="!isEditing" />
                      <ul class="error">
                        <li *ngIf="workInfoGroup.get('Address')?.hasError('required') && workInfoGroup.get('Address')?.touched">
                          Este campo es obligatorio.
                        </li>
                        <li *ngIf="workInfoGroup.get('Address')?.hasError('minlength') && workInfoGroup.get('Address')?.touched">
                          Debe tener al menos 3 caracteres.
                        </li>
                        <li *ngIf="workInfoGroup.get('Address')?.hasError('maxlength') && workInfoGroup.get('Address')?.touched">
                          No puede tener más de 60 caracteres.
                        </li>
                        <li *ngIf="workInfoGroup.get('Address')?.hasError('pattern') && workInfoGroup.get('Address')?.touched">
                          Solo se permiten '#', "'", '°', '.', '-', '_', '/'.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="dynamic-info__col-2">
                    <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                      <label for="">Correo de trabajo *</label>
                      <input type="text" formControlName="workEmail" [readonly]="!isEditing" />
                      <ul class="error">
                        <li *ngIf="workInfoGroup.get('workEmail')?.hasError('required') && workInfoGroup.get('workEmail')?.touched">
                          Este campo es obligatorio.
                        </li>
                        <li *ngIf="workInfoGroup.get('workEmail')?.hasError('invalidEmail') && workInfoGroup.get('workEmail')?.touched">
                          Correo no válido.
                        </li>
                      </ul>
                    </div>

                    <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                      <label for="">Unidad</label>
                      <span class="text" *ngIf="!isEditing && !workInfoGroup.get('Unit')?.value">-</span>
                      <input type="text" formControlName="Unit" [readonly]="!isEditing" *ngIf="isEditing || workInfoGroup.get('Unit')?.value" />
                      <ul class="error">
                        <li *ngIf="workInfoGroup.get('Unit')?.hasError('minlength') && workInfoGroup.get('Unit')?.touched">
                          Debe tener al menos 3 caracteres.
                        </li>
                        <li *ngIf="workInfoGroup.get('Unit')?.hasError('maxlength') && workInfoGroup.get('Unit')?.touched">
                          No puede tener más de 30 caracteres.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>

                <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                  <label for="">Cargo *</label>
                  <input type="text" formControlName="Position" [readonly]="!isEditing" />
                  <ul class="error">
                    <li *ngIf=" workInfoGroup.get('Position')?.hasError('required') && workInfoGroup.get('Position')?.touched">
                      Este campo es obligatorio.
                    </li>
                    <li *ngIf="workInfoGroup.get('Position')?.hasError('minlength') && workInfoGroup.get('Position')?.touched">
                      Debe tener al menos 5 caracteres.
                    </li>
                    <li *ngIf="workInfoGroup.get('Position')?.hasError('maxlength') && workInfoGroup.get('Position')?.touched">
                      No puede tener más de 50 caracteres.
                    </li>
                  </ul>
                </div>

                <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                  <label for="">Descripción del cargo *</label>
                  <textarea rows="4" formControlName="Description" [readonly]="!isEditing"></textarea>
                  <ul class="error">
                    <li *ngIf=" workInfoGroup.get('Description')?.hasError('required') && workInfoGroup.get('Description')?.touched">
                      Este campo es obligatorio.
                    </li>
                    <li *ngIf="workInfoGroup.get('Description')?.hasError('minlength') && workInfoGroup.get('Description')?.touched">
                      El texto debe ser mayor a 5 caracteres.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <button class="button button--add" type="button" [ngClass]="{ disabled: isLoading }" (click)="addWorkInfo()"
              *ngIf="isEditing">
              Agregar
            </button>
          </div>
        </div>

        <div class="about-right">
          <!-- ACADEMIC INFO -->
          <div class="dynamic-info__container" formArrayName="academicInfo">
            <h2 class="text text--md text--bold text--blue">
              Formación académica
            </h2>

            <span *ngIf="academicInfoControls.length === 0 && !isEditing">-</span>
            <div *ngIf="academicInfoControls.length > 0">
              <div class="dynamic-info__content" *ngFor="let academicInfo of academicInfoControls; let i = index"
                [formGroupName]="i">
                <div class="dynamic-info__content-header" *ngIf="isEditing">
                  <h2 class="text text--blue text--bold">
                    {{ academicInfo.get("Title")?.value || "..." }}
                  </h2>
                  <button *ngIf="i !== 0" class="button button--delete" type="button"
                    [ngClass]="{ disabled: isLoading }" (click)="deleteDynamicItem(
                        i,
                        academicInfo,
                        'academicInfo',
                        'AcademicInfos'
                      )
                    ">
                    <em class="icon icon-i-trash"></em> Eliminar
                  </button>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                    <label for="">Título *</label>
                    <input type="text" formControlName="Title" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="academicInfo.get('Title')?.hasError('required') &&
                          academicInfo.get('Title')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="academicInfo.get('Title')?.hasError('minlength') &&
                          academicInfo.get('Title')?.touched
                        ">
                        El texto debe ser mayor a 5 caracteres.
                      </li>
                    </ul>
                  </div>

                  <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                    <label for="">Fecha de graduación *</label>
                    <input type="date" formControlName="FinishDate" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="academicInfo
                            .get('FinishDate')
                            ?.hasError('required') &&
                          academicInfo.get('FinishDate')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="dynamic-info__col-2">
                  <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                    <label for="">Institución *</label>
                    <input type="text" formControlName="Institute" [readonly]="!isEditing" />
                    <ul class="error">
                      <li *ngIf="academicInfo.get('Institute')?.hasError('required') &&
                          academicInfo.get('Institute')?.touched
                        ">
                        Este campo es obligatorio.
                      </li>
                      <li *ngIf="academicInfo
                            .get('Institute')
                            ?.hasError('minlength') &&
                          academicInfo.get('Institute')?.touched
                        ">
                        El texto debe ser mayor a 5 caracteres.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <button class="button button--add" type="button" [ngClass]="{ disabled: isLoading }"
              (click)="addAcademicInfo()" *ngIf="isEditing">
              Agregar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Hidden for parent -->
    <div class="info-section">
      <div class="info-section__content">
        <h2 class="text text--blue-light text--lg text--bold">
          Información personal
        </h2>

        <!-- Hidden for parent -->
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información demográfica
          </h2>
          <div class="info__col-4" formGroupName="ClassificationUser">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }"
              *ngIf="(isMyResume && allowedRoles([2,10])) || isMyChild">
              <label for="">¿Posee discapacidad?</label>
              <span class="text" *ngIf=" shouldShowField('ClassificationUser.handicappedID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.handicappedID').showDiv">
                <select formControlName="handicappedID" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeHandicapped">
                    {{ item.disabilityName }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Sexo biológico</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.biologicalSexID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.biologicalSexID').showDiv">
                <select formControlName="biologicalSexID" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>Seleccionar</option>
                  <option [ngValue]="item.id" *ngFor="let item of typeBiologicalSex">
                    {{ item.nameBiologicalSex }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content" formGroupName="ClassificationUser" *ngIf="parentResume">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Socioeconómica</h2>
          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Estrato social</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.estratoID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.estratoID').showDiv">
                <select formControlName="estratoID" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeEstratos">
                    {{ item.nameEstrato }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Información familiar -->
    <div class="info-section" formGroupName="ClassificationUser" *ngIf="principalAppName == 'cedoc'">
      <h2 class="text text--blue-light text--lg text--bold">
        Información familiar
      </h2>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Situación Familiar y Estructural
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Hijo de Miembro de la Fuerza Pública</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ChildOfPublicForceMember')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ChildOfPublicForceMember')
                    .showDiv
                ">
                <select name="" formControlName="ChildOfPublicForceMember" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Ejército Nacional de Colombia'">
                    Ejército Nacional de Colombia
                  </option>
                  <option [value]="'Policía'">Policía</option>
                  <option [value]="'Armada Nacional'">Armada Nacional</option>
                  <option [value]="'Fuerza Aérea'">Fuerza Aérea</option>
                  <option [value]="'INPEC'">INPEC</option>
                  <option [value]="'NO APLICA'">No aplica</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Cantidad de personas del grupo familiar
                <span class="text--sm">(incluido el aspirante)</span></label>
              <span class="text" *ngIf="
                  shouldShowField(
                    'ClassificationUser.NumberOfPeopleInTheFamilyGroup'
                  ).showSpan
                ">-</span>

              <input type="number" formControlName="NumberOfPeopleInTheFamilyGroup" [readonly]="!isEditing" *ngIf="
                  shouldShowField(
                    'ClassificationUser.NumberOfPeopleInTheFamilyGroup'
                  ).showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">No. Hermanos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.NumberSiblings').showSpan
                ">-</span>
              <input type="number" formControlName="NumberSiblings" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NumberSiblings').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">No. Posición entre Hermanos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SiblingPosition').showSpan
                ">-</span>
              <input type="number" formControlName="SiblingPosition" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SiblingPosition').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">No. Hermanos con Educación Superior</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.SiblingsHigherEducation')
                    .showSpan
                ">-</span>
              <input type="number" formControlName="SiblingsHigherEducation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.SiblingsHigherEducation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Si tiene Hijos indique el número de hijos</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.NumberChildren').showSpan
                ">-</span>
              <input type="number" formControlName="NumberChildren" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.NumberChildren').showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información de la Madre
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Estado civil de los padres</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MaritalStatusOfParents')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.MaritalStatusOfParents')
                    .showDiv
                ">
                <select name="" formControlName="MaritalStatusOfParents" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Casados'">Casados</option>
                  <option [value]="'Union libre'">Union libre</option>
                  <option [value]="'Separados'">Separados</option>
                  <option [value]="'Divorciados'">Divorciados</option>
                  <option [value]="'Viudo(a)'">Viudo(a)</option>
                  <option [value]="'Otra'">Otra</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Madre viva o fallecida</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherLivingOrDeceased')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.MotherLivingOrDeceased')
                    .showDiv
                ">
                <select name="" formControlName="MotherLivingOrDeceased" [ngClass]="{ 'no-events': !isEditing }"
                  #motherLivingOrDeceased>
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Vive'">Vive</option>
                  <option [value]="'Falleció'">Falleció</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Nombre de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersName').showSpan
                ">-</span>
              <input type="text" formControlName="MothersName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersName').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Número de cédula de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersCard').showSpan
                ">-</span>
              <input type="text" formControlName="MothersCard" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersCard').showDiv
                " />
            </div>
          </div>

          <div class="info__col-3">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Teléfono de la madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersTelephone')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MothersTelephone" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersTelephone').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Ocupación de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MothersOccupation')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MothersOccupation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MothersOccupation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Dirección de la madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherAddress').showSpan
                ">-</span>
              <input type="text" formControlName="MotherAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MotherAddress').showDiv
                " />
            </div>
          </div>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.MotherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Email de la Madre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.MotherEmailAddress')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="MotherEmailAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.MotherEmailAddress')
                    .showDiv
                " />
              <ul class="error">
                <li *ngIf="
                    userForm
                      .get('ClassificationUser.MotherEmailAddress')
                      ?.hasError('invalidEmail') &&
                    userForm.get('ClassificationUser.MotherEmailAddress')
                      ?.touched
                  ">
                  Correo no válido.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Información del padre
          </h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Padre vivo o fallecido</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FatherLivingOrDeceased')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.FatherLivingOrDeceased')
                    .showDiv
                ">
                <select name="" formControlName="FatherLivingOrDeceased" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Vive'">Vive</option>
                  <option [value]="'Falleció'">Falleció</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Nombre del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersName').showSpan
                ">-</span>
              <input type="text" formControlName="FathersName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersName').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Número de cédula del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersCertificate')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersCertificate" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersCertificate')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Email del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ParentEmailAddress')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="ParentEmailAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.ParentEmailAddress')
                    .showDiv
                " />
              <ul class="error">
                <li *ngIf="
                    userForm
                      .get('ClassificationUser.ParentEmailAddress')
                      ?.hasError('invalidEmail') &&
                    userForm.get('ClassificationUser.ParentEmailAddress')
                      ?.touched
                  ">
                  Correo no válido.
                </li>
              </ul>
            </div>
          </div>

          <div class="info__col-3">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Teléfono del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersTelephone')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersTelephone" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersTelephone').showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Ocupación del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FathersOccupation')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="FathersOccupation" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FathersOccupation')
                    .showDiv
                " />
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.FatherLivingOrDeceased')
                  ?.value === 'Vive'
              ">
              <label for="">Dirección del padre</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.FatherAddress').showSpan
                ">-</span>
              <input type="text" formControlName="FatherAddress" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.FatherAddress').showDiv
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">
            Vínculos con Fuerzas Armadas
          </h2>

          <div class="info__col-3">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">¿Tiene Familiar en las Fuerzas Armadas?</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamily')
                    .showSpan
                ">-</span>
              <div class="select-container" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamily')
                    .showDiv
                ">
                <select name="" formControlName="ArmedForcesFamily" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [value]="'Si'">Si</option>
                  <option [value]="'No'">No</option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.ArmedForcesFamily')?.value ===
                'Si'
              ">
              <label for="">Tipo de Fuerza</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.ForceType').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.ForceType').showDiv">
                <select formControlName="ForceType" [ngClass]="{ 'no-events': !isEditing }">
                  <option [ngValue]="null" selected disabled>
                    Seleccionar
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of typeForces">
                    {{ item.NameForce }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
            </div>

            <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                userForm.get('ClassificationUser.ArmedForcesFamily')?.value ===
                'Si'
              ">
              <label for="">Nombre del Familiar</label>
              <span class="text" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamilyName')
                    .showSpan
                ">-</span>
              <input type="text" formControlName="ArmedForcesFamilyName" [readonly]="!isEditing" *ngIf="
                  shouldShowField('ClassificationUser.ArmedForcesFamilyName')
                    .showDiv
                " />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Hidden for parent -->
    <ng-container *ngIf="!parentResume">
      <div class="info-section" formGroupName="ClassificationUser" *ngIf="!parentResume">
        <h2 class="text text--blue-light text--lg text--bold">
          Información responsable de pago
        </h2>

        <div class="info-section__content">
          <div class="basic-info">
            <h2 *ngIf="userData?.parentsToStudents?.length == 0" class="text text--md text--bold text--blue">
              Este estudiante no tiene responsable de pago
            </h2>

            <div class="info__col-4">
              <ng-container *ngFor="let parent of userData?.parentsToStudents">
                <div class="input">
                  <label for="">Nombres y apellidos: </label>
                  <span class="text">
                    {{ parent?.parentUser?.Name1 }}
                    {{ parent?.parentUser?.Name2 }}
                    {{ parent?.parentUser?.LastName1 }}
                    {{ parent?.parentUser?.LastName2 }}
                  </span>
                </div>
                <div class="input">
                  <label for="">Documento: </label>
                  <span class="text">
                    {{ parent?.parentUser?.UserDocuments[0]?.Document }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="info-section" formGroupName="ClassificationUser">
        <h2 class="text text--blue-light text--lg text--bold">
          Información académica
        </h2>

        <div class="academic-actions" *ngIf="allowedRoles([2, 3, 1, 29])">
          <button type="button" class="button button--upload-file" (click)="openPath('/academicStatus')">
            <em class="icon icon-i-academic-record"></em>
            Historial académico
          </button>
          <button type="button" class="button button--upload-file" (click)="openPath('/academicStatus/observations')">
            <em class="icon icon-i-search"></em>
            Observaciones
          </button>
        </div>
      </div>
    </ng-container>

    <div class="info-section" *ngIf="!allowedRoles([2, 10])">
      <h2 class="text text--blue-light text--lg text--bold">Información militar</h2>

      <div class="info-section__content" formGroupName="militarInfo" *ngIf="isPublicMilitar">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Información básica</h2>
        </div>

        <div class="info__col-4">
          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">Tipo de Fuerza*</label>
            <span class="text" *ngIf="shouldShowField('militarInfo.ForceID').showSpan">-</span>
            <div class="select-container" *ngIf="shouldShowField('militarInfo.ForceID').showDiv">
              <select formControlName="ForceID" [ngClass]="{'no-events' : !isEditing}" (change)="onSelectForce()">
                <option value="" selected disabled>Seleccionar</option>
                <option [value]="item.id" *ngFor="let item of typeForces">{{ item.NameForce }}</option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('militarInfo.ForceID')?.hasError('required') 
                && userForm.get('militarInfo.ForceID')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>

          <div class="input" [ngClass]="{'input--editable' : isEditing}">
            <label for="">Rango*</label>
            <span class="text" *ngIf="shouldShowField('militarInfo.realRangeID').showSpan">-</span>
            <div class="select-container" *ngIf="shouldShowField('militarInfo.realRangeID').showDiv">
              <select name="" formControlName="realRangeID" [ngClass]="{'no-events' : !isEditing}">
                <option value="" selected disabled>Seleccionar</option>
                <option [value]="item.id" *ngFor="let item of ranges">{{item.NameRange}}</option>
              </select>
              <em class="icon-i-angle-right" *ngIf="isEditing"></em>
            </div>
            <ul class="error">
              <li *ngIf="userForm.get('militarInfo.realRangeID')?.hasError('required') 
                && userForm.get('militarInfo.realRangeID')?.touched">
                Este campo es obligatorio.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Documentación</h2>

          <div class="info__col-4">
            <ng-container formGroupName="militarInfo">
              <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                <label for="">No. Cédula Militar</label>
                <span class="text" *ngIf="shouldShowField('militarInfo.CedulaMil').showSpan">-</span>
                <input type="text" formControlName="CedulaMil" [readonly]="!isEditing"
                  *ngIf="shouldShowField('militarInfo.CedulaMil').showDiv" />
              </div>
            </ng-container>

            <ng-container *ngIf="!parentResume">
              <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                <label for="">Condición</label>
                <span class="text">{{conditionName}}</span>
              </div>

              <ng-container formGroupName="ClassificationUser">
                <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                  <label for="">Zona de reclutamiento</label>
                  <span class="text" *ngIf="shouldShowField('ClassificationUser.recruitingZone').showSpan">-</span>
                  <div class="select-container" *ngIf="shouldShowField('ClassificationUser.recruitingZone').showDiv">
                    <select formControlName="recruitingZone" [ngClass]="{ 'no-events': !isEditing }"
                      (change)="onSelectRecruitingZone()">
                      <option [ngValue]="null" selected disabled>
                        Seleccionar
                      </option>
                      <option [value]="item.name" *ngFor="let item of recruitmentZones">
                        {{ item.name }}
                      </option>
                    </select>
                    <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                  </div>
                </div>

                <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                  <label for="">Distrito Militar</label>
                  <span class="text" *ngIf="shouldShowField('ClassificationUser.MilitaryDistrict').showSpan">-</span>
                  <div class="select-container" *ngIf="shouldShowField('ClassificationUser.MilitaryDistrict').showDiv">
                    <select formControlName="MilitaryDistrict" [ngClass]="{ 'no-events': !isEditing }">
                      <option [ngValue]="null" selected disabled>
                        Seleccionar
                      </option>
                      <option [value]="zone" *ngFor="let zone of recruitmentDistrict">
                        {{ zone }}
                      </option>
                    </select>
                    <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container formGroupName="ClassificationUser">
        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Vestimenta</h2>

            <div class="info__col-4">
              <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                <label for="">Talla camuflado</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.CamouflageSize').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.CamouflageSize').showDiv
                  ">
                  <select name="" formControlName="CamouflageSize" [ngClass]="{ 'no-events': !isEditing }">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="34">34</option>
                    <option [value]="36">36</option>
                    <option [value]="38">38</option>
                    <option [value]="40">40</option>
                    <option [value]="42">42</option>
                    <option [value]="44">44</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Servicio Militar</h2>

            <div class="info__col-4">
              <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                <label for="">Servicio Militar - Tipo Soldado</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.MilitaryService').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.MilitaryService').showDiv
                  ">
                  <select name="" formControlName="MilitaryService" [ngClass]="{ 'no-events': !isEditing }">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Si'">Si</option>
                    <option [value]="'No'">No</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>

              <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Fuerza</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.SoldierForceType')
                      .showSpan
                  ">-</span>
                <input type="text" formControlName="SoldierForceType" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.SoldierForceType').showDiv
                  " />
              </div>

              <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Unidad (Servicio Militar)</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.UnitMilitaryService')
                      .showSpan
                  ">-</span>
                <input type="text" formControlName="UnitMilitaryService" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.UnitMilitaryService')
                      .showDiv
                  " />
              </div>

              <div class="input" [ngClass]="{
                  'input--editable': isEditing,
                  'input--disabled':
                    isEditing &&
                    userForm.get('ClassificationUser.Grade')?.disabled
                }" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Grado</label>
                <span class="text" *ngIf="shouldShowField('ClassificationUser.Grade').showSpan">-</span>
                <input type="text" formControlName="Grade" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.Grade').showDiv" />
              </div>

              <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Contingente</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.Contingent').showSpan
                  ">-</span>
                <input type="text" formControlName="Contingent" [readonly]="!isEditing"
                  *ngIf="shouldShowField('ClassificationUser.Contingent').showDiv" />
              </div>

              <div class="input" [ngClass]="{ 'input--editable': isEditing }" *ngIf="
                  userForm.get('ClassificationUser.MilitaryService')?.value ===
                  'Si'
                ">
                <label for="">Año de Contingente</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.ContingentYear').showSpan
                  ">-</span>
                <input type="text" formControlName="ContingentYear" [readonly]="!isEditing" *ngIf="
                    shouldShowField('ClassificationUser.ContingentYear').showDiv
                  " />
              </div>
            </div>
          </div>
        </div>

        <div class="info-section__content">
          <div class="basic-info">
            <h2 class="text text--md text--bold text--blue">Ley de Veteranos</h2>

            <div class="info__col-4">
              <div class="input" [ngClass]="{ 'input--editable': isEditing }">
                <label for="">Ley de veteranos</label>
                <span class="text" *ngIf="
                    shouldShowField('ClassificationUser.VeteransLaw').showSpan
                  ">-</span>
                <div class="select-container" *ngIf="
                    shouldShowField('ClassificationUser.VeteransLaw').showDiv
                  ">
                  <select name="" formControlName="VeteransLaw" [ngClass]="{ 'no-events': !isEditing }">
                    <option [ngValue]="null" selected disabled>
                      Seleccionar
                    </option>
                    <option [value]="'Veterano'">Veterano</option>
                    <option [value]="'Cónyuge del veterano'">
                      Cónyuge del veterano
                    </option>
                    <option [value]="'Hijo del veterano hasta los 25 años'">
                      Hijo del veterano hasta los 25 años
                    </option>
                    <option [value]="'Padre o madre del veterano'">
                      Padre o madre del veterano
                    </option>
                    <option [value]="'No aplica'">No aplica</option>
                  </select>
                  <em class="icon-i-angle-right" *ngIf="isEditing"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="info-section" formGroupName="ClassificationUser">
      <h2 class="text text--blue-light text--lg text--bold">Seguridad Social</h2>

      <div class="info-section__content">
        <div class="basic-info">
          <h2 class="text text--md text--bold text--blue">Salud</h2>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Tipo de sangre</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.rhID').showSpan">-</span>
              <div class="select-container" *ngIf="shouldShowField('ClassificationUser.rhID').showDiv">
                <select formControlName="rhID" [ngClass]="{ 'no-events': !isEditing }">
                  <option [value]="null" selected disabled>Seleccionar</option>
                  <option [ngValue]="item.id" *ngFor="let item of bloodType">
                    {{ item.nameRH }}
                  </option>
                </select>
                <em class="icon-i-angle-right" *ngIf="isEditing"></em>
              </div>
              <ul class="error">
                <li *ngIf="userForm.get('ClassificationUser.rhID')?.hasError('required') &&
                  userForm.get('ClassificationUser.rhID')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
          </div>

          <div class="info__col-4">
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Seguro</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.ensuranceCompany').showSpan">-</span>
              <input type="text" formControlName="ensuranceCompany" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.ensuranceCompany').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('ClassificationUser.ensuranceCompany')?.hasError('required') 
                  && userForm.get('ClassificationUser.ensuranceCompany')?.touched ">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
  
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">EPS (Entidad Promotora de Salud)</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.eps').showSpan">-</span>
              <input type="text" formControlName="eps" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.eps').showDiv" />
              <ul class="error">
                <li *ngIf="userForm.get('ClassificationUser.eps')?.hasError('required') &&
                  userForm.get('ClassificationUser.eps')?.touched">
                  Este campo es obligatorio.
                </li>
              </ul>
            </div>
  
            <div class="input" [ngClass]="{ 'input--editable': isEditing }">
              <label for="">Caja de Compensación Familiar</label>
              <span class="text" *ngIf="shouldShowField('ClassificationUser.ers').showSpan">-</span>
              <input type="text" formControlName="ers" [readonly]="!isEditing"
                *ngIf="shouldShowField('ClassificationUser.ers').showDiv" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions" *ngIf="(isMyResume && !allowedRoles([2, 10])) ||
        allowedRoles([1]) || isMyChild">
      <button class="button button--blue" type="submit" *ngIf="isEditing">
        Guardar
      </button>
      <button class="button button--blue" type="button" (click)="isEditing = false" *ngIf="isEditing">
        Cancelar
      </button>
      <button class="button button--blue" type="button" (click)="isEditing = true" *ngIf="!isEditing">
        Editar datos
      </button>
    </div>
  </form>
</section>