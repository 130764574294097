import { UserCoursesI, UserSubjectI } from 'src/app/models/approvalModels/approvalModels';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { map, switchMap } from 'rxjs';
import { XlsxService } from 'src/app/services/xlsx.service';
import { TitleCasePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.css'],
})
export class NotesModalComponent implements OnInit {
  constructor(
    private _approvalService: ApprovalService,
    private _xlsxService:XlsxService,
    private _titleCasePipe:TitleCasePipe,
  ) {}

  @Input('selectedCourseInfo') selectedCourseInfo: 
    UserCoursesI = {} as UserCoursesI;
  @Input('isWithinDateRange') isWithinDateRange: boolean = false;
  @Output('onCloseModal') onCloseModal: EventEmitter<void> = new EventEmitter();

  public notes: UserSubjectI[] = [];

  ngOnInit(): void {
    this.updateSubjectList();
  }

  private updateSubjectList() {
    Swal.fire({ 
      title: 'Cargando',
      text: 'Esto no debería tardar mucho', 
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();

    this.getSubjectGroups()
      .pipe(
        switchMap((subjectGroups) => {
          const subjectGroupIDs = subjectGroups.map((e) => e.id);
          return this.getUserSubjects(subjectGroupIDs).pipe(
            map((userSubjects) => ({ subjectGroups, userSubjects }))
          );
        })
      )
      .subscribe({
        next: ({ userSubjects }:{userSubjects:UserSubjectI[]}) => {
          this.notes = this.getStudying(userSubjects);
          Swal.close();
        },
        error: (err) => console.log(err),
      });
  }

  /**
   * Get user subjects filter
   * @param SubjectGroupIDs 
   * @returns 
   */
  private getUserSubjects(SubjectGroupIDs: number[]) {
    let filter = JSON.stringify({
      where: {
        and: [
          { UserID: this.selectedCourseInfo.UserID },
          { SubjectGroupID: { inq: SubjectGroupIDs } },
        ],
      },
      include: [
        { subjectGroup: ['userapp', { subjects: 'AcademicaArea' }] },
        { userRecord: ['midTerm', 'dateRecord'] },
      ],
    });
    return this._approvalService.getUserSubjects(filter);
  }

  /**
   * Get subject groups filter
   * @returns 
   */
  private getSubjectGroups() {
    const filter: string = JSON.stringify({
      where: {
        and: [
          { CourseOferID: this.selectedCourseInfo.coursesOfers.id },
          { CourseGroupID: this.selectedCourseInfo.CourseGroupID },
        ],
      },
      include: ['subjects', 'userapp'],
    });
    return this._approvalService.getSubjectGroups(filter);
  }

  /**
   * Get current studying subjects
   * @param userSubjects 
   * @returns 
   */
  private getStudying(userSubjects: UserSubjectI[]) {
    if (userSubjects.length === 0 || !this.isWithinDateRange) 
      return [];

    const data = userSubjects.filter((userSubject: UserSubjectI) => {
      const hasUserRecords =
        userSubject.userRecord &&
        userSubject.userRecord.length > 0 &&
        !userSubject.IsHomolog;
      return hasUserRecords;
    });
    return data;
  }

  /**
   * Export excel
   */
  public generateReport(){
    const today = new Date();

    const data = this.notes.map((e:UserSubjectI) => ({
      "NOMBRE": this._titleCasePipe.transform(
        e.subjectGroup.subjects.NameSubject.toLowerCase()
      ),
      "NOTA": e.FinalRecord
    }));

    this._xlsxService.exportData(
      data,
      `Boletín ${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}`
    );
  }

  /**
   * On close modal notification
   * @param event 
   */
  public closeModal(event: Event) {
    event.stopPropagation();
    this.onCloseModal.emit();
  }
}