<div>
  <label
    for="file"
    class="file-box"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <div class="file-box__content">
      <em class="icon-i-filled-upload upload-icon"></em>
      <p class="file-text">
        Arrastre el archivo aquí o seleccione <strong>Buscar Archivo</strong>
      </p>
    </div>
    <input
      [multiple]="multiple"
      id="file"
      type="file"
      (change)="onSelecteFile($event)"
      [accept]="formats.join(',')"
      #files
      hidden
    />
  </label>

  <div class="file-info">
    <p class="file-text">
      Formatos soportados: <strong>{{ formats.join(", ") | uppercase }}</strong>
    </p>
    <p class="file-text">
      Tamaño máximo: <strong>{{ maxSize }}MB</strong>
    </p>
  </div>

  <div class="file-list">
    <div class="file-list__item" *ngFor="let file of fileList; let i = index">
      <div class="file-list__item-content">
        <em class="icon-i-doc"></em>
        {{ file.name | titlecase }}
      </div>

      <button class="delete-button" (click)="removeFile(i)">
        <em class="icon-i-close"></em>
      </button>
    </div>
  </div>

  <div class="file-footer">
    <p class="file-text">
      <strong>Total archivos ({{ fileList.length }})</strong>
    </p>
    <div class="file-actions">
      <button
        class="file-actions__save"
        (click)="onSave()"
      >
        Guardar
      </button>
      <button class="file-actions__cancel" (click)="fileList = []" *ngIf="fileList.length > 1">
        Limpiar
      </button>
    </div>
  </div>
</div>
