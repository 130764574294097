import { Component, Input, OnInit } from '@angular/core';
import { SubjectsI } from 'src/app/models/approvalModels/approvalModels';

@Component( {
  selector: 'approval-list-header',
  templateUrl: './approval-list-header.component.html',
  styleUrls: [ './approval-list-header.component.css' ]
} )
export class ApprovalListHeaderComponent implements OnInit {

  //receives a names list for the header
  @Input( 'headerNames' ) headerNames: string[] = [];

  constructor () { }

  ngOnInit(): void {
    //
  }


  /**
   * Returns an object with styles for a CSS Grid layout.
   * @returns
   */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${ this.headerNames.length - 1 }, 1fr)`,
      'align-items': 'center',
    }
  }

}
