import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable( {
  providedIn: 'root'
} )
export class XlsxService {

  constructor () {
    //
  }

  //export data to excel
  public exportData( data: any[], fileName: string ) {
    let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet( data );
    let wokBook: XLSX.WorkBook = {
      Sheets: { 'data': workSheet },
      SheetNames: [ 'data' ]
    };
    XLSX.writeFile( wokBook, `${ fileName }.xlsx` );
  }
}
