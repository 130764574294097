import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  baseUrl: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',
  backV1ApiV1: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  backV1ApiV2: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',

  principalAppName: 'cedoc',
  pagePath: 'http://localhost:4200',
  obsBucketName: "observationminutes",
  docBucketName: "documentsinfo",
  reqInfoBucketName: "requestinfo",

  ...schoolToLoad(),
  registerCloudFunction: 'https://us-central1-cedoc-360c.cloudfunctions.net/dev-userRegister/register',
  googleWsApi: 'https://us-central1-cedoc-360c.cloudfunctions.net/dev-consolWorkspaceClientApi',
};
