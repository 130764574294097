import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { UserCoursesI, UserSubjectI, } from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import Swal from 'sweetalert2';

@Component( {
  selector: 'subject-unapproved',
  templateUrl: './subject-unapproved.component.html',
  styleUrls: [ './subject-unapproved.component.css' ],
} )
export class SubjectUnapprovedComponent implements OnInit {

  //Sends a notification when delete button is clicked
  @Output( 'deleteAction' ) deleteAction: EventEmitter<any> = new EventEmitter();

  //sends a call when the list of subjects is updated
  @Output( 'updateList' ) updateList: EventEmitter<void> = new EventEmitter();
  @Input( 'showStatus' ) showStatus: boolean = false;
  @Input( 'noteToApprove' ) noteToApprove: number = 0;

  //obtains the userSubject data
  private _userSubject: UserSubjectI = {} as UserSubjectI;
  @Input( 'userSubject' ) set userSubject( value: UserSubjectI ) {
    this._userSubject = value;
    if ( value === undefined ) return;
    this.hasHistory = value.userRecord?.some( e => e.IsModify === true )!;
  }

  get userSubject() {
    return this._userSubject;
  }

  //Receives information about the selected course
  @Input( 'userCourseInfo' ) userCourseInfo: UserCoursesI = {} as UserCoursesI;

  public showAdditionalData: boolean = false;
  public showHistory: boolean = false;
  public hasHistory: boolean = false;
  public noteValue: number = 0;

  constructor ( private _approvalService: ApprovalService) {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * when editing a note, check that the note does not exceed 5 points.
   * @param event
   */
  valueCheck( event ) {
    const value = event.target.value;

    if ( value > 5 ) {
      event.target.value = 5;
    } else if ( event.target.value < 0 ) {
      event.target.value = 0;
    }

    this.noteValue = event.target.value;
  }

  /**
   * creates a new SubjectGroup
   */
  createSubjectGroup() {
    if( ![1, 6].some( e => this._approvalService.roles.includes(e)))
      return;

    Swal.fire({ 
      title: 'Guardando...', 
      text: "Estamos habilitando la nota, por favor espera",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();

    let data = {
      CourseGroupID: this.userCourseInfo.CourseGroupID,
      SubjectID: this.userSubject.subjectGroup.subjects.id,
      UserID: this._approvalService.adminUser.id, //admin
      MidTerms: 1,
      DateStart: Date.now(),
      DateEnd: Date.now(),
      CodeClassRoom: '',
      CodeBlackBoard: '',
      IsDateChanged: true,
      IsActive: false,
      IsPhysicalTest: false,
      TotalPeople: 1,
      CourseOferID: this.userCourseInfo.coursesOfers.id,
      NameSubjectGroup: `${ this.userSubject.subjectGroup.subjects.NameSubject } (Habilitación)`,
      IsHomolog: false,
      IsSemestralize: false,
      VirtualLink: '',
    };

    this._approvalService
      .createSubjectGroup(data)
      .pipe(
        switchMap((resp) =>
          this.createUserSubject(resp.id).pipe(
            map((userSubject) => {
              return {
                userSubject: userSubject,
                subjectGroup: resp,
              };
            })
          )
        ),
        switchMap((resp) =>
          this.createMidTerms(resp.subjectGroup.id).pipe(
            map((midTerms) => {
              return {
                midTerms: midTerms,
                userSubject: resp.userSubject,
                subjectGroup: resp.subjectGroup,
              };
            })
          )
        ),
        switchMap((resp) =>
          this.createDateRecord(resp.subjectGroup.id, resp.midTerms.id).pipe(
            map((dateRecord) => {
              return {
                dateRecord: dateRecord,
                midTerms: resp.midTerms,
                userSubject: resp.userSubject,
                subjectGroup: resp.subjectGroup,
              };
            })
          )
        ),
        switchMap((resp) => {
          return this.createUserRecords(
            resp.userSubject.id,
            resp.dateRecord.id,
            resp.midTerms.id
          );
        })
      )
      .subscribe({
        complete: () => {
          Swal.fire({
            icon: 'success',
            title: '¡Hecho!',
            text: `${
              this.noteValue >= this.noteToApprove
                ? 'Los cambios han sido guardados y ahora pueden ser consultados en la sección "APROBADAS".'
                : 'La nota se ha establecido correctamente.'
            }`,
            allowEscapeKey:false,
            allowOutsideClick:false
          }).then(result => {
            if(result.isConfirmed)
              this.updateList.emit()
          })
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: "Error",
            text: err.status === 401 ? "Error de permisos." : "Error al procesar su solicitud."
          });
          console.log(err);
        },
      });
  }

  /**
   * creates a new UserSubject
   * @param subjectGroupId
   * @returns
   */
  createUserSubject( subjectGroupId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      FinalRecord: this.noteValue,
      UserID: this.userCourseInfo.UserID,
      IsHomolog: false,
      IsAproved: this.noteValue >= this.noteToApprove,
      UserCourseID: this.userCourseInfo.id,
      SubjectID: this.userSubject.subjectGroup.subjects.id,
      IsAcepted: true,
    };
    return this._approvalService.createUserSubject( data );
  }

  /**
   * creates a new MidTerms
   * @param subjectGroupId
   * @returns
   */
  createMidTerms( subjectGroupId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      MidTermNum: 1,
      NameMid: 'Nota 1',
      Porcent: 100,
      IsDisabled: 0,
    };
    return this._approvalService.createMidTerms( data );
  }

  /**
   * creates a new DateRecord
   * @param subjectGroupId
   * @param midTermId
   * @returns
   */
  createDateRecord( subjectGroupId: number, midTermId: number ) {
    let data = {
      SubjectGroupID: subjectGroupId,
      MidTermID: midTermId,
      ProfileWhAutorizeID: this._approvalService.adminUser.id,
      IsExtension: false,
      DateStartRecord: new Date(),
      DateEndREcord: new Date(),
      Excuse: '',
      //attachmentLink: this._dataFile.fileUrl
    };
    return this._approvalService.createDateRecord( data );
  }

  /**
   * creates a new UserRecord
   * @param userSubjectId
   * @param dateRecordId
   * @param midTermId
   * @returns
   */
  createUserRecords(
    userSubjectId: number,
    dateRecordId: number,
    midTermId: number
  ) {
    let data = {
      UserSubjectID: userSubjectId,
      ParcialRecord: this.noteValue,
      DateRecordID: dateRecordId,
      MidTermID: midTermId,
      IsAccording: true,
      IsModify: true,
    };
    return this._approvalService.createUseRecord( data );
  }


  /**
  * Returns an object with styles for a CSS Grid layout.
  * @returns
  */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${ this.showStatus ? '4' : '3' }, 1fr)`,
      'align-items': 'center',
    }
  }

}
