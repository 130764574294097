import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: [ './upload-file.component.css' ]
} )
export class UploadFileComponent {

  @Input('multiple') multiple:boolean = false;
  @Input('formats') formats:string[] = [".pdf"];
  @Input('maxSize') maxSize:number = 5;
  @Output('onSaveFiles') onSaveFiles:EventEmitter<{
    files: File[],
    clear: () => void
  }> = new EventEmitter();

  public fileList:File[] = [];

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.target as HTMLElement;
    dropzone.classList.add('file-box--dragover');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.target as HTMLElement;
    dropzone.classList.remove('file-box--dragover');
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.target as HTMLElement;
    dropzone.classList.remove('file-box--dragover');

    if (event.dataTransfer?.files)
      this.fileValidations(event.dataTransfer.files);
  }

  public onSelecteFile(event:any){
    const input = event.target as HTMLInputElement;
    if (!input.files?.length)
      return;

    this.fileValidations(input.files);
    input.value = '';
  }

  private fileValidations(files:FileList){
    const filesArray = Array.from(files);

    if (!this.multiple && filesArray.length > 1)
      return window.alert("Solo se permite cargar un archivo.");

    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];

      const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
      if (!this.formats.includes(fileExtension))
        return window.alert(`El archivo ${file.name} tiene un formato no permitido.`)
      
      if (file.size > this.maxSize * 1024 * 1024)
        return window.alert(`El archivo ${file.name} excede el tamaño máximo permitido de 5MB.`)
    }

    this.fileList = filesArray;
  }

  public removeFile(index:number){
    this.fileList.splice(index, 1);
  }

  public onSave(){
    this.onSaveFiles.emit({
      files: [...this.fileList],
      clear: () => this.clearFileList()
    });
  }

  public clearFileList() {
    this.fileList = [];
    //...
  }
}