interface SchoolToLoadOptions {
  school?: string;
}

//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}

/**
 * Default school variables
 * @param school
 * @returns
 */
export const schoolToLoad = ({ school = 'cedoc' }: SchoolToLoadOptions = {}) => {
  return {
    DYNAMIC_SCHOOL_LOGO: (id: number = 0) => `${storage[school]}/modules/ecommerce/common/img/esc${id}.png`,
    SCHOOL_NAME: school.toUpperCase()
  }
}
