<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title">
      {{ subjectGroup.subjects.NameSubject }}
    </span>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="showAdditionalData ? 'approval__item--active' : ''">
    <div class="approval__list-subject">
      <!-- Subject col -->
      <span class="approval-text approval-text--blue">Créditos: {{ subjectGroup.subjects.Creditos || ''}}</span>
      <span class="approval-text">PROFESOR:
        {{ subjectGroup.userapp?.Name1 || ''}}
        {{ subjectGroup.userapp?.Name2 || ''}}
        {{ subjectGroup.userapp?.LastName1 || ''}}
        {{ subjectGroup.userapp?.LastName2 || ''}}
      </span>
      <span class="approval-text">GRUPO:
        {{ subjectGroup.NameSubjectGroup }}
      </span>
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">{{ subjectGroup.subjects.Absences || 0 }}</span>
    <!-- Final record col -->
    <ng-container *allowedRoles="[1,6]; then homologBtn; else homologText"></ng-container>
    <!-- Enable record col -->
    <span class="approval-text approval-text--center">-</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Pendiente
    </div>
  </div>

  <ng-template #homologBtn>
    <div class="homolog-actions">
      <button class="button button--enable" type="button"
        (click)="subjectActions.emit( {showModal:true, subjectInfo:subjectGroup.subjects } )">
        Homologar
      </button>
     <!--  <button class="button button--delete" type="button"
        (click)="deleteAction.emit( { showModal:true, userSubjectInfo:subjects, subjectInfo:subjects } )">
        Eliminar
      </button> -->
    </div>
  </ng-template>

  <ng-template #homologText>
    <p class="approval-text approval-text--center">-</p>
  </ng-template>
</div>
