import { Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApprovalService } from 'src/app/services/approval/approval.service';

@Directive( {
  selector: '[allowedRoles]'
} )
export class ApprovalRolesDirective implements OnInit {

  constructor (
    private _approvalService: ApprovalService,
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) { }

  @Input( 'allowedRoles' ) allowedRoles: number[] = [];
  @Input( 'allowedRolesThen' ) thenTemplate: TemplateRef<any> | null = null;
  @Input( 'allowedRolesElse' ) elseTemplate: TemplateRef<any> | null = null;


  ngOnInit(): void {
    const hasRole: boolean = this.checkUserRoles();
    if ( hasRole ) {
      const templateToRender = this.thenTemplate || this._templateRef;
      this._viewContainerRef.createEmbeddedView( templateToRender );
    } else {
      const templateToRender = this.elseTemplate || null;
      if ( templateToRender ) {
        this._viewContainerRef.createEmbeddedView( templateToRender );
      } else {
        this._viewContainerRef.clear();
      }
    }
  }

  private checkUserRoles(): boolean {
    const userRoles =  this._approvalService.roles;
    return this.allowedRoles.some( role => userRoles.includes( role ) );
  }
}