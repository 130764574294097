import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserI } from 'src/app/models/approvalModels/approvalModels';
import { HttpService } from '../http.service';

@Injectable( {
  providedIn: 'root'
} )
export class ApprovalService {

  constructor ( private _httpService: HttpService ) { }

  private _roles: number[] = [];
  get roles() {
    return this._roles;
  }

  set roles( value: any[] ) {
    this._roles = value;
  }

  private _adminUser: any;
  get adminUser() {
    return this._adminUser;
  }

  set adminUser( value: any ) {
    this._adminUser = value;
  }

  /* == */

  getUserInfo( filter?: string ): Observable<UserI> {
    return this._httpService.httpGet( `/Userapps${ filter }` );
  }

  getChildren(parentId: number): Observable<any> {
    const filter = JSON.stringify({
      where: { ParentUserId: parentId },
      include: [{ studentUser: 'UserDocuments' }]
    });

    const url: string = `/ParentsToStudents?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getSubject( filter?: string ): Observable<any> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/Subjects${ newFilter }` );
  }

  getSubjectGroups( filter?: string ): Observable<any> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/SubjectGroups${ newFilter }` );
  }

  getUserSubjects( filter?: string ): Observable<any> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/UserSubjects${ newFilter }` );
  }

  createSubjectGroup( data: any ): Observable<any> {
    return this._httpService.httpPost( '/SubjectGroups', data );
  }

  createUserSubject( data: any ): Observable<any> {
    return this._httpService.httpPost( '/UserSubjects', data );
  }

  createDateRecord( data: any ): Observable<any> {
    return this._httpService.httpPost( '/DateRecords', data );
  }

  createMidTerms( data: any ): Observable<any> {
    return this._httpService.httpPost( '/MidTerms', data );
  }

  createUseRecord( data: any ): Observable<any> {
    return this._httpService.httpPost( '/UserRecords', data );
  }

  updateUserRecord( data: any, id:number): Observable<any> {
    return this._httpService.httpPatchBackV1( `/UserRecords/${id}`, data );
  }

  sendNotification(data:any):Observable<any>{
    return this._httpService.httpPost(`/NotificationQueues`, data);
  }

  deleteUserSubject( id: number): Observable<any> {
    return this._httpService.httpDelete( `/UserSubjects/${ id }`);
  }
}