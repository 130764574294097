<div class="register-form__container" *ngIf="registeredChildren === undefined"> 
  <div class="register-form__title">
    <button class="button button--blue" (click)="onCloseForm.emit()"><em class="icon-i-close"></em></button>
    <h2 class="title">Registrar aspirante / estudiante</h2>
    <span></span>
  </div>

  <span class="register-form__title-span">Todos los campos con asterisco * son obligatorios</span>

  <form class="register-form" [formGroup]="registerForm">
    <div class="register-form__grid" [hidden]="pagination === 2"> 
      <div formGroupName="basicInfo">
        <label for="">Primer nombre*</label>
        <input type="text" class="register-form__input" placeholder="Escribir nombre" formControlName="firstName">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('basicInfo.firstName')?.hasError('required') && registerForm.get('basicInfo.firstName')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
  
      <div formGroupName="basicInfo">
        <label for="">Segundo nombre</label>
        <input type="text" class="register-form__input" placeholder="Escribir nombre" formControlName="middleName">
      </div>

      <div formGroupName="basicInfo">
        <label for="">Primer Apellido*</label>
        <input type="text" class="register-form__input" placeholder="Escribir apellido" formControlName="lastName">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('basicInfo.lastName')?.hasError('required') && registerForm.get('basicInfo.lastName')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="basicInfo">
        <label for="">Segundo Apellido</label>
        <input type="text" class="register-form__input" placeholder="Escribir apellido" formControlName="secondLastName">
      </div>

      <div [ngClass]="documentTypes.length === 0 ? 'disabled' : ''" formGroupName="documentInfo">
        <label for="">Tipo de documento*</label>
        <select name="" id="" class="register-form__input" formControlName="documentTypeId">
          <option [value]="''" selected disabled>Seleccionar</option>
          <option [ngValue]="document.id" *ngFor="let document of documentTypes">{{ document.NameTypeDocument }}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('documentInfo.documentTypeId')?.hasError('required') && registerForm.get('documentInfo.documentTypeId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="documentInfo">
        <label for="">Número de documento*</label>
        <input type="text" class="register-form__input" placeholder="Número" formControlName="document">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('documentInfo.document')?.hasError('required') && registerForm.get('documentInfo.document')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="contactInfo">
        <label for="">Teléfono Celular*</label>
        <input type="number" class="register-form__input" placeholder="Teléfono" formControlName="cellphone">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('contactInfo.cellphone')?.hasError('required') && registerForm.get('contactInfo.cellphone')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="contactInfo" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">Teléfono casa*</label>
        <input type="number" class="register-form__input" placeholder="Teléfono casa" formControlName="phone">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('contactInfo.phone')?.hasError('required') && registerForm.get('contactInfo.phone')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="basicInfo">
        <label for="">Correo*</label>
        <input type="text" class="register-form__input" placeholder="Correo" formControlName="email">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('basicInfo.email')?.hasError('required') && registerForm.get('basicInfo.email')?.touched">Este campo es obligatorio.</li>
          <li class="error-list__item" *ngIf="registerForm.get('basicInfo.email')?.hasError('invalidEmailDomain') && registerForm.get('basicInfo.email')?.touched">Correo inválido.</li>
        </ul>
      </div>

      <div formGroupName="contactInfo" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">Fecha de nacimiento*</label>
        <input type="date" class="register-form__input" formControlName="birthDate">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('contactInfo.birthDate')?.hasError('required') && registerForm.get('contactInfo.birthDate')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="contactInfo" *ngIf="SCHOOL_NAME === 'CELIC'">  
        <label for="">Nacionalidad*</label>
        <select class="register-form__input" formControlName="nacionality">
          <option value="" disabled selected>Seleccionar</option>
          <option [ngValue]="item.id" *ngFor="let item of countries">{{item.country_name}}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('contactInfo.nacionality')?.hasError('required') && registerForm.get('contactInfo.nacionality')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
      
      <div formGroupName="classificationUser" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">Sexo biológico*</label>
        <select name="" id="" class="register-form__input" formControlName="biologicalSexId">
          <option value="" selected disabled>Seleccionar</option>
          <option [ngValue]="item.id" *ngFor="let item of typeBiologicalSex">{{ item.nameBiologicalSex }}</option>
        </select>
        
        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('classificationUser.biologicalSexId')?.hasError('required') && registerForm.get('classificationUser.biologicalSexId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
      
      <div formGroupName="classificationUser" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">Tipo de sangre*</label>
        <select name="" id="" class="register-form__input" formControlName="rhId">
          <option value="" selected disabled>Seleccionar</option>
          <option [ngValue]="item.id" *ngFor="let item of bloodType">{{ item.nameRH }}</option>
        </select>
        
        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('classificationUser.rhId')?.hasError('required') && registerForm.get('classificationUser.rhId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
      
      <div formGroupName="classificationUser" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">EPS*</label>
        <input type="string" class="register-form__input" placeholder="EPS" formControlName="eps">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('classificationUser.eps')?.hasError('required') && registerForm.get('classificationUser.eps')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div formGroupName="classificationUser" *ngIf="SCHOOL_NAME === 'CELIC'"> 
        <label for="">Seguro*</label>
        <input type="string" class="register-form__input" placeholder="Seguro" formControlName="ensuranceCompany">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('classificationUser.ensuranceCompany')?.hasError('required') && registerForm.get('classificationUser.ensuranceCompany')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
    </div>

    <div class="register-form__grid" [hidden]="pagination === 1">
      <ng-container formGroupName="basicInfo" *ngIf="SCHOOL_NAME === 'CEDOC'">
        <div>
          <label for="">Personal civil / militar*</label>
          <select class="register-form__input" formControlName="typeUserId" (change)="onSelectPersonalType()">
            <option [ngValue]="item.id!" *ngFor="let item of typeUser">{{item.nameTypeUser}}</option>
          </select>
  
          <ul class="error-list">
            <li class="error-list__item" *ngIf="registerForm.get('basicInfo.typeUserId')?.hasError('required') && registerForm.get('basicInfo.typeUserId')?.touched">Este campo es obligatorio.</li>
          </ul>
        </div>
      </ng-container>

      <div formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true">
        <label for="">Cédula militar*</label>
        <input type="text" class="register-form__input" placeholder="Número" formControlName="militaryDocument">

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('militaryInfo.militaryDocument')?.hasError('required') && registerForm.get('militaryInfo.militaryDocument')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div [ngClass]="forces.length === 0 ? 'disabled' : ''" formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true">
        <label for="">Fuerza*</label>
        <select name="" id="" class="register-form__input" formControlName="forceId" (change)="onSelectForce()">
          <option [value]="''" selected disabled>Seleccionar</option>
          <option [ngValue]="force.id" *ngFor="let force of forces">{{ force.NameForce }}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('militaryInfo.forceId')?.hasError('required') && registerForm.get('militaryInfo.forceId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div [ngClass]="ranges.length === 0 ? 'disabled' : ''" formGroupName="militaryInfo" *ngIf="registerForm.get('isMilitary')?.value === true">
        <label for="">Rango*</label>
        <select name="" id="" class="register-form__input" formControlName="otanId">
          <option [value]="''" selected disabled>Seleccionar</option>
          <option [ngValue]="range.OtanID" *ngFor="let range of ranges">{{range.NameRange}}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('militaryInfo.otanId')?.hasError('required') && registerForm.get('militaryInfo.otanId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div [ngClass]="schools.length === 0 ? 'disabled' : ''" formGroupName="courseInfo">
        <label for=""  >{{SCHOOL_NAME === 'CELIC' ? 'Liceos' : 'Escuela'}}*</label>
        <select name="" id="" class="register-form__input" formControlName="schoolId" (change)="onSelectSchool()">
          <option [value]="''" selected disabled>Seleccionar</option>
          <option [ngValue]="school.id" *ngFor="let school of schools">{{ school.NameTSchool }}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('courseInfo.schoolId')?.hasError('required') && registerForm.get('courseInfo.schoolId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>

      <div [ngClass]="courses.length === 0 ? 'disabled':''" formGroupName="courseInfo">
        <label for="">{{ SCHOOL_NAME === 'CELIC' ? 'Grado al que aspira' : 'Curso al que fue convocado' }}*</label>
        <select name="" id="" class="register-form__input" formControlName="courseOfferId">
          <option [value]="''" selected disabled>Seleccionar</option>
          <option [ngValue]="course.id" *ngFor="let course of courses">{{ course.NameCourseOfer }}</option>
        </select>

        <ul class="error-list">
          <li class="error-list__item" *ngIf="registerForm.get('courseInfo.courseOfferId')?.hasError('required') && registerForm.get('courseInfo.courseOfferId')?.touched">Este campo es obligatorio.</li>
        </ul>
      </div>
    </div>

    <div class="form-submit__container" [hidden]="pagination === 1">
      <div class="form-submit__terms">
        <input type="checkbox" formControlName="termsCond">
        <a [routerLink]="['/privacy-policy']" class="" target="_blank">Acepto los términos y condiciones</a>
      </div>

      <button class="button button--blue button--submit" [ngClass]="isLoading || !registerForm.valid ? 'button--disabled' : ''"
        (click)="registerChild()">
        REGISTRAR
      </button>

      <ul class="error-list">
        <li class="error-list__item error-list__item--center" *ngIf="serverErrors">{{serverErrors}}</li>
      </ul>
    </div>
  </form>

  <div class="register-form__progress">
    <div class="form-progress__page">
      <button class="button button--blue" [ngClass]="pagination <= 1 ? 'button--disabled' : ''" (click)="previousPage()">
        <em class="icon-i-back page-icon"></em>
      </button>
  
      <button class="button button--blue" [ngClass]="isBasicInfoValid && pagination === 1 ? '' : 'button--disabled' 
          " (click)="nextPage()">
        <em class="icon-i-next page-icon"></em>
      </button>
    </div>
  
    <div class="form-progress__bar" 
      [style]="{ '--progress-width': progressWidth }">
    </div>
  </div>
</div>

<div class="register-result" *ngIf="registeredChildren !== undefined">
  <h2 class="title">Registro de Estudiantes</h2>

  <div class="register-result__user">
    <div class="result-user__info">
      <h2 class="user-info__title">EL USUARIO ES</h2>
      <span class="user-info__response">{{ registeredChildren.email }}</span>
    </div>

    <div class="result-user__info">
      <h2 class="user-info__title">LA CONTRASEÑA ES</h2>
      <span class="user-info__response">{{ registeredChildren.cellphone }}</span>
    </div>
  </div>

  <h2 class="result-message">INGRESE CON ESTE USUARIO <br> PARA CONTINUAR SU PROCESO</h2>
  <button class="button button--blue button--children-list" (click)="onCloseForm.emit()">VER LISTA DE HIJOS</button>
</div>