import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { ParentI } from 'src/app/models/approvalModels/approvalModels';
import { ITypeUser } from 'src/app/models/users/type-user';

@Injectable({
  providedIn: 'root',
})
export class RegisterChildrenService {
  private _urlApiV1 = environment.backV1ApiV1;
  private _urlApiV2 = environment.backV1ApiV2;

  constructor(private _http: HttpClient, private _authService: AuthService) {
    //
  }

  private get _Headers(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._authService.getToken(),
    });
    return headers;
  }

  public getCurrentUser(userId: number): Observable<any> {
    const filter = JSON.stringify({
      where: { id: userId },
      include: ['roleMappings', 'UserDocuments'],
    });

    const url: string = `${this._urlApiV2}/Userapps?filter=${filter}`;
    return this._http.get(url, { headers: this._Headers });
  }

  public getChildFromResponsible(attendedById: number): Observable<any> {
    const filter = JSON.stringify({
      where: { attendedBy: attendedById },
      include: [
        { parentUser: 'UserDocuments' },
        { studentUser: 'UserDocuments' },
        { attendantUser: 'UserDocuments' },
        'typeRlelationshipAttended',
        'typeRlelationshipParent',
        { transferParentUser: 'UserDocuments' },
      ],
    });

    const url: string = `${this._urlApiV2}/ParentsToStudents?filter=${filter}`;
    return this._http.get(url, { headers: this._Headers });
  }

  public getChildren(parentId: number): Observable<any> {
    const filter = JSON.stringify({
      where: {
        or: [{ ParentUserId: parentId }, { TransferParentUserId: parentId }],
      },
      include: [
        'parentUser',
        { studentUser: 'UserDocuments' },
        { attendantUser: 'UserDocuments' },
        'typeRlelationshipAttended',
        'typeRlelationshipParent',
        { transferParentUser: 'UserDocuments' },
      ],
    });

    const url: string = `${this._urlApiV2}/ParentsToStudents?filter=${filter}`;
    return this._http.get(url, { headers: this._Headers });
  }

  public patchChild(data: any, parentsToStudentsId: number): Observable<any> {
    const url: string = `${this._urlApiV2}/ParentsToStudents/${parentsToStudentsId}`;
    return this._http.patch(url, data, { headers: this._Headers });
  }

  public getRelationships(): Observable<any> {
    return this._http.get(`${this._urlApiV2}/TypeRelationships`, {
      headers: this._Headers,
    });
  }


  public getCountries(): Observable<any>{
    return this._http.get(`${this._urlApiV2}/Countries`, {
      headers: this._Headers,
    });
  }

  public getDocumentTypes(): Observable<any> {
    return this._http.get(`${this._urlApiV2}/TypeDocuments`, {
      headers: this._Headers,
    });
  }

  public getSchools(): Observable<any> {
    const filter = JSON.stringify({
      order: 'NameTSchool ASC',
      where: {
        id: {
          neq: 25,
        },
      },
    });
    return this._http.get(`${this._urlApiV2}/Schools?filter=${filter}`, {
      headers: this._Headers,
    });
  }

  public getCourseOffers(schoolId: number): Observable<any> {
    const filter = JSON.stringify({
      where: { SchoolID: schoolId },
    });

    const url = `${this._urlApiV2}/CoursesOfers?filter=${filter}`;
    return this._http.get(url, { headers: this._Headers });
  }

  public getForces(): Observable<any> {
    return this._http.get(`${this._urlApiV2}/Forces`, {
      headers: this._Headers,
    });
  }
  public getBiologicalSex(): Observable<any> {
    return this._http.get(`${this._urlApiV2}/TypeBiologicalSexes`, {
      headers: this._Headers,
    });
  }
  public getBloodType(): Observable<any> {
    return this._http.get(`${this._urlApiV2}/TypeRHs`, {
      headers: this._Headers,
    });
  }

  public getUserTypes(): Observable<ITypeUser[]> {
    return this._http.get<ITypeUser[]>(`${this._urlApiV2}/TypeRHs`, {
      headers: this._Headers,
    });
  }

  public getRange(forceId: number): Observable<any> {
    const filter = JSON.stringify({
      where: { ForceID: forceId },
    });

    const url = `${this._urlApiV2}/Ranges?filter=${filter}`;
    return this._http.get(url, { headers: this._Headers });
  }

  public getUser(filter: string) {
    const filterData = filter ? `?filter=${filter}` : '';
    const users = this._http.get(
      `${this._urlApiV2}/ViewUserInfos${filterData}`,
      { headers: this._Headers }
    );
    return users;
  }

  public registerChild(data: any) {
    return this._http.post(environment.registerCloudFunction, data);
  }

  public finUserByDocument(document: string): Observable<ParentI[]> {
    const filter = JSON.stringify({
      where: { Document: { regexp: `/${document}/` } },
      limit: 3,
    });
    const url = `${this._urlApiV2}/ViewUserInfos?filter=${filter}`;
    return this._http.get<ParentI[]>(url);
  }

  postNotificationQueue(data): Observable<any> {
    let url = `${this._urlApiV2}/NotificationQueues`;
    return this._http.post(url, data, { headers: this._Headers });
  }

  public insertParentToStudent(data: any): Observable<any> {
    return this._http.post(`${this._urlApiV2}/ParentsToStudents`, data, {
      headers: this._Headers,
    });
  }

  public changePlatformUserToMyChild(data: any): Observable<any> {
    return this._http.post(
      `${this._urlApiV1}/Articles/changeViewStudent`,
      data,
      { headers: this._Headers }
    );
  }
}
