<div class="filters">
  <div class="filter-input">
    <label for="">Buscar por nombre de materia</label>
    <div class="search-filter">
      <i class="icon-i-search"></i>
      <input type="text" placeholder="Buscar..." (keyup)="filter($event)">
    </div>
  </div>

  <div class="filter-input">
    <label for="">Filtrar por área académica</label>
    <div class="select-filter">
      <select name="" id="" (change)="onSelectArea($event)">
        <option [value]="0">Todas</option>
        <option [value]="item.id" *ngFor="let item of areas">{{ item.NameArea }}</option>
      </select>
      <i class="icon-i-angle-right"></i>
    </div>
  </div>
</div>