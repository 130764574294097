import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSearchSelectComponent } from './custom-search-select.component';



@NgModule({
  declarations: [
    CustomSearchSelectComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[CustomSearchSelectComponent]
})
export class CustomSearchSelectModule { }
