<div class="search-dropdown__container">
  <div class="search-dropdown">
    <div class="search-dropdown__content">
      <input type="text" placeholder="Buscar por documento" class="search-dropdown__search-input" [placeholder]="placeholder" autocomplete="off" #searchInput>
      <div class="search-dropdown__list">
        <div class="search-dropdown__item" *ngFor="let item of userList" (click)="onItemClickEmit(item, $event)">
          <span class="dropdown-item__name">{{item.Name1 | titlecase}} {{item.LastName1 | titlecase}} ({{item.Document}})</span>
          <em class="icon-i-chulo icon" *ngIf="item.isSelected"></em>
        </div>
      </div>

      <div class="search-dropdown__empty" *ngIf="userList.length === 0">Sin resultados</div>

      <div class="select-container">
        <select name="" id="" class="select" (change)="selectedRelationship = $event">
          <option value="" selected disabled>Seleccionar parentesco</option>
          <option [value]="item.id" *ngFor="let item of relationships">{{ item.nameRelationship | titlecase}}</option>
        </select>
      </div>

      <div class="dropdown-actions">
        <button class="button button--blue" [ngClass]="!selectedItem || !selectedRelationship ? 'disabled' : ''" (click)="onConfirmClick()">Asignar</button>
      </div>
    </div>
  </div>
</div>