import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubjectGroupI, SubjectsI, UserSubjectI } from 'src/app/models/approvalModels/approvalModels';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'subject-certified',
  templateUrl: './subject-certified.component.html',
  styleUrls: [ './subject-certified.component.css' ]
} )
export class SubjectCertifiedComponent implements OnInit {

  //Sends a notification when delete button is clicked
  @Output( 'deleteAction' ) deleteAction: EventEmitter<any> = new EventEmitter();
  @Input( 'userSubject' ) userSubject: UserSubjectI = {} as UserSubjectI;
  @Input( 'showStatus' ) showStatus: boolean = false;


  //allows to display or hide the additional information of the subject
  public showAdditionalData: boolean = false;

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * Returns an object with styles for a CSS Grid layout.
   * @returns
   */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${ this.showStatus ? '4' : '3' }, 1fr)`,
      'align-items': 'center',
    }
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.backV1ApiV1}/${urlComplement}`;
    return url;
  }
}
