import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChildrenFormComponent } from './children-form/children-form.component';
import { FindUserComponent } from './find-user/find-user.component';

@NgModule({
  declarations: [
    ChildrenFormComponent,
    FindUserComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports:[
    ChildrenFormComponent,
    FindUserComponent
  ]
})
export class RegisterChildrenModule { }
