import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { map, Subscription, switchMap } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { CoursesOfersI, SchoolI, SubjectsI } from 'src/app/models/honourModels/honour';
import { HonourService } from 'src/app/services/honour/honour.service';
import { XlsxService } from 'src/app/services/xlsx.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component( {
  selector: 'request-honour-roll',
  templateUrl: './honour-roll.component.html',
  styleUrls: [ './honour-roll.component.css' ]
} )
export class HonourRollComponent implements OnInit {

  constructor ( private _honourService: HonourService,
    private _titleCase: TitleCasePipe,
    private _xlsxService: XlsxService,
    private _router: Router ) { }

  //stores the school info of the logged in user
  public selectedSchoolInfo: SchoolI = {} as SchoolI;
  //sores the subjects
  public subjects: SubjectsI[] = [];
  //stores the course offers
  public courseOffers: CoursesOfersI[] = [];

  //table config
  public gridOptions: GridOptions = {
    //columns
    columnDefs: [
      {
        headerName: 'Posición',
        field: 'position', rowDrag: true, width: 80, cellStyle: { 'text-align': 'left' }
      },
      {
        headerName: 'Nombres',
        field: 'name'
      },
      {
        headerName: 'Apellidos',
        field: 'lastName'
      },
      {
        headerName: 'Documento',
        field: 'document'
      },
      {
        headerName: 'NotaFinal',
        field: 'finalRecordObj',
        cellRenderer: params => {
          //When the cells are rendered it will recognize the row data, like name, document, etc   
          const { isHomolog, record } = params.data[ 'finalRecordObj' ] || {};
          let finalRecordText: any = '';

          if ( record ) {
            finalRecordText = isHomolog ? `Homologada: ${ record }` : record;

            if ( isHomolog ) {
              return `<div class="ag-grid__pill ag-grid__pill--green">${ finalRecordText }</div>`;
            } else if ( record >= 1 && record <= 2.5 ) {
              return `<div class="ag-grid__pill ag-grid__pill--red">${ finalRecordText }</div>`;
            } else if ( record >= 2.5 && record <= 3.5 ) {
              return `<div class="ag-grid__pill ag-grid__pill--orange">${ finalRecordText }</div>`;
            } else {
              return `<div class="">${ record }</div>`;
            }
          }

          return '';
        },
      }
    ],

    //default table config
    defaultColDef: {
      resizable: true,
      sortable: true,
      cellStyle: { 'text-align': 'center' }
    },

    rowClassRules: {
      'ag-grid__disable-row': (params) => {
        const { isWithdrawn } = params.data;
        return isWithdrawn;
      }
    }
  }

  //stores the table rows data
  public rowData: any[] = [];

  public DYNAMIC_SCHOOL_LOGO:any = (id:number) => environment.DYNAMIC_SCHOOL_LOGO(id);  

  private _subscription: Subscription = new Subscription();
  
  ngOnInit(): void {
    this.getCurrentUser();
  }

  /**
   * grid init function
   * @param params 
   */
  onGridReady( params ) {
    params.api.sizeColumnsToFit();
  }

  /**
   * loads the subjects depending on the course offer selected
   * @param event 
   */
  public loadSubjects( event: any ) {
    const courseOfferId: number = event.target.value;
    const courseId: any = this.courseOffers.find( e => e.id == courseOfferId )?.CourseID;

    this.subjects = [];
    this.rowData = [];

    this._subscription.unsubscribe();
    this._subscription = this.getSubjects( courseId ).subscribe( {
      next: ( resp: SubjectsI[] ) => {
        this.subjects = resp;
      }, error: ( err ) => console.log( err )
    } )
  }

  /**
   * loads the course offers depending on the type of course
   * @param event 
   */
  public loadCourseOffers( event: any ) {
    const type: boolean = event.target.value;
    this.courseOffers = [];
    this.subjects = [];
    this.rowData = [];

    this._subscription.unsubscribe();
    this._subscription = this.getCourseOffers( type ).subscribe( {
      next: ( resp: CoursesOfersI[] ) => {
        this.courseOffers = resp;
      }, error: ( err ) => console.log( err )

    } );
  }

  /**
   * it will load the table with the HonourRolls information corresponding to the selected subject.
   * @param event 
   * @param courseOfferId 
   */
  public showTable( event: any, courseOfferId: string ) {
    const subjectId: number = event.target.value;
    Swal.fire({ 
      title:"Cargando tabla",
      text: "Por favor espere...",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();


    this.rowData = [];
    this.getHonorRolls( Number( courseOfferId ), subjectId ).subscribe( {
      next: ( resp ) => {
        if ( resp.length === 0 ) {
          Swal.fire({
            icon:'warning',
            title:"Sin datos",
            text: "No se encontró ningún resultado."
          });
          return;
        }

        //assign the rows data
        resp.forEach( ( e, index ) => {
          this.rowData.push( {
            position: index + 1,
            lastName: this._titleCase.transform( e.LastNames ),
            name: this._titleCase.transform( e.Names ),
            document: this._titleCase.transform( e.Document ),
            finalRecordObj: {
              record: e.FinalRecord ?? '-',
              isHomolog: e.IsHomolog
            },
          } )
        } );

        Swal.close();
      },
      error: ( err ) => console.log( err )
    } )
  }

  /**
   * gets logged-in user information
   */
  private getCurrentUser() {
    Swal.fire({ 
      title:"Cargando",
      text: "Por favor espere...",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();
 
    const filter: string = JSON.stringify( {
      include: [ 'roles', 'roleMappings' ]
    } )

    this._honourService.getUserInfo( `/me?filter=${ filter }` )
      .pipe( switchMap( ( userInfo: any ) => this._honourService.getAllSchools().pipe(
        map( ( schools: SchoolI[] ) => {

          //checks if current user has the following roles and gets its id
          const allowedRoles: string[] = [ 'Registro y Control', 'General', 'Inspector Escuela' ];
          const role = userInfo.roles.find( role => allowedRoles.includes( role.name ) );

          if ( !role )
            return this.sendErrorMessage( 404, 'Lo sentimos, no tienes acceso a esta función.' );

          const roleId = role.id;

          //search roleMappings for the id of the role to get the school and the schools that depend on it
          const roleMapping = userInfo.roleMappings.find( role => role.roleId === roleId );
          const currentSchool = schools.find( school => school.id === roleMapping.SchoolID );

          return currentSchool;
        }
        )
      ) ) )
      .subscribe( {
        next: ( data: any ) => {
          if ( data.error ) {
            Swal.fire({
              icon: 'error',
              title: data.error.message
            });
            return;
          }

          this.selectedSchoolInfo = {
            id: data.id,
            NameTSchool: data.NameTSchool,
            Color: data.Color,
            DepenSchoolID: data.DepenSchoolID
          }
          Swal.close();
        }, error: ( err ) => {
          Swal.fire({
            icon:'error',
            text: "Ha ocurrido un error",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if(result.isConfirmed)
              this._router.navigate( [ '/landing' ])
          })
          console.log( err );
        }
      } );
  }

  /**
   * gets the information to be displayed in the table
   * @param courseOfferId 
   * @param subjectId 
   * @returns 
   */
  private getHonorRolls( courseOfferId: number, subjectId: number ) {
    const filter = JSON.stringify( {
      where: {
        and: [ { CourseOferID: courseOfferId }, { SubjectID: subjectId } ]
      }
    } )
    return this._honourService.getHonorRolls( filter );
  }

  /**
   * gets the course offers
   * @param type 
   * @returns 
   */
  private getCourseOffers( type: boolean ) {
    const filter: any = {
      where: { and: [ { IsActive: type } ] },
      include: [ 'course' ]
    }

    if ( this.selectedSchoolInfo.id !== 0 )
      filter.where.and.push( { SchoolID: this.selectedSchoolInfo.id } )

    const filterString = JSON.stringify( filter )
    return this._honourService.getCourseOffers( filterString );
  }

  /**
   * gets the subjects
   * @param courseId 
   * @returns 
   */
  private getSubjects( courseId: number ) {
    const filter: any = JSON.stringify( {
      where: { CourseID: courseId },
      include: [ 'AcademicaArea' ]
    } )
    return this._honourService.getSubjects( filter );
  }

  /**
  * download an excel file with the data shown in the table.
  */
  public exportExcel() {
    const rows = this.rowData.map( row => {
      const item = {};

      //Iterates through each key-value pair of the row, finds the column definition for that key, and assigns a headerName to it and the row value.
      Object.entries( row ).forEach( ( [ key, value ]: any, index ) => {
        const columnDef = this.gridOptions.columnDefs?.find( def => def[ 'field' ] === key.split( ' ' ).join( '' ) );
        const headerName = columnDef?.headerName || '';

        let cellValue: string = '';
        if ( index === 4 )
          cellValue = value.isHomolog ? `Homologada: ${ value.record }` : value.record;
        else
          cellValue = value;

        item[ headerName ] = cellValue;
      } );
      return item;
    } );

    this._xlsxService.exportData( rows, 'Histórico_de_notas' );
  }

  /**
  * exports a PDF file
  */
  public exportPdf() {
    const doc = new jsPDF();

    // get the header names for the grid from the column definitions
    const header: any[] = Object.keys( this.rowData[ 0 ] )
      .map( key => this.gridOptions.columnDefs?.find( def => def[ 'field' ] === key.split( ' ' ).join( '' ) )?.headerName );

    // create an array of arrays containing the row data for the PDF
    const rows: any[] = this.rowData.map( row => {
      const item: any[] = Object.values( row ).map((e:any,index) => {
        if(index === 4)
          return e.isHomolog ? `Homologada: ${ e.record }` : e.record; 
        
        return e;
      });

      return item;
    } );

    autoTable( doc, {
      styles: { fontSize: 10 },
      head: [ header ],
      body: rows,
    } );

    doc.save( 'Sábana_de_nota.pdf' );
  }

  /**
   * Returns an object containing the error code and message.
   * @param errorCode 
   * @param errorMessage 
   * @returns 
   */
  private sendErrorMessage( errorCode: number, errorMessage: string ): object {
    return {
      error: {
        code: errorCode,
        message: errorMessage,
      }
    };
  }
}
