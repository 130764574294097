import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'approval-filter',
  templateUrl: './approval-filter.component.html',
  styleUrls: ['./approval-filter.component.css']
})
export class ApprovalFilterComponent implements OnInit {

  constructor() { }

  //returns the filter input value
  @Output('inputValue') inputValue: EventEmitter<any> = new EventEmitter();

  @Input('areas') areas: any[] = [];

  private _selectedArea: number = 0;
  private _subjectName: string = '';

  ngOnInit(): void {
    //
  }

  filter(event: any) {
    const value = event.target.value;
    this._subjectName = value;
    this.inputValue.emit({ subjectName: value, areaId: Number(this._selectedArea) });
  }

  onSelectArea(event: any) {
    const value = event.target.value;
    this._selectedArea = value;
    this.inputValue.emit({ subjectName: this._subjectName, areaId: Number(value) });
  }
}
