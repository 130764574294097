import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpService } from '../http.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RequestResumeService {
  constructor(private _httpService: HttpService, private _http: HttpClient) {}

  getParentToStudents(filter?: string): Observable<any> {
    let filters = '';
    if (filter) filters = `?filter=${filter}`;
    return this._httpService.httpGet(`/ParentsToStudents/${filters}`);
  }

  getUserInfo(filter?: string, isMe: boolean = false): Observable<any> {
    let filters = '';
    if (filter) filters = `?filter=${filter}`;

    return this._httpService.httpGet(`/Userapps/${isMe ? 'me' : ''}${filters}`);
  }

  getBasicData(path: string, filters?:string) {
    if (filters)
      filters = `?filter=${filters}`
    
    return this._httpService.httpGet(`/${path}${filters || ''}`);
  }

  addInfo(data: any, path: string, userId: number): Observable<any> {
    return this._httpService.httpPost(`/Userapps/${userId}/${path}`, data).pipe(
      catchError(err => {
        console.error(`Error al agregar ${path}:`, err);
        return of(null);
      })
    );
  }

  updateInfo(data: any, path: string): Observable<any> {
    return this._httpService.httpPatch(`/${path}`, data).pipe(
      catchError(err => {
        console.error(`Error al actualizar ${path}:`, err);
        return of(null);
      })
    )
  }

  postMilitarInfo(data: any, userId: number) {
    return this._httpService.httpPost(`/Userapps/${userId}/MilitarInfo`,data)
    .pipe( catchError(err => {
        console.error('Error al crear el información militar:', err);
        return of(null);
      })
    );
  }
  deleteInfo(data: any, path: string, userId: number): Observable<any> {
    return this._httpService.httpDelete(
      `/Userapps/${userId}/${path}/${data.id}`
    );
  }

  //==

  patchUserDocumentByID(
    documentId: number,
    userId: number,
    documentData: any
  ): Observable<any> {
    return this._httpService.httpPutBackV1(
      `/Userapps/${userId}/UserDocuments/${documentId}`,
      documentData
    );
  }

  patchPassword(userId: number, data: any): Observable<any> {
    return this._httpService.httpPost(
      `/Userapps/${userId}/updatePassword`,
      data
    ).pipe(
      catchError(err => {
        console.error('Error al acatualizar la contraseña:', err);
        return of(null);
      })
    )
  }

  passwordMatch(userId: number, data: any) {
    return this._httpService.httpPost(
      `/Userapps/${userId}/passwordMatch`,
      data
    ).pipe(
      catchError(err => {
        console.error('Error al verificar la contraseña:', err);
        return of(null);
      })
    );
  }

  updatePersonalInfo(userId: number, data: any){
    return this._httpService.httpPatch(`/Userapps/${userId}`, data).pipe(
      catchError(err => {
        console.error('Error al actualizar el email:', err);
        return of(null);
      })
    );
  }
}