import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpService } from '../http.service';
import {
  SchoolI,
  UserDataI,
} from 'src/app/models/observationModels/observation';

@Injectable({
  providedIn: 'root',
})
export class ObservationsExplorerService {
  constructor(private _httpService: HttpService) {}

  getSchools(filter?: string): Observable<any[]> {
    let filters = '';
    if (filter) filters = `?filter=${filter}`;
    return this._httpService.httpGet(`/Schools/${filters}`);
  }

  getUserInfo(filter?: string): Observable<any> {
    let filters = '';
    if (filter) filters = `?filter=${filter}`;

    return this._httpService.httpGet(`/Userapps/${filters}`);
  }

  getViewUserInfo(qryFilter?: string): Observable<any> {
    let filters = '';
    if (qryFilter) filters = `?filter=${qryFilter}`;

    return this._httpService.httpGet(`/ViewUserInfos/${filters}`).pipe(
      switchMap((users: any[]) => {
        const userIds = users.map((e) => e.id);
        const usersFilter = JSON.stringify({
          where: { id: { inq: userIds } },
          include: [{ roleMappings: ['role', 'school'] }, { UserCourses: 'coursesOfer' }, 'UserDocuments' ],
        });

        return this.getUserInfo(usersFilter);
      })
    );
  }
}
