import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'custom-search-select',
  templateUrl: './custom-search-select.component.html',
  styleUrls: ['./custom-search-select.component.css'],
})
export class CustomSearchSelectComponent implements OnInit {
  constructor() {
    //
  }

  //search for input
  @ViewChild('filterInput') filterInput: ElementRef;

  //receives the maximum number of elements that can be added
  @Input('maxItems') maxItems: number = 8;
  private _maxItemsCounter: number = 0;

  //receives an extra conditional for the input
  @Input('extraValidation') extraValidation: boolean = false;

  //receive the list of members
  private _itemList: any[] = [];
  @Input('itemList') set itemList(value: any[]) {
    this.itemsToAdd = [];
    this._itemList = value;
    this.filteredItems = value;
  }

  //label title
  @Input('title') title: string = '';

  //input id
  @Input('inputId') inputId: string = '';

  //disables input
  @Input('disabled') disabled: boolean = false;

  //emits the members to be added
  @Output('addedItems') addedItems: EventEmitter<any[]> = new EventEmitter();

  //hides the input or not
  public hideInput: boolean = false;

  //stores the members to be added
  public itemsToAdd: any[] = [];

  //allows to show or not to show the search dialog box
  public showDialog: boolean = false;

  //stores the list of filtered members
  public filteredItems: any[] = [];

  ngOnInit(): void {
    //
  }

  /**
   * items filter
   * @param event
   */
  filterItems(event) {
    let value = event.target.value.toLowerCase();

    if (this.disabled) return;

    let filteredItems = [...this._itemList].filter((m) =>
      m.value.toLowerCase().includes(value)
    );

    this.itemsToAdd.forEach((e) => {
      filteredItems.forEach((f, index) => {
        if (f.id === e.id) filteredItems.splice(index, 1);
      });
    });

    this.filteredItems = filteredItems;
  }

  /**
   * when clicking on the input
   * @returns
   */
  onClick() {
    if (this.disabled) return;

    this.showDialog = this._itemList.length > 0;
  }

  /**
   * temporarily stores possible group members
   * @param data
   */
  addItem(data: any) {
    if (this.maxItems === 0) return;

    if (this._maxItemsCounter < this.maxItems) {
      this._maxItemsCounter += 1;
      this.setItems(data);
    }

    if (this._maxItemsCounter >= this.maxItems) this.hideInput = true;
  }

  /**
   * adds an item to the list of possible members to be added
   * @param data
   */
  setItems(data: any) {
    let indexToRemove = this.filteredItems.findIndex(
      (item) => item.id === data.id
    );
    if (indexToRemove !== -1) this.filteredItems.splice(indexToRemove, 1);

    this.itemsToAdd.push(data);
    this.addedItems.emit(this.itemsToAdd);
  }

  /**
   * deletes possible items
   * @param member
   */
  deleteItem(itemToRemove: any) {
    this.hideInput = false;
    if (this.maxItems > 0) this._maxItemsCounter -= 1;

    this.itemsToAdd.forEach((addedUser, index) => {
      if (addedUser.id === itemToRemove.id) this.itemsToAdd.splice(index, 1);
    });

    this.filteredItems.unshift(itemToRemove);
    this.addedItems.emit(this.itemsToAdd);
  }

  //receives the id of the item to add and adds it without the need to type the value and click on add
  setItemAutomatically(itemId) {
    let item: any[] = this._itemList.filter((m: any) => m.id === itemId);
    this.addItem(item[0]);
  }

  /**
   * clears the selector data
   */
  clearData() {
    this.filterInput.nativeElement.value = '';
    this.filteredItems = [];
    this.itemsToAdd = [];
    this._maxItemsCounter = 0;
    this.hideInput = false;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(e) {
    this.showDialog =
      e.target.closest('.search-select__input') ||
      e.target.closest('.search-select__dialog') ||
      e.target.closest('.search-select__dialog-item');
  }
}
