<div class="search-select" [ngStyle]="{'opacity': disabled ? '.5' : '', 'pointer-events': disabled ? 'none' : ''}">
  <label for="">{{ title }}</label>
  <div class="search-select__input-container" [hidden]="hideInput">
    <!-- #region SEARCH INPUT -->
    <input type="text" class="search-select__input" autocomplete="off" id="{{ inputId }}"
      placeholder="Buscar..." tabindex="-1"
      #filterInput (keyup)="filterItems($event)">

    <em class="icon-i-search"></em>
    <!-- #endregion -->

    <!-- #region DROPDOWN -->
    <div class="search-select__dialog" *ngIf="showDialog && filteredItems.length > 0">

      <div class="search-select__dialog-item" *ngFor="let item of filteredItems" (click)="addItem(item)">
        <div class="search-select__dialog-info">
          <p class="search-select__text">
            {{item.value}}
          </p>
        </div>

        <em class="icon-i-plus-circle search-select__add-item"></em>
      </div>
    </div>
    <!-- #endregion-->
  </div>

  <!-- SHOW ERRORS -->
  <ng-container *ngIf="extraValidation">
    <small class="text-invalid">Este campo es obligatorio</small>
  </ng-container>
</div>

<div class="search__pills" *ngIf="itemsToAdd.length > 0" [ngStyle]="{'margin-top': hideInput ? '0px' : ''}">
  <ng-container *ngFor="let item of itemsToAdd">
    <div class="item-pill">
      <img class="pill-icon" [src]="item.image" alt="pill icon" *ngIf="item.image">
      <p class="pill-text">{{item.value}}</p>
      <button class="button" type="button" (click)="deleteItem(item)">
        <em class="icon-i-close"></em>
      </button>
    </div>
  </ng-container>
</div>