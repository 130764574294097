import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import {
  UserI,
  UserSubjectI,
} from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'subject-studying',
  templateUrl: './subject-studying.component.html',
  styleUrls: ['./subject-studying.component.css'],
})
export class SubjectStudyingComponent implements OnInit {
  //Sends a notification when delete button is clicked
  @Output('deleteAction') deleteAction: EventEmitter<any> = new EventEmitter();
  @Input('showStatus') showStatus: boolean = false;
  @Input('isWithinDateRange') isWithinDateRange: boolean = false;

  //obtains the userSubject data
  private _userSubject: UserSubjectI = {} as UserSubjectI;
  @Input('userSubject') set userSubject(value: UserSubjectI) {
    this._userSubject = value;
    if (value === undefined) return;
  }

  get userSubject() {
    return this._userSubject;
  }

  @Input('userInfo') userInfo: UserI | undefined = undefined;

  public showAdditionalData: boolean = false;
  public allowEdit: boolean = true;

  constructor(private _approvalService: ApprovalService) {
    //
  }

  ngOnInit(): void {
    //
  }

  public setAccording(state: boolean, term: any) {
    if (
      !['estudiante', 'aspirante'].some((e) =>
        this._approvalService.roles.includes(e)
      ) ||
      !this.isWithinDateRange
    )
      return;

    Swal.fire({
      icon: 'warning',
      title: `${state ? 'Esta' : 'No esta'} de acuerdo con la nota?`,
      allowEscapeKey:false,
      allowOutsideClick:false
    }).then(result => {
      if(result.isConfirmed){
        Swal.fire({ 
          title: 'Cargando...', 
          text: "Por favor, espera un momento",
          allowEscapeKey:false,
          allowOutsideClick:false
        });

        this.allowEdit = false;

        this._approvalService
          .updateUserRecord({ IsAccording: state }, term.id)
          .pipe(
            switchMap((userRecord: any) =>
              this.notification(state, term).pipe(
                map((notification) => ({ userRecord, notification }))
              )
            )
          )
          .subscribe({
            next: ({ userRecord, notification }) => {
              console.log(notification);
              const updatedUserRecord = this._userSubject.userRecord?.map(
                (e) => {
                  if (e.id === userRecord.id) return { ...e, ...userRecord };

                  return e;
                }
              );

              this.userSubject = {
                ...this._userSubject,
                userRecord: updatedUserRecord,
              };

              this.allowEdit = false;
              Swal.fire({
                icon: 'success',
                title: 'Hecho',
                text: 'Se actualizó el estado de la nota.',
              });
            },
            error: (err) => {
              this.allowEdit = true;
              Swal.close();
              console.log(err);
            },
          });
      }
    })
  }

  private notification(IsAccording: boolean, term: any): Observable<any> {
    const professorEmail =
      this._userSubject.subjectGroup.userapp?.CedocEmail ||
      this._userSubject.subjectGroup.userapp?.email;
    const userName = `${this.userInfo?.Name1} ${this.userInfo?.LastName1}`;
    const subjectName = term.midTerm.NameMid;

    const senderName = `${this.userInfo?.Name1} ${this.userInfo?.LastName1}`;
    const senderEmail =
      this._userSubject.subjectGroup.userapp?.CedocEmail ||
      this._userSubject.subjectGroup.userapp?.email;

    const data = {
      namesTO: [`Tienes un novedad de ${userName}`],
      emailsTo: [professorEmail],
      msg: `El estudiante ${userName} ${
        IsAccording ? 'está' : 'no está'
      } de acuerdo con la nota de la materia ${subjectName}`,
      timeToSend: new Date().toISOString(),
      templateFile: 'template.html',
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email',
      senderName,
      senderEmail,
    };

    return this._approvalService.sendNotification(data);
  }

  /**
   * Returns an object with styles for a CSS Grid layout.
   * @returns
   */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${
        this.showStatus ? '4' : '3'
      }, 1fr)`,
      'align-items': 'center',
    };
  }
}
