import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { map, Subscription, switchMap } from 'rxjs';
import { CoursesOfersI, SchoolI } from 'src/app/models/honourModels/honour';
import { HonourService } from 'src/app/services/honour/honour.service';
import { XlsxService } from 'src/app/services/xlsx.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component( {
  selector: 'request-honour-graduate',
  templateUrl: './honour-graduate.component.html',
  styleUrls: [ './honour-graduate.component.css' ],
} )
export class HonourGraduateComponent implements OnInit {

  constructor ( private _honourService: HonourService,
    private _titleCase: TitleCasePipe,
    private _xlsxService: XlsxService,
    private _router: Router 
  ) { }

  //stores years elapsed from 2017 to today
  public yearList: number[] = [ 2017 ];

  //stores the list of courseOffers
  public courseOffers: CoursesOfersI[] = [];

  //stores the school info of the logged in user
  public selectedSchool: SchoolI = {} as SchoolI;

  //stores the year
  public selectedYearId: number = 0;

  //stores the list of schools that depend on the logged-in user's main school
  public dependSchools: SchoolI[] = [];

  private _subscription: Subscription = new Subscription();

  //table config
  public gridOptions: GridOptions = {
    //default table config
    defaultColDef: {
      resizable: true,
      sortable: true,
      cellStyle:{'text-align':'center'}
    },

    //columns
    columnDefs: [
      {
        headerName: 'Posición',
        field: 'position',
        cellStyle:{'text-align':'left'},
        headerClass:'ag-header__left-text'
      },
      {
        headerName: 'Documento',
        field: 'document',
      },
      {
        headerName: 'CédulaMil',
        field: 'militaryDocument',
      },
      {
        headerName: 'Rango',
        field: 'range',
      },
      {
        headerName: 'Arma',
        field: 'gun',
      },
      {
        headerName: 'Nombres',
        field: 'name',
      },
      {
        headerName: 'Apellidos',
        field: 'lastName',
      },
      {
        headerName: 'GPA',
        field: 'gpa',
      },
      {
        headerName: 'Promedio',
        field: 'average',
      }
    ],

    rowClassRules: {
      'ag-grid__disable-row': (params) => {
        const { isWithdrawn } = params.data;
        return isWithdrawn;
      }
    }
  }

  private _gridOptions:GridOptions = {...this.gridOptions};

  //stores the table rows data
  public rowData: any[] = [];
  public initTotalColumns:any = this.gridOptions.columnDefs?.length;

  public DYNAMIC_SCHOOL_LOGO:any = (id:number) => environment.DYNAMIC_SCHOOL_LOGO(id);

  ngOnInit(): void {
    this.setYearList();
  }

  /**
  * gets the years from 2017 to the current date
  */
  private setYearList() {
    Swal.fire({ 
      title:'Cargando...', 
      text: "Por favor espere",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();
  
    const currentYear: number = new Date().getFullYear();

    //-------------------------2017
    for ( let i = this.yearList[ 0 ] + 1; i <= currentYear; i++ ) {
      this.yearList.push( i );
    }

    this.getCurrentUser();
  }

  /**
   * allows to change schools
   * @param school 
   */
  public setSchool( school: any, userCourse: any ) {
    if ( this.selectedSchool.id === school.id ) return;

    userCourse.value = 0;
    this.setSelectedSchoolData( school );
    this.loadCourseOffers();
  }

  /**
   * gets the course offers
   * @param event 
   */
  public loadCourseOffers() {
    this.courseOffers = [];
    this.rowData = [];

    this._subscription.unsubscribe();
    this._subscription = this.getCourseOffers().subscribe( {
      next: ( resp: any[] ) => {
        this.courseOffers = resp;
      }, error: ( err ) => console.log( err )
    } );
  }

  /**
   * gets the subjects of each user and their subjects
   * @param event 
   */
  public getCourseInfo(event: any) {
    const courseOfferId = Number(event.target.value);

    Swal.fire({ 
      title:'Cargando...', 
      text: "Por favor espere",
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();
  
    this.rowData = [];

    this.getHonourRoll(courseOfferId).pipe(
      map((offers: any[]) => {
        const userIdsSet = new Set(offers.map(offer => offer.UserID));
        const userIds = [...userIdsSet];

        let groupedOffers = userIds.reduce((grouped, userId) => {
          if (userId !== null) {
            grouped[userId] = offers.filter(offer => offer.UserID === userId);
          }
          return grouped;
        }, {});

        const groupedOffersArray = Object.values(groupedOffers).map((offersForUser: any) => {
          const tmpSubjects = offersForUser.map(offer => {
            return {
              name: this._titleCase.transform(offer.subject?.NameSubject),
              finalRecord: offer.FinalRecord,
              isHomolog: offer.IsHomolog
            };
          });

          return {
            isWithdrawn: offersForUser[0].isWithdrawn,
            document: offersForUser[0].Document,
            militaryDocument: offersForUser[0]?.CedulaMil ?? '-',
            range: offersForUser[0]?.Range ?? '-',
            gun: offersForUser[0]?.Unit ?? '-',
            lastName: offersForUser[0]?.LastNames,
            firstName: offersForUser[0]?.Names,
            gpa: this.calculateGPA(offersForUser),
            average: this.calculateAverage(offersForUser),
            subjects: tmpSubjects
          };
        });

        return groupedOffersArray;
      })
    ).subscribe({
      next: (rowInfo: any) => {
        if (rowInfo.length === 0) {
          Swal.fire({
            icon:"warning",
            title:"Sin datos",
            text:"No se encontró ningún resultado."
          });
          return;
        }

        this.assignSubjectColumn(rowInfo);
        this.assignRowsData(rowInfo);
        Swal.close();
      },
      error: (err) => console.log(err)
    })
  }

 
  /**
   * Assigns subject columns to the grid based on the provided row information.
   * 
   * @param rowInfo 
   */
  private assignSubjectColumn( rowInfo: any[] ) {
    const subjectNames = rowInfo[ 0 ].subjects.map( subject => subject.name );
    let columnDefs: any[] = [];

    for ( const name of subjectNames ) {
      if ( typeof name !== 'string' || name.trim() === '' ) {
        continue;
      }

      const fieldName = name.split( ' ' ).join( '' );
      const cellRenderer = ( params: any ) => {
        const { isHomolog, finalRecord } = params.data[ name ] || {};
        let finalRecordText: any = '';

        if ( finalRecord !== undefined ) {
          finalRecordText = isHomolog ? `Homologada: ${ finalRecord }` : finalRecord;
          return this.generateCellRenderer( finalRecordText, isHomolog );
        }

        return '';
      };
      const valueGetter = this.generateValueGetter( name );

      columnDefs.push( {
        headerName: name,
        field: fieldName,
        cellRenderer,
        valueGetter,
      } );
    }

    this.gridOptions.columnDefs = [
      ...(this._gridOptions.columnDefs || []),
      ...columnDefs,
    ];
  }

  /**
   * Generates the HTML cell renderer based on the final record text and homolog status.
   * 
   * @param finalRecordText 
   * @param isHomolog 
   * @returns 
   */
  private generateCellRenderer( finalRecordText: string, isHomolog: boolean ): string {
    if ( isHomolog ) {
      return `<div class="ag-grid__pill ag-grid__pill--green">${ finalRecordText }</div>`;
    } else if ( Number( finalRecordText ) >= 1 && Number( finalRecordText ) <= 2.5 ) {
      return `<div class="ag-grid__pill ag-grid__pill--red">${ finalRecordText }</div>`;
    } else if ( Number( finalRecordText ) > 2.5 && Number( finalRecordText ) <= 3.5 ) {
      return `<div class="ag-grid__pill ag-grid__pill--orange">${ finalRecordText }</div>`;
    } else {
      return `<div class="">${ finalRecordText }</div>`;
    }
  }

  /**
   * Generates a value getter function for the specified subject name.
   * 
   * @param name 
   * @returns 
   */
  private generateValueGetter( name: string ): ( params: any ) => number {
    return ( params ) => params.data[ name ]?.finalRecord ?? 0;
  }
  

  /**
   * This function takes in an array of rowInfo objects and assigns data to each row.
   * @param rowInfo 
   */
  private assignRowsData( rowInfo: any ) {
    this.rowData = rowInfo.map( ( info, index ) => {
      const newRowData: any = {
        position: index + 1,
        gun: this._titleCase.transform( info.gun ),
        document: info.document,
        range: info.range,
        militaryDocument: info.militaryDocument,
        name: this._titleCase.transform( info.firstName ),
        lastName: this._titleCase.transform( info.lastName ),
        gpa: info.gpa.toFixed( 2 ),
        average: info.average.toFixed( 2 ),
      };

      // Assign subjects data
      const subjects = info.subjects.filter( subject => subject.name !== undefined );
      for ( const subject of subjects ) {
        newRowData[ subject.name ] = { isHomolog: subject.isHomolog, finalRecord: `${ subject.finalRecord }` };
      }

      return newRowData;
    } );
  }

  /**
  * gets logged-in user information
  */
  private getCurrentUser() {
    const filter: string = JSON.stringify( {
      include: [ 'roles', 'roleMappings' ]
    } )

    this._honourService.getUserInfo( `/me?filter=${ filter }` )
      .pipe( switchMap( ( userInfo: any ) => this._honourService.getAllSchools().pipe(
        map( ( schools: SchoolI[] ) => {
          //checks if current user has the following roles and gets its id
          const allowedRoles: string[] = [ 'General', 'Registro y Control', 'Jefe', 'Inspector Escuela' ];

          const role = userInfo.roles.find( role => allowedRoles.includes( role.name ) );
          if ( !role ) return this.sendErrorMessage( 404, 'Lo sentimos, no tienes acceso a esta función.' );

          //search roleMappings for the id of the role to get the school and the schools that depend on it
          const currentSchool = schools.find( school => school.id === userInfo.roleMappings.find( e => e.roleId === role.id ).SchoolID );
          const dependSchools = schools.filter( school => school.DepenSchoolID === currentSchool?.id );

          return [ currentSchool, ...dependSchools ];
        } )
      ) ) )
      .subscribe( {
        next: ( data: any ) => {
          if ( data.error ) {
            Swal.fire({
              icon: 'error',
              text:data.error.message
            });
            return;
          }

          const [ selectedSchoolData ] = data;
          this.setSelectedSchoolData( selectedSchoolData );
          this.dependSchools = data;
          Swal.close();
        }, error: ( err ) => {
          console.log( err );
          Swal.fire({
            icon: 'error',
            text: "Ha ocurrido un error",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if(result.isConfirmed)
              this._router.navigate( [ '/landing' ] );
          })
        }
      } )
  }

  /**
   * gets the necessary data from the selected school 
   * @param school 
   */
  private setSelectedSchoolData( school: SchoolI ) {
    this.selectedSchool.id = school.id;
    this.selectedSchool.NameTSchool = school.NameTSchool;
    this.selectedSchool.Color = school.Color;
  }

  /**
   * gets the course offers
   * @returns 
   */
  private getCourseOffers() {
    const filter = JSON.stringify( {
      where: {
        and: [ { SchoolID: this.selectedSchool.id },
        { Since: { between: [ `${ this.selectedYearId }-01-01`, `${ this.selectedYearId }-12-31` ] } } ]
      }
    } )

    return this._honourService.getCourseOffers( filter );
  }

  private getHonourRoll( courseOfferId: number ) {
    const filter = JSON.stringify( {
      where:{ and:[{CourseOferID: courseOfferId},{SchoolID:this.selectedSchool.id}] }, include: [ 'subject' ]
    } );

    return this._honourService.getHonourGraduates( filter );
  }

  /**
   * download an excel file with the data shown in the table.
   */
  public exportExcel() {
    const rows = this.rowData.map( row => {
      const item = {};

      //Iterates through each key-value pair of the row, finds the column definition for that key, and assigns a headerName to it and the row value.
      Object.entries( row ).forEach( ( [ key, value ]: any, index ) => {
        const columnDef = this.gridOptions.columnDefs?.find( def => def[ 'field' ] === key.split( ' ' ).join( '' ) );
        
        const headerName = columnDef?.headerName || '';

        let cellValue: string = '';
        if ( index <  this.initTotalColumns) {
          cellValue = value;
        } else {
          cellValue = value.isHomolog ? `Homologada: ${ value.finalRecord }` : value.finalRecord;
        }

        item[ headerName ] = cellValue;
      } );
      return item;
    } );

    this._xlsxService.exportData( rows, 'Histórico_de_notas' );
  }

  /**
   * Returns an object containing the error code and message.
   * @param errorCode 
   * @param errorMessage 
   * @returns 
   */
  private sendErrorMessage( errorCode: number, errorMessage: string ): object {
    return {
      error: {
        code: errorCode,
        message: errorMessage,
      }
    };
  }

  /**
   * Calculate GPA based on subject offerings provided
   * @returns 
   */
  private calculateGPA(offersForUser:any[]) {
    if (offersForUser.length === 0)
      return 0;
  
    const totalCredits = offersForUser.reduce((sum, offer) => {
      const credits = offer.subject?.Creditos || 0;
      return sum + credits;
    }, 0);
  
    const weightedGradeSum = offersForUser.reduce((sum, offer) => {
      const credits = offer.subject?.Creditos || 0;
      const finalRecord = offer.FinalRecord || 0;
      return sum + (finalRecord * credits);
    }, 0);
  
    if (totalCredits === 0)
      return 0;
  
    return weightedGradeSum / totalCredits;
  }

  /**
   * Calculate notes Average based on subject offerings provided
   * @returns
   */
  private calculateAverage(offersForUser:any[]){
    if (offersForUser.length === 0)
      return 0;
    
    const average = offersForUser.reduce((sum, offer) => {
      const finalRecord = offer.FinalRecord || 0;
      return sum + finalRecord;
    }, 0);

    return average / offersForUser.length;
  }
}