import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApprovalRolesDirective } from 'src/app/directives/approval-roles.directive';

@NgModule( {
  declarations: [
    ApprovalRolesDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ApprovalRolesDirective
  ]
} )
export class SharedApprovalModule { }
