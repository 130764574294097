import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import { UserI } from 'src/app/models/approvalModels/approvalModels';

@Injectable( {
  providedIn: 'root'
} )
export class HonourService {

  constructor ( private _httpService: HttpService ) { }

  getUserInfo( filter?: string ): Observable<UserI> {
    return this._httpService.httpGet( `/Userapps${ filter }` );
  }

  getAllSchools():Observable<any[]>{
    return this._httpService.httpGet( `/Schools` );
  }

  getSchools(): Observable<any[]> {
    const filter = JSON.stringify({
      order: 'NameTSchool ASC',
      where: {
        id: {
          neq: 25
        }
      }
    });
    return this._httpService.httpGet( `/Schools?filter=${filter}` );
  }

  getCourseOffers( filter?: string ): Observable<any[]> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/CoursesOfers${ newFilter }` );
  }

  getHonourGraduates( filter?: string ): Observable<any[]> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/ViewHonourGraduates${ newFilter }` );
  }

  getHonorRolls( filter?: string ): Observable<any[]> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/ViewHonourRolls${ newFilter }` );
  }

  getSubjects( filter?: string ): Observable<any[]> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/Subjects${ newFilter }` );
  }


}
