import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  catchError,
  combineLatest,
  concat,
  debounceTime,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  toArray,
} from 'rxjs';
import { ResumeI } from 'src/app/models/resumeModels/resume';
import { RequestResumeService } from 'src/app/services/resume/resume.service';
import { environment } from 'src/environments/environment';
import zonesAndDistricts from '../resources/zonesAndDistricts';
import { AttachmentService } from 'src/app/services/attachment.service';
import Swal from 'sweetalert2';
import { ITypeUser } from 'src/app/models/users/type-user';
import { IRanges } from 'src/app/models/users/ranges';

@Component({
  selector: 'celic-resume',
  templateUrl: './celic-resume.component.html',
  styleUrls: ['./celic-resume.component.css'],
})
export class CelicResumeComponent implements OnInit {
  constructor(
    private _requestResumeService: RequestResumeService,
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    public _attachmentService: AttachmentService
  ) {
    /**/
  }

  public isEditing: boolean = false;
  public currentAge: number = 0;
  public shouldChangePassword: boolean = false;
  public userForm: FormGroup = this._fb.group({
    security: this._fb.group({
        currentPassword: ['', [Validators.required, Validators.minLength(6)]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validator: this.newPasswordMatchValidator }
    ),

    personalInfo: this._fb.group({
      CedocEmail: ['', [Validators.required, this.validateFormEmail]],
      email: ['', [Validators.required, this.validateFormEmail]],
      typeUserID: ['', [Validators.required]],
    }),

    documentInfo: this._fb.group({
      TypeDocumentID: ['', [Validators.required]],
      Document: ['', [Validators.required]],
    }),

    contactInfo: this._fb.group({
      Email: ['', [Validators.required, this.validateFormEmail]],
      Address: ['', Validators.required], //check on login
      Ciudad: ['', Validators.required],
      Nacionality: ['', Validators.required],
      Phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]], //check on login
      CellPhone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      birthDate: [{ value: '', disabled: false }, Validators.required], //Can be edited once
    }),

    ClassificationUser: this._fb.group({
      DisplacingMunicipality: [''],
      handicappedID: [''],
      MunicipalityWhereHeWasAVictimOfTheConflict: [''],
      biologicalSexID: [''],
      estratoID: [''],
      ChildOfPublicForceMember: [''],
      NumberOfPeopleInTheFamilyGroup: [''],
      NumberSiblings: [''],
      SiblingPosition: [''],
      SiblingsHigherEducation: [''],
      NumberChildren: [''],
      MaritalStatusOfParents: [''],
      MothersName: [''],
      MothersCard: [''],
      MotherLivingOrDeceased: [''],
      MothersTelephone: [''],
      MothersOccupation: [''],
      MotherEmailAddress: ['', this.validateFormEmail],
      MotherAddress: [''],
      FathersName: [''],
      FathersCertificate: [''],
      FatherLivingOrDeceased: [''],
      FathersTelephone: [''],
      FathersOccupation: [''],
      FatherAddress: [''],
      ParentEmailAddress: ['', this.validateFormEmail],
      ArmedForcesFamily: [''],
      ForceType: [''],
      ArmedForcesFamilyName: [''],
      InstitutionCharacter: [''],

      CurrentStatus: [''],
      MilitaryService: [''],
      SoldierForceType: [''],
      UnitMilitaryService: [''],
      VeteransLaw: [''],
      Contingent: [''],
      ContingentYear: [''],
      Grade: [{ value: '', disabled: false }],
      rhID: ['', [Validators.required]],
      ensuranceCompany: ['', [Validators.required]],
      eps: ['', [Validators.required]],
      ers: [''],
      recruitingZone: [''],
      MilitaryDistrict: [''],
      CamouflageSize: [''],
    }),

    ProfesionalProfile: this._fb.group({
      Description: [''],
    }),

    militarInfo: this._fb.group({
      realRangeID: ['', Validators.required],
      CedulaMil:[''],
      ForceID:  ['', Validators.required],
    }),

    workInfo: this._fb.array([]),
    academicInfo: this._fb.array([]),
  });

  public userData: ResumeI | undefined = undefined;
  public courseList: any[] = [];
  public targetUserEmail: string = '';
  public countries: any[] = [];
  public typeEthnicalGroup: any[] = [];
  public typeIndigenousPeople: any[] = [];
  public blackCommunities: any[] = [];
  public typeExceptionalCapacity: any[] = [];
  public typeBiologicalSex: any[] = [];
  public typeCivilStatus: any[] = [];
  public typeHandicapped: any[] = [];
  public typeEstratos: any[] = [];
  public typeZones: any[] = [];
  public typeSisben: any[] = [];
  public typeForces: any[] = [];
  public ranges: IRanges[] = [];
  public typeDocuments: any[] = [];
  public typeRelationship: any[] = [];
  public recruitmentZones: any[] = zonesAndDistricts;
  public recruitmentDistrict: any[] = [];
  public bloodType: any[] = [];
  public conditionType: any[] = [];
  public observerUserRoles: any[] = [];
  public typeUser: ITypeUser[] = [];

  public validForm: boolean = true;
  public isLoading: boolean = false;
  public isMyResume: boolean = false;
  public parentResume: boolean = false;
  public isCurrentPasswordValid: boolean = false;
  public loadingResume: boolean = true;
  public parentToStudentsData: any = {};
  public isMyChild:boolean = false;
  public isPublicMilitar: boolean = false;

  //#region Form getters
  get workInfoControls(): AbstractControl[] {
    return (this.userForm.get('workInfo') as FormArray)?.controls || [];
  }

  get academicInfoControls(): AbstractControl[] {
    return (this.userForm.get('academicInfo') as FormArray)?.controls || [];
  }
  //#endregion

  //#region Environment variables
  public BACK_V1_URL: string = environment.backV1ApiV1;
  public principalAppName: string = environment.principalAppName;
  //#endregion

  ngOnInit(): void {
    this.getUserId();
  }

  //Search for a user by their email address
  getUserId() {
    this.targetUserEmail = this._route.snapshot.paramMap.get('id') ?? '';
    if (this.targetUserEmail !== 'me') {
      if (!this.validateEmail(this.targetUserEmail)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Correo no válido.',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) this._router.navigate(['/']);
        });
        return;
      }
    }

    this.loadingResume = true;
    Swal.fire({
      title: '',
      text: 'Cargando, por favor, espere.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Set filters for observer and target users
    const observerUserFilter: string = JSON.stringify({
      include: ['roleMappings', 'ContactInfos'],
    });
    const targetUserFilter: string = this.getUserFilterByEmail();

    const currentUser = localStorage.getItem('currentUser');

    const requests = [
      this._requestResumeService.getUserInfo(targetUserFilter, this.targetUserEmail === 'me').pipe(
        map(resp => resp[0] ? resp[0] : resp)
      ),
      this._requestResumeService.getUserInfo(observerUserFilter, true),
      this._requestResumeService.getBasicData('Countries'),
      this._requestResumeService.getBasicData('TypeEthnicalGroups'),
      this._requestResumeService.getBasicData('TypeIndigeniusPeople'),
      this._requestResumeService.getBasicData('TypeCommunities'),
      this._requestResumeService.getBasicData('TypeExceptionalCapacities'),
      this._requestResumeService.getBasicData('TypeBiologicalSexes'),
      this._requestResumeService.getBasicData('TypeCivilStatuses'),
      this._requestResumeService.getBasicData('TypeEstratos'),
      this._requestResumeService.getBasicData('TypeHandicappeds'),
      this._requestResumeService.getBasicData('TypeZones'),
      this._requestResumeService.getBasicData('TypeSisbens'),
      this._requestResumeService.getBasicData('Forces'),
      this._requestResumeService.getBasicData('TypeDocuments'),
      this._requestResumeService.getBasicData('TypeRelationships'),
      this._requestResumeService.getBasicData('TypeRHs'),
      this._requestResumeService.getParentToStudents(
        JSON.stringify({
          where: { ParentUserId: Number(currentUser) },
          include: [
            "typeRlelationshipParent",
            "typeRlelationshipAttended",
            { attendantUser: 'UserDocuments' },
            { studentUser: 'UserDocuments' },
            { parentUser: 'UserDocuments' },
          ],
        })
      ),
      this._requestResumeService.getParentToStudents(
        JSON.stringify({
          where: { attendedBy: Number(currentUser) },
          include: [
            "typeRlelationshipParent",
            "typeRlelationshipAttended",
            { attendantUser: 'UserDocuments' },
            { studentUser: 'UserDocuments' },
            { parentUser: 'UserDocuments' },
          ],
        })
      ),
      this._requestResumeService.getBasicData('TypeConditions'), // 19
      this._requestResumeService.getBasicData('TypeUsers'), // 19
    ];

    // Combine all requests using concat
    concat(...requests)
      .pipe(
        toArray(),
        switchMap( (resp:any[]) => {
          const targetUser = resp[0];
          if (targetUser.MilitarInfos.length === 0)
            return of(resp);

          //Update militar info
          const forceID = targetUser.MilitarInfos[0].ForceID;
          return this._requestResumeService.getBasicData('Ranges', JSON.stringify({
            where: { ForceID: forceID }
          })).pipe(
            catchError(() => of(resp)),
            map((ranges:any[]) => {
              this.ranges = ranges;
              this.userForm.get('militarInfo')?.patchValue({
                ...targetUser.MilitarInfos[0],
              });

              return resp;
            }),
          );
        })
      )
      .subscribe({
        next: (data: any) => {
          const targetUser = data[0];

          this.parentToStudentsData.myChilds = data[17] || [];
          this.parentToStudentsData.attendantOf = data[18] || [];

          // Check if target user exists
          if (!targetUser || (targetUser && targetUser.length === 0)) {
            Swal.fire({
              icon: 'error',
              text: 'Correo no válido.',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) this._router.navigate(['/']);
            });
            return;
          }

          this.observerUserRoles = data[1].roleMappings.map((e) => e.roleId);

          //Allowed to view if it is my resume
          this.isMyResume = data[1].id === targetUser.id;

          //Allowed to view specific content for parent
          this.parentResume = this.isMyResume && this.allowedRoles([29]);

          const children = this.parentToStudentsData.myChilds.map(
            (e) => e.StudentUserId
          );

          //If I'm the parent and I have children
          this.isMyChild = this.allowedRoles([29]) && children.includes(targetUser.id);

          if ( this.isMyResume ||
            this.isMyChild ||
            this.allowedRoles([1, 3, 6, 7, 15, 18, 25])
          ) {
            this.userData = targetUser;
            this.currentAge = this.getCurrentAge();
            this.countries = data[2];
            this.typeEthnicalGroup = data[3];
            this.typeIndigenousPeople = data[4];
            this.blackCommunities = data[5];
            this.typeExceptionalCapacity = data[6];
            this.typeBiologicalSex = data[7];
            this.typeCivilStatus = data[8];
            this.typeEstratos = data[9];
            this.typeHandicapped = data[10];
            this.typeZones = data[11];
            this.typeSisben = data[12];
            this.typeForces = data[13];
            this.typeDocuments = data[14];
            this.typeRelationship = data[15];
            this.bloodType = data[16];
            this.conditionType = data[19];
            this.typeUser = data[20];

            this.patchForm();
            this.loadingResume = false;
            Swal.close();
            return;
          }

          Swal.fire({
            icon: 'error',
            text: 'No tienes permisos para ver el contenido.',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) this._router.navigate(['/']);
          });
        },
        error: (err: Error) => {
          Swal.close();
        },
      });
  }

  //Patch form data
  private patchForm() {
    //Update personalInfo form
    this.userForm.get('contactInfo')?.patchValue({
      ...this.userData?.ContactInfos[0],
      birthDate: this.userData!.ContactInfos[0].birthDate
        ? this.formatDate(this.userData!.ContactInfos[0].birthDate.toString()) : '',
    });

    this.userForm.get('documentInfo')?.patchValue({
      ...this.userData?.UserDocuments[0],
    });

    this.userForm.get('personalInfo')?.patchValue({
      ...this.userData!,
    });

    this.userForm.get('ClassificationUser')?.patchValue({
      ...this.userData!.ClassificationUser?.[0],
    });

    //Update MilitarInfo form
    this.userForm.get('militarInfo')?.patchValue({
      ...this.userData!.MilitarInfos?.[0],
    })

    //Update ProfesionalProfile form
    this.userForm.get('ProfesionalProfile')?.patchValue({
      ...this.userData!.ProfesionalProfile?.[0],
    });

    //Dynamically add items that are loaded from the database
    if(this.parentResume){
      // Creates an empty form record if i'm the parent
      this.addWorkInfo(this.userData!.WorkInfos.length === 0 ? undefined : this.userData!.WorkInfos);
    }

    if(this.parentResume){
      // Creates an empty form record if i'm the parent
      this.addAcademicInfo(this.userData!.AcademicInfos.length === 0 ? undefined : this.userData!.AcademicInfos);
    }

    //Automatically load recruitmentDistrict
    const recruitingZone = this.userForm.get(
      'ClassificationUser.recruitingZone'
    )?.value;

    if (recruitingZone) {
      const zonaDistritoFinded = zonesAndDistricts.find(
        (e: any) => e.name === recruitingZone
      );

      this.recruitmentDistrict = zonaDistritoFinded
        ? zonaDistritoFinded.district
        : zonesAndDistricts[0].district;
    }

    this.disableInputs();
    this.formValidations();
  }

  //These inputs cannot be edited, visible, required or not required.
  private disableInputs() {
    if (this.userForm.get('personalInfo.birthDate')?.value) {
      this.userData!.ContactInfos[0].birthDate = this.userForm.get(
        'personalInfo.birthDate'
      )?.value;
      this.userForm.get('personalInfo.birthDate')?.disable();
      this.currentAge = this.getCurrentAge();
    }

    //Only admin can edit
    if (!this.allowedRoles([1])) {
      this.userForm.get('personalInfo.CedocEmail')?.disable();
      this.userForm.get('personalInfo.TypeDocumentID')?.disable();
    }

    //If it is my resume and I'm parent
    if (this.parentResume) 
      this.userForm.get('ClassificationUser.grade')?.disable();
  }

  //Enable or disable required for fields
  private formValidations() {
    const typeUser = this.userData?.TypeUser;
    this.isPublicMilitar = !!(typeUser && (typeUser.isMilitar && typeUser.isPublic));
    if(!this.isPublicMilitar){
      this.clearMassiveValidator([
        'militarInfo.realRangeID',
        'militarInfo.ForceID',
      ]);
    }

    //if observer is a parent
    if (this.parentResume) {
      this.clearMassiveValidator([
        'ClassificationUser.rhID',
        'ClassificationUser.eps',
        'ClassificationUser.ensuranceCompany',
      ]);
    }

    //Check password
    this.shouldChangePassword =
      this.isMyResume &&
      this.userData!.pendingChangePass &&
      this.allowedRoles([10, 29]);

    if (this.shouldChangePassword) {
      this.matchPassword();
      return;
    }

    //Remove validations if shouldChangePassword is false
    this.clearMassiveValidator([
      'security.currentPassword',
      'security.newPassword',
      'security.confirmPassword',
    ]);
  }

  //Compare password fields
  private matchPassword() {
    //Match password
    this.userForm
      .get('security.currentPassword')
      ?.valueChanges.pipe(
        tap(() => (this.isCurrentPasswordValid = false)),
        map((value) => value.toString()),
        debounceTime(300),
        filter((value) => value.length > 5),
        switchMap((password) =>
          this._requestResumeService.passwordMatch(this.userData!.id, {
            password,
          })
        )
      )
      .subscribe({
        next: ({ passwordMatch }: any) => {
          this.isCurrentPasswordValid = passwordMatch;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  //Calculate user age
  public getCurrentAge() {
    const contactInfo = this.userData!.ContactInfos[0];
    const birthDate =
      contactInfo && contactInfo.birthDate
        ? new Date(contactInfo.birthDate)
        : new Date();
    const currentDate = new Date();
    let age: any = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  // Helper method to create user filter by email
  private getUserFilterByEmail(): string {
    const isMe = this.targetUserEmail === 'me';

    const filters = {
      include: [
        'TypeUser',
        'roleMappings',
        { MilitarInfos: ['Force'] },
        { UserDocuments: ['TypeDocuments'] },
        {
          ClassificationUser: [
            'typeBiologicalSex',
            'typeCivilStatus',
            'typeEthnicalGroup',
            'typeIndigeniusPeople',
            'typeHandicapped',
            'typeExceptionalCapacity',
            'typeSisben',
            'typeEstrato',
            'typeZone',
          ],
        },
        'ContactInfos',
        'WorkInfos',
        'AcademicInfos',
        'ProfesionalProfile',
        { UserCourses: { coursesOfers: { course: 'School' } } },
      ],
    };

    if (!isMe) filters['where'] = { CedocEmail: this.targetUserEmail };
    return JSON.stringify(filters);
  }

  //Inserts a new work info in the form
  public addWorkInfo( data: any = undefined) {
    const dataArray = this.userForm.get('workInfo') as FormArray;
    
    const createFormData = (e: any = null) => {
      const formData: any = {
        Company: [ e?.Company || '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
        Since: [e ? this.formatDate(e.Since) : '', Validators.required],
        Description: [ e?.Description || '', [Validators.required, Validators.minLength(5)]],
        Position: [e?.Position || '',[
            Validators.required, 
            Validators.minLength(5),
            Validators.maxLength(50)],
        ],
        Unit: [e?.Unit || '', [Validators.minLength(2), Validators.maxLength(30)]],
        UserID: this.userData!.id
      };

      if (e?.id) formData.id = e.id;

      //If I'm a parent
      if (this.parentResume) {
        formData.Address = [e?.Address || '', [
            Validators.required, 
            Validators.minLength(3), 
            Validators.maxLength(60), 
            Validators.pattern(/^['#\°\.\s\w\-\/]*$/)],
        ];
        formData.Phone = [ e?.Phone || '', [
          Validators.required, 
          Validators.minLength(7), 
          Validators.maxLength(20),
          Validators.pattern(/^[0-9]*$/)
        ]];
        formData.workEmail = [e?.workEmail || '', [Validators.required, this.validateFormEmail]];
      }

      return formData;
    };

    if (data) {
      dataArray.clear();
      data.forEach((e) => dataArray.push(this._fb.group(createFormData(e))) );
      return;
    }

    dataArray.push(this._fb.group(createFormData()));
  }

  //Inserts a new scientific info in the form
  //Inserts a new academic info in the form
  public addAcademicInfo(data: any = undefined) {
    const dataArray = (this.userForm.get('academicInfo') as FormArray);
    const createFormData = (e: any = null) => {
      const formData: any = {
        Title: [e?.Title || '', [Validators.required, Validators.minLength(5)]],
        Institute: [e?.Institute || '', Validators.required],
        FinishDate: [
          e ? this.formatDate(e.FinishDate) : '',
          [Validators.required],
        ],
        UserID: this.userData!.id
      };
      if (e?.id) formData.id = e.id;
      return formData;
    };

    if (data) {
      dataArray.clear();
      data.forEach((e) => {
        dataArray.push(this._fb.group(createFormData(e)));
      });
      return;
    }

    dataArray.push(this._fb.group(createFormData()));
  }

  //Remove any previously added dynamic elements
  public deleteDynamicItem(
    index: number,
    data: any,
    formArrayName: string,
    path: string
  ) {
    const value = data.value;
    const dataArray = this.userForm.get(formArrayName) as FormArray;

    if (value.id) {
      this.isLoading = true;

      //Removes from database
      this._requestResumeService
        .deleteInfo(value, path, this.userData!.id)
        .subscribe({
          complete: () => {
            //Remove from form array
            const index = dataArray.controls.findIndex(
              (control) => control.value.id === value.id
            );
            if (index !== -1) dataArray.removeAt(index);
            this.isLoading = false;
          },
          error: (err) => {
            console.log(err);
            this.isLoading = false;
          },
        });
      return;
    }

    dataArray.removeAt(index);
  }

  //Save all data
  public saveData() {
    //I can save the data if it is my curriculum or admin
    const isMyResume = this.isMyResume && !this.allowedRoles([2, 10]);
    const isAdmin = this.allowedRoles([1])
    if (!(isMyResume || isAdmin || this.isMyChild))
      return;

    //Validate password fields
    //By default the password is valid
    let validPassword = true;

    //By default the password obs is null to send nothing
    let passwordObs: Observable<any> = of(null);

    if (this.shouldChangePassword) {
      validPassword =
        this.isCurrentPasswordValid &&
        (this.userForm.get('security')?.valid ?? false);
      if (validPassword) {
        passwordObs = this._requestResumeService.patchPassword(
          this.userData!.id,
          {
            password: this.userForm.get('security.confirmPassword')?.value,
          }
        );
      }
    }

    //Ignore password because "this._requestResumeService.patchPassword" is doing the job.
    const  {pendingChangePass, password, ...userData} = this.userData!;
    const personalInfo = {
      ...userData,
      ...this.userForm.get('personalInfo')?.value,
      email: this.userForm.get('contactInfo.Email')?.value
    };
    
    //This should not throw an error, the tables are created when the user is created.
    const contactInfo = {
      ...this.userData!.ContactInfos[0],
      ...this.userForm.get('contactInfo')?.value,
      //UserID: this.userData!.id,
    };

    //This should not throw an error, the tables are created when the user is created.
    const documentInfo = {
      ...this.userData!.UserDocuments[0],
      ...this.userForm.get('documentInfo')?.value,
      //UserID: this.userData!.id,
    };

    const profesionalProfile = {
      ...this.userData!.ProfesionalProfile?.[0],
      ...this.userForm.get('ProfesionalProfile')?.value,

      id: this.userData!.ProfesionalProfile?.[0]?.id ?? null,
      UserID: this.userData!.id,
    };

    const classificationUser = {
      ...this.userData!.ClassificationUser?.[0],
      ...this.userForm.get('ClassificationUser')?.value,

      id: this.userData!.ClassificationUser?.[0]?.id ?? null,
      UserID: this.userData!.id,
    };

    const militaryInfo = {
      ...this.userData!.MilitarInfos?.[0],
      ...this.userForm.get('militarInfo')?.value,

      id: this.userData!.MilitarInfos?.[0]?.id ?? null,
      UserID: this.userData!.id,
    };

    // Exclude RangeIDS
    const { RangeIDS, Range, ...militarInfoToUpdate } = militaryInfo;

    const targetUserFilter: string = this.getUserFilterByEmail();
    const observables = {
      passwordObs,
      personalInfo: this._requestResumeService.updatePersonalInfo(this.userData!.id, personalInfo),
      contacatInfo: this._requestResumeService.updateInfo(contactInfo,'ContactInfos'),
      documentInfo: this._requestResumeService.updateInfo(documentInfo,'UserDocuments'),
      profesionalInfo: this._requestResumeService.updateInfo(profesionalProfile,'ProfesionalProfiles'),
      militarInfo: this.isPublicMilitar
        ? this._requestResumeService.updateInfo(militarInfoToUpdate, 'MilitarInfos')
        : of(null),
      classificationUser: this._requestResumeService.updateInfo(classificationUser,'ClassificationUsers'),
      workInfo: this.saveDynamicItem('workInfo', 'WorkInfos'),
      academicInfo: this.saveDynamicItem('academicInfo', 'AcademicInfos'),
      userData: this._requestResumeService.getUserInfo(targetUserFilter,this.targetUserEmail === 'me').pipe(
        map(resp => resp[0] ? resp[0] : resp)
      ),
    };

    if (!this.userForm.valid || !validPassword) {
      Swal.fire({
        icon: 'warning',
        text: 'Por favor revisa nuevamente, hay campos por completar.',
      });

      this.userForm.markAllAsTouched();
      return;
    }

    Swal.fire({
      text: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    Swal.showLoading();

    this.isLoading = true;
    forkJoin(observables).subscribe({
      next: ({ workInfo, academicInfo, passwordObs, userData }) => {
        //Refresh userData
        this.userData = userData;
        
        //This actions is unique
        if (passwordObs) {
          this.shouldChangePassword = false;

          Swal.fire({
            icon: 'warning',
            title: '¡Cambio de contraseña exitoso!',
            text: "Su contraseña ha sido actualizada correctamente. Por favor, vuelva a iniciar sesión para aplicar los cambios'",
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) this._router.navigate(['/landing']);
          });

          return;
        }

        //Refresh form data
        this.addWorkInfo(workInfo);
        this.addAcademicInfo(academicInfo);

        this.isLoading = false;
        this.isEditing = false;

        Swal.fire({
          icon: 'success',
          title: '¡Hecho!',
          text: 'Los datos se han guardado.',
        });
      },
      error: (err) => {
        let message = err.message;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Ocurrió un error: ${message || "Internal server error"}`,
        });
        this.isLoading = false;
      },
    });
  }

  //Convert comma-separated text into an array
  public textArray(text: string): string[] {
    return text.split(',').map((e) => e.trim());
  }

  //Creates an array of observables for each dynamically added element
  private saveDynamicItem(formArrayName: string, path: string) {
    const formsArray: any = this.userForm.get(formArrayName) as FormArray;
    if (formsArray.controls.length === 0) return of([]);

    const allData: any[] = formsArray.controls.map((control) => control.value);
    const updateObservables: Observable<any>[] = allData
      .map((data) =>
        this._requestResumeService.updateInfo(data, path)
      );

    const allObservables: Observable<any>[] = updateObservables;
    return combineLatest(allObservables);
  }

  public onChaneUserType(){
    const typeUser = this.typeUser.find( e => e.id == this.userForm.get("personalInfo.typeUserID")?.value);
    if(typeUser && (typeUser.isMilitar && typeUser.isPublic)){
      this.isPublicMilitar = true;
      this.setMassiveValidator(['militarInfo.realRangeID','militarInfo.ForceID'], Validators.required)
      return
    }
    
    this.isPublicMilitar = false;
    this.clearMassiveValidator(['militarInfo.realRangeID','militarInfo.ForceID']);
  }

  //Load recruitmentZone list
  public onSelectRecruitingZone() {
    const value = this.userForm.get('ClassificationUser.recruitingZone')?.value;
    this.recruitmentDistrict = [];

    this.userForm
      .get('ClassificationUser')
      ?.patchValue({ MilitaryDistrict: '' });
    this.recruitmentDistrict = this.recruitmentZones.find(
      (e: any) => e.name == value
    ).district;
  }

  public onSelectForce(){
    const filters = JSON.stringify({
      where: { ForceID: this.userForm.get("militarInfo.ForceID")?.value }
    });
    
    this.userForm.patchValue({ militarInfo:{ realRangeID:'' } })
    this._requestResumeService.getBasicData('Ranges', filters).subscribe({
      next:(resp)=>{
        this.ranges = resp;
      },err:(err)=>{
        console.log(err);
      }
    })
  }

  shouldShowField(fieldName: string): { showSpan: boolean; showDiv: boolean } {
    const field = this.userForm.get(fieldName);
    if (!field) return { showSpan: false, showDiv: false };
  
    const isFieldEmpty = !field.value?.toString().trim();
    const showSpan = isFieldEmpty && !this.isEditing;
    const showDiv = !isFieldEmpty || this.isEditing;
  
    return { showSpan, showDiv };
  }

  //Formatting database date in to html date
  private formatDate(date: string) {
    let firstDate: Date;
    try {
      firstDate = new Date(date);
      if (isNaN(firstDate.getTime())) {
        throw new Error('Fecha inválida');
      }
    } catch (error) {
      firstDate = new Date();
    }
    let result = firstDate.toISOString().split('T')[0];
    return result;
  }

  public openPath(path: string) {
    this._router.navigate([path, this.userData!.id]);
  }

  //Email validator for userForm
  private validateFormEmail(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value && typeof value === 'string') {
      const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$/;
      const valid = regex.test(value);

      return valid ? null : { invalidEmail: true };
    }

    return null;
  }

  //validates if the entered url parameter is an email address
  private validateEmail(email: string) {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}$/;
    return regex.test(email);
  }

  public allowedRoles(allowedRoles: number[]) {
    return this.observerUserRoles.some((e) => allowedRoles.includes(e));
  }
  //Password validator
  private newPasswordMatchValidator(g: FormGroup) {
    return g.get('newPassword')!.value === g.get('confirmPassword')!.value
      ? null
      : { passwordMismatch: true };
  }

  get conditionName() {
    const condition = this.conditionType.find( e => e.id === this.userData!.typeConditionID);
    return condition ? condition.nameCondition : 'n/a';
  }

  //For debug
  private getFormErrors(formGroup: FormGroup, parentKey: string = ''): any {
    const errors = {};

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlKey = parentKey ? `${parentKey}.${key}` : key;

      if (control instanceof FormGroup) {
        Object.assign(errors, this.getFormErrors(control, controlKey));
      } else if (control instanceof FormArray) {
        for (let i = 0; i < control.controls.length; i++) {
          Object.assign(
            errors,
            this.getFormErrors(
              control.at(i) as FormGroup,
              `${controlKey}[${i}]`
            )
          );
        }
      } else if (control && control.errors) {
        errors[controlKey] = control.errors;
      }
    });

    return errors;
  }

  private setMassiveValidator(fields: string[], validator: ValidatorFn) {
    fields.forEach((field) => {
      const control = this.userForm.get(field);
      if (control) {
        control.setValidators(validator);
        control.updateValueAndValidity();
      }
    });
  }

  private clearMassiveValidator(fields: string[]) {
    fields.forEach((field) => {
      const control = this.userForm.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }
}