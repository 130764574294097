import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';

@Injectable( {
  providedIn: 'root'
} )
export class ObservationsService {

  constructor ( private _httpService: HttpService ) { }

  /**
   * Get user info query
   * @param id 
   * @param filter 
   * @returns 
   */
  getUserInfo( filter?: string ): Observable<any> {
    let filters = "";
    if ( filter ) 
      filters = `?filter=${ filter }`;
  
    return this._httpService.httpGet( `/Userapps/${ filters }` );
  }

  /**
   * Set observations query
   * @param data 
   * @returns 
   */
  setObservations(data:any): Observable<any>{
    const filter = JSON.stringify({
      order: 'date DESC'
    })
    return this._httpService.httpPost(`/ObservationsInfos?filter=${filter}`, data);
  }

  getObservations(additionalFilters:any): Observable<any>{
    return this._httpService.httpGet(`/ObservationsInfos?filter=${additionalFilters}`);
  }

  getObservationTypes(): Observable<any>{
    const filter = JSON.stringify({
      include:['school','subTypeObservations']
    })
    return this._httpService.httpGet(`/TypeObservations?filter=${filter}`);
  }

  patchObservation(id:number, data):Observable<any>{
    return this._httpService.httpPatch(`/ObservationsInfos/${id}`, data);
  }

  sendNotification(data:any){
    return this._httpService.httpPost(`/NotificationQueues`, data);
  }

  getChildren(parentId: number): Observable<any> {
    const filter = JSON.stringify({
      where: { ParentUserId: parentId },
      include: [{ studentUser: 'UserDocuments' }]
    });

    const url: string = `/ParentsToStudents?filter=${filter}`;
    return this._httpService.httpGet(url);
  }
}
