<div class="notes-modal">
  <div class="notes-modal__container">
    <div class="modal-header">
      <h2 class="modal-title">Boletín de notas</h2>
      <div class="modal-header__actions">
        <button class="button button--download" (click)="generateReport()">
          <em class="icon-i-download"></em>
        </button>
        <button class="button button--close" (click)="closeModal($event)">
          <em class="icon-i-close"></em>
        </button>
      </div>
    </div>
    <div class="table-container">
      <table class="styled-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Nota</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let note of notes">
            <td>{{note.subjectGroup.subjects.NameSubject | titlecase}}</td>
            <td>{{note.FinalRecord}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>