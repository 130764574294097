
const zonesAndDistricts = [
  {
    name: "Primera Zona de Reclutamiento",
    district: [
      "Tunja",
      "Chiquinquirá",
      "Sogamoso",
      "Barbosa"
    ]
  },
  {
    name: "Segunda Zona de Reclutamiento",
    district: [
      "Barranquilla",
      "Santa Marta",
      "Cartagena",
      "Valledupar",
      "Riohacha"
    ]
  },
  {
    name: "Tercera Zona de Reclutamiento",
    district: [
      "Cali",
      "Palmira",
      "Buga",
      "Popayán",
      "Ipiales",
      "Pasto",
      "Buenaventura"
    ]
  },
  {
    name: "Cuarta Zona de Reclutamiento",
    district: [
      "Medellín",
      "Carepa",
      "Puerto Berrío",
      "Quibdó"
    ]
  },
  {
    name: "Quinta Zona de Reclutamiento",
    district: [
      "Bucaramanga",
      "Socorro",
      "Barrancabermeja",
      "Cúcuta",
      "Pamplona",
      "Ocaña",
      "Arauca"
    ]
  },
  {
    name: "Sexta Zona de Reclutamiento",
    district: [
      "Tolemaida",
      "Ibagué",
      "Honda",
      "Girardot",
      "Chaparral"
    ]
  },
  {
    name: "Séptima Zona de Reclutamiento",
    district: [
      "Villavicencio",
      "Yopal",
      "Granada (Meta)"
    ]
  },
  {
    name: "Octava Zona de Reclutamiento",
    district: [
      "Armenia",
      "Pereira",
      "Cartago",
      "Manizales"
    ]
  },
  {
    name: "Novena Zona de Reclutamiento",
    district: [
      "Neiva",
      "Florencia",
      "Pitalito",
      "Mocoa"
    ]
  },
  {
    name: "Décima Primera Zona",
    district: [
      "Montería",
      "Sincelejo",
      "Caucasia"
    ]
  },
  {
    name: "Décima Tercera Zona de Reclutamiento",
    district: [
      "Bogotá",
      "Facatativá",
      "Cajicá",
      "Fusagasugá",
      "Soacha"
    ]
  },
  {
    name: "Décima Quinta Zona de Reclutamiento: Bogotá",
    district: [
      "Bogotá"
    ]
  }
]
export default zonesAndDistricts;