import { Component } from '@angular/core';

@Component({
  selector: 'request-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'request';
}
